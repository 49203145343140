<div class="container upload-container">
    <div class="row">
        <div class="col-md-12">
            <h3 id="questionnaires">Questionnaires</h3>
            <div class="row">
                <form name="form" (ngSubmit)="onSubmit(f.form)" #f="ngForm" novalidate>
                    <div class="col-md-12 mb-4" *ngFor="let question of questions;let i=index">
                        <h6>
                            <b class="mr-1">Q{{i+1}}. </b> {{question.question}}<em class="red-star">*</em>
                        </h6>
                        <textarea [readonly]="disableBtn" (keyup)="keypress(question.answer)" id="{{'question'+ i}}" [(ngModel)]="question.answer"  autoResize="autoResize" pInputTextarea  name="answer{{i}}" class="form-control" required rows="3">
                        </textarea>
                        <div *ngIf="(f.submitted || ('answer'+i).touched) && ('answer'+i).errors?.required" class="text-danger">
                            Answer can not be empty
                        </div>
                    </div>
                    <div class="buttons">
                        <ng-container *ngIf="companyInfo?.status != 'Submitted'">
                        <button class="btn cancel" type="button" (click)="back()" id="backBtn" >BACK</button>
                        <button class="btn next" type="submit" (click)="f.form.markAllAsTouched()" id="saveAndExitBtn" [disabled]="f.invalid">SAVE & NEXT</button>
                        </ng-container>
                        <ng-container *ngIf="companyInfo?.status == 'Submitted'">
                        <button class="mr-4 btn cancel" type="button" (click)="back()" id="backBtnSubmitted">BACK</button>
                        <button class="btn next" type="button" (click)="goNext()"  id="nextBtn">Next</button>
                        </ng-container>
                
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>