<div class="user-cutomer-managment">
    <div class="page-heading mt-4">
        <div class="left">
            <h1 id="usersHeading">Users</h1>
        </div>
        <div class="right">
            <button class="btn" type="button" id="addUserBtn" [routerLink]="['/vendor/user','new','']" >
                <em class="icon-add icon"  aria-hidden="true"></em><span>Add User</span></button>
        </div>
    </div>

    <div class="row">
        <div class="page-content mt-3">
            <div class="showList">
                <p-table class="p-table" [columns]="headers" [value]="users" class="p-table mt-5"
                (onLazyLoad)="refreshPage($event)" [paginator]="false" [lazy]="true" [sortOrder]="1" [resizableColumns]="true"
                responsiveLayout="scroll">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-container *ngFor="let col of columns; let i = index">
                                <th class="cursor-pointer "  id="{{ 'header' + i }}" [pSortableColumn]="col.name" [pSortableColumnDisabled]="!col.sort" [ngStyle]="{'max-width': col.width}">{{col.header}}<img *ngIf="col.sort" class=" topbottom"
                                    src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" >
                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-users>
                        <tr style="height: 56px;color: gray;">
                            <td>{{users.createdDate | date}}</td>
                            <td class="text-transform">{{users.name}}</td>
                            <td>{{users.email}}</td>
                            <td>{{users.mobileNumber}}</td>
                            <td style="max-width: 200px;">
                            <ng-container *ngFor="let col of users.eroleSet;let isLast=last">
                                {{col=='ROLE_VENDOR_ADMIN'?'Admin':'Non Admin'}}
                             </ng-container>
                            </td>  
                            <td  style="max-width: 200px;"> 
                                <ng-container *ngIf="users.locations?.length>0;  then isComma; else isNotComma">
                                </ng-container>
                            
                                <ng-template #isComma>
                                    <div class="locations progressive-sidebar">
                                        {{users.locations.join(', ') }}
                                    </div>
                                </ng-template>
                            
                                <ng-template #isNotComma>
                                    {{users.locations}}
                                </ng-template>
                            </td>
                            <td>
                                <ng-container *ngIf="user.username != users.email">
                                <em class="icon-edit icon positions" (click)="editUser(users)" pTooltip="Edit" tooltipPosition="bottom"></em>
                                <em class="icon-delete icon positions" (click)="delete(users)" pTooltip="Delete" tooltipPosition="bottom"></em>
                                </ng-container>
                            </td>
                            <td>
                                <p-inputSwitch class="contacttext positions"  pTooltip="Active / Inactive" tooltipPosition="bottom" *ngIf="user.username != users.email" [disabled]="false" [(ngModel)]="users.active" (onChange)="changeStatus(users)"></p-inputSwitch>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" class="text-center">No Data Available.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="boot-paginator" *ngIf="users?.length>0">
            <app-pagination [totalPages]="pager.totalPages" [size]="size" (onPageChange)="setPage($event)"></app-pagination>
        </div>
    </div>
</div>
<p-toast position="top-right">
</p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false"></p-confirmDialog>