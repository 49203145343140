import { Component } from '@angular/core';

@Component({
  selector: 'app-addvendorscreen-survey',
  templateUrl: './addvendorscreen-survey.component.html',
  styleUrls: ['./addvendorscreen-survey.component.scss']
})
export class AddvendorscreenSurveyComponent {

}
