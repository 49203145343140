<div class="overview mt-4">
    <div class="status" *ngIf="companyInfo?.status != 'APPROVED'">
        <div class="stepper-container stepper-bg">
            <div class="heading">
                <h2 id="status">Application Status:
                    <span class="bg-green" id="legalStatusSubmitted" *ngIf="companyInfo?.status =='Submitted'">{{invitedCompDetails?.legalName}} received your request & in the process of reviewing it.</span>
                    <span class="bg-red" id="legalStatusRejected" *ngIf="companyInfo?.status =='REJECTED'">{{invitedCompDetails?.legalName}} rejected your request <a id="rejected" class="cursor-pointer data-table" (click)="gotovendordashboard()">Click here</a></span>
                </h2>
                <h2 class="rejection mt-1" id="rejectionReason" *ngIf="companyInfo?.status =='REJECTED'">Rejection Reason:
                    <span  id="rejectionReason" >{{companyInfo?.rejectionReason}}</span>
                </h2>
                <h2 class="rejection mt-1" id="remark" *ngIf="companyInfo?.status =='REJECTED'">Remark:
                    <span id="remark" >{{companyInfo?.remark}}</span>
                </h2>
            </div>
            <div class="bs-stepper" APPROVED>
                <div class="bs-stepper-header" role="tablist">  
                
                    <div class="step" >
                        <button type="button" class="step-trigger" >
                            <span class="bs-stepper-circle">1</span>
                            <div class="text active">
                                <span class="bs-stepper-label" id="companyInfo">Company Information <span class="info">Status: {{companyInfo?.status}}</span> </span>
                            </div>
                        </button>
                    </div>
                    
                    <div class="step" >
                        <button type="button" class="step-trigger">
                            <span class="bs-stepper-circle">2</span>
                            <div class="texts">
                                <span class="bs-stepper-label" id="uploadDocument">Upload Documents <span class="info">Status:
                                        {{companyInfo?.status}}</span></span>
                            </div>
                        </button>
                    </div>


                    
                    <div class="step">
                        <button type="button" class="step-trigger" >
                            <span class="bs-stepper-circle">3</span>
                            <span class="bs-stepper-labels bs-stepper-label" id="questionnaire">Questionnaire <span class="infos">Status:
                                    {{companyInfo?.status}}</span></span>
                        </button>
                    </div>
                    
                    <div class="step">
                        <button type="button" class="step-trigger" >
                            <span class="bs-stepper-circle">4</span>
                            <span class="bs-stepper-labels bs-stepper-label esg-overview-label" id="esg">ESG<span class="infos">Status:
                                {{companyInfo?.status}}</span></span>
                        </button>
                    </div>
                    <div class="step">
                        <button type="button" class="step-trigger" >
                            <span class="bs-stepper-circle">5</span>
                            <span class="bs-stepper-labels bs-stepper-label" id="regulation">Regulations <span class="infos">Status:
                                {{companyInfo?.status}}</span></span>
                        </button>
                    </div>
                </div>
            </div>
    
      
        </div>
    </div>

    <div class="card-container mt-4">
        <div class="row">
            <div class=" col-lg-3 col-md-6">
                <div class="row-card-body">
                    <div class="right">
                        <em class="icon-incorporation"></em>
                    </div>
                    <div class="left">
                        <label for="incorporationDate">Incorporation Date</label>
                        <p *ngIf="cardCount?.dateOfIncorporation" id="dateOfIncorporation">{{cardCount?.dateOfIncorporation | date}}</p>
                        <p *ngIf="!cardCount?.dateOfIncorporation" id="dateOfIncorporation">-</p>
                    </div>
                </div>
            </div>
            <div class=" col-lg-3 col-md-6">
                <div class="row-card-body">
                    <div class="right">
                        <em class="icon-director"></em>
                    </div>
                    <div class="left">
                        <label for="totalDirector" id="totalDirectorLabel">Total Directors</label>
                        <p id="totalDirector">{{cardCount?.boardOfDirector?cardCount.boardOfDirector:'N.A'}}</p>
                    </div>
                </div>
            </div>
            <div class=" col-lg-3 col-md-6">
                <div class="row-card-body">
                    <div class="right">
                        <em class="icon-shareholders"></em>
                    </div>
                    <div class="left">
                        <label for="totalShareholder" id="totalShareholderLabel">Total Shareholders</label>
                        <p id="totalShareholder">{{cardCount?.shareHolder?cardCount.shareHolder:'N.A'}}</p>
                    </div>
                </div>
            </div>
            <div class=" col-lg-3 col-md-6">
                <div class="row-card-body">
                    <div class="right">
                        <em class="icon-document"></em>
                    </div>
                    <div class="left">
                        <label for="documentUpload" id="documentUploadLabel">Documents Uploaded</label>
                        <p id="documentUpload">{{cardCount?.documentCount?cardCount.documentCount:'N.A'}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="financial-container ">
        <div class="grid">
            <div class="col-12 flex-box">
                <!-- <div class="col-6 financial-mobile-screen">
                    <div class="charts">
                        <div class="financial">
                            <div class="cards">
                                <h2 id="financialHeading">Financial</h2>
                                <div class="p-chart">
                                    <div echarts [options]="options" class="demo-chart"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                
                <div class="col-12 financial-mobile-screen mt-2" [ngClass]="isOverlay?'overlayheight':''">
                    <div class="date">
                        <h2 id="dateHeading">Financial As On {{financialData?.cisFinancialSearchData?.createdAt | date}}</h2>
                    </div>
                    <div class="overlay" *ngIf="isOverlay" >
                        <p>Financial Check Not Initiated</p>
                    </div>
                    <div class="date-card" *ngIf="isfinancialData">
                        <div class="cards">
                            <div class="row-cards-body">
                                <p class="text-base cardheading" id="header">Total Assests</p>
                                <div class=" coloricons">
                                    <p class="text-bases"><img src='../../assets/images/arrow-up.png' width="20"
                                            class="cursor-pointer data-table"><span></span></p>
                                </div>
                                <p class="text-xl font-bold font-family" > {{financialValues[0]?.value | currency:'EUR':'symbol':'.0'}}</p>
                                <p class="p" *ngIf="!financialValues[1]?.value">0</p>
                                <div class="year ">
                                    <p>Previous Year</p>
                                    <p class="p">{{financialValues[1]?.value |currency:'EUR':'symbol':'.0'}}</p>
                                    <p class="p" *ngIf="!financialValues[1]?.value">0</p>
                                </div>
                            </div>
                        </div>
                        <div class="cards">
                            <div class="row-cards-body">
                                <p class="text-base cardheading" id="header1">Net Assets</p>
                                <div class=" coloricons">
                                    <p class="text-bases"><img src="../../assets/images/arrow-down.png" width="20"
                                            class="cursor-pointer data-table"><span></span></p>
                                </div>
                                <p class="text-xl font-bold font-family" > 0</p>
                                <div class="year ">
                                    <p>Previous Year</p>
                                    <p class="p">0</p>
                                </div>
                            </div>
                        </div>
                        <div class="cards">
                            <div class="row-cards-body">
                                <p class="text-base cardheading" id="header">Current Liablities</p>
                                <div class=" coloricons">
                                    <p class="text-bases"><img src='../../assets/images/arrow-up.png' width="20"
                                            class="cursor-pointer data-table"><span></span></p>
                                </div>
                                <p class="text-xl font-bold font-family" > {{currentLiabilities[0]?.value | currency:'EUR':'symbol':'.0'}}</p>
                                <p class="p" *ngIf="!currentLiabilities[1]?.value">0</p>
                                <div class="year ">
                                    <p>Previous Year</p>
                                    <p class="p">{{currentLiabilities[1]?.value |currency:'EUR':'symbol':'.0'}}</p>
                                    <p class="p" *ngIf="!currentLiabilities[1]?.value">0</p>
                                </div>
                            </div>
                        </div>
                        <div class="cards">
                            <div class="row-cards-body">
                                <p class="text-base cardheading" id="header">Turnover</p>
                                <div class=" coloricons">
                                    <p class="text-bases"><img src='../../assets/images/arrow-up.png' width="20"
                                            class="cursor-pointer data-table"><span></span></p>
                                </div>
                                <p class="text-xl font-bold font-family" > {{turnOver[0]?.value | currency:'EUR':'symbol':'.0'}}</p>
                                <p class="p" *ngIf="!turnOver[1]?.value">0</p>
                                <div class="year ">
                                    <p>Previous Year</p>
                                    <p class="p">{{turnOver[1]?.value |currency:'EUR':'symbol':'.0'}}</p>
                                    <p class="p" *ngIf="!turnOver[1]?.value">0</p>
                                </div>
                            </div>
                        </div>
                        <div class="cards">
                            <div class="row-cards-body">
                                <p class="text-base cardheading" id="header1">Cash in Hand</p>
                                <div class=" coloricons">
                                    <p class="text-bases"><img src="../../assets/images/arrow-down.png" width="20"
                                            class="cursor-pointer data-table"><span></span></p>
                                </div>
                                <p class="text-xl font-bold font-family" > 0</p>
                                <div class="year ">
                                    <p>Previous Year</p>
                                    <p class="p">0</p>
                                </div>
                            </div>
                        </div>
                        <div class="cards">
                            <div class="row-cards-body">
                                <p class="text-base cardheading" id="header">Employees</p>
                                <div class=" coloricons">
                                    <p class="text-bases"><img src='../../assets/images/arrow-up.png' width="20"
                                            class="cursor-pointer data-table"><span></span></p>
                                </div>
                                <p class="text-xl font-bold font-family" > {{financialData?.cisFinancialSearchData?.numberOfEmployees[0]?.noOfEmployee}}</p>
                                <p class="p" *ngIf="!financialData?.cisFinancialSearchData?.numberOfEmployees[0]?.noOfEmployee">0</p>
                                <div class="year ">
                                    <p>Previous Year</p>
                                    <p class="p">{{financialData?.cisFinancialSearchData?.numberOfEmployees[1]?.noOfEmployee}}</p>
                                    <p class="p" *ngIf="!financialData?.cisFinancialSearchData?.numberOfEmployees[1]?.noOfEmployee">0</p>
                                </div>
                            </div>
                        </div>
                </div>
                </div>
            </div>
        </div>
    </div>
   
    <hr class="mt-4">

    <div class="document-container mt-4">
        <div class="heading mt-3 ">
            <h2 id="documentUploadHeading">Documents</h2>
        </div>
        <div class="documents mt-3 pb-5">
            <div class="doc-card" [ngClass]="companyInfo?.certificateOfIncorporation? 'isUploaded':'none' " (click)="download(companyInfo?.certificateOfIncorporation)">
                <span class="check-icon" *ngIf="companyInfo?.certificateOfIncorporation"><i class="pi pi-check "></i></span>
                <div class="card-image">
                    <em class="icon-pdf"></em>
                </div>
                <div class="card-text">
                    <div class="texthead">
                        <p id="documents">Incorporation Documents</p>
                    </div>
                </div>
            </div>
            <div class="doc-card" [ngClass]="companyInfo?.memorandumArticlesOfAssociation? 'isUploaded':'none' " (click)="download(companyInfo?.memorandumArticlesOfAssociation)">
                <span class="check-icon" *ngIf="companyInfo?.memorandumArticlesOfAssociation"><i class="pi pi-check "></i></span>
                <div class="card-image">
                    <em class="icon-pdf"></em>
                </div>
                <div class="card-text">
                    <div class="texthead">
                        <p id="memorandum">Memorandum & Articles of Association</p>
                    </div>
                </div>
            </div>
            <div class="doc-card" [ngClass]="companyInfo?.financialStatementsLast3Years? 'isUploaded':'none' " (click)="download(companyInfo?.financialStatementsLast3Years)">
                <span class="check-icon" *ngIf="companyInfo?.financialStatementsLast3Years"><i class="pi pi-check "></i></span>
                <div class="card-image">
                    <em class="icon-pdf"></em>
                </div>
                <div class="card-text">
                    <div class="texthead">
                        <p id="financialStatement">Financial Statements for last three years</p>
                    </div>
                </div>
            </div>
            <div class="doc-card" [ngClass]="companyInfo?.tradingLicense? 'isUploaded':'none'" (click)="download(companyInfo?.tradingLicense)">
                <span class="check-icon" *ngIf="companyInfo?.tradingLicense"><i class="pi pi-check "></i></span>
                <div class="card-image">
                    <em class="icon-pdf"></em>
                </div>
                <div class="card-text">
                    <div class="texthead ">
                        <p id="tradingLicense">Trading License</p>
                    </div>
                </div>
            </div>
            
            <div class="doc-card" [ngClass]="companyInfo?.addressApproval? 'isUploaded':'none' " (click)="download(companyInfo?.addressApproval)">
                <span class="check-icon" *ngIf="companyInfo?.addressApproval"><i class="pi pi-check "></i></span>
                <div class="card-image">
                    <em class="icon-pdf"></em>
                </div>
                <div class="card-text">
                    <div class="texthead">
                        <p id="addressProof">Address Proof</p>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
</div>