import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { PATH } from 'src/app/app.constant';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-vendor-assessment-form',
  templateUrl: './vendor-assessment-form.component.html',
})
export class VendorAssessmentFormComponent implements OnInit {
  infoSecRiskForm:FormGroup;
  vendorType;
  vendorList:any;
  customerList:any;
  constructor(private router:Router, private httpService:HttpService, 
    private spinnerService: NgxSpinnerService, private appCookieService: AppCookieService,
    private toastrService: ToastrService, private formBuilder:FormBuilder, private dialogService:DialogService) { }

  ngOnInit(): void {
    this.riskForm();
    this.getvendorListListData('VENDOR');
    this.getvendorListListData('CUSTOMER');
  }

  riskForm(){
    this.infoSecRiskForm = this.formBuilder.group({
      vendorType:[],
      vendor:[[],[Validators.required]]
    })
  }

  getvendorListListData(val){
    let payload:any={};
    if(val=='VENDOR'){
      payload.searchStatus='VENDOR';
    }
    if(val=='CUSTOMER'){
      payload.searchStatus='CUSTOMER';
    }
    
    this.httpService.postData(`${PATH.VENDOR}/filter`, payload).subscribe((res:any)=>{
      if(val=='VENDOR'){
        this.vendorList = res['content'].map(elm=>{return {vendorName:elm.companyName,vendorId:elm.vendorId}});
      }
      if(val=='CUSTOMER'){
        this.customerList = res['content'].map(elm=>{return {vendorName:elm.companyName,vendorId:elm.vendorId}});
      }
      this.spinnerService.hide();
    },(err)=>{
      this.spinnerService.hide();
    })
  }

  checkVendorType(ev){
    this.vendorType = this.infoSecRiskForm.value.vendorType;
  }

  submit(){
    let payload = this.infoSecRiskForm.value.vendor;
    this.spinnerService.show();
    this.httpService.postData(PATH.INFO_SEC_RISK,payload).subscribe((res)=>{
      this.toastrService.success('Information Security Risk assigned successfully');
    },(err) => {
      this.spinnerService.hide();
    })
  }
}
