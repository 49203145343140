import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { ActivitiesComponent } from '../activities/activities.component';
import { ProfileInfoCompanyComponent } from '../profile-info-company/profile-info-company.component';
import { ProfileInfoUploadDocumentComponent } from '../profile-info-upload-document/profile-info-upload-document.component';
import { HttpService } from 'src/app/services/http.service';
import { PATH } from 'src/app/app.constant';
import { ToastrService } from 'ngx-toastr';
import { AppCookieService } from 'src/app/services/cookieService';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-profile-linking',
  templateUrl: './profile-linking.component.html',
  styleUrls: ['./profile-linking.component.scss']
})
export class ProfileLinkingComponent implements OnInit {
  private stepper: Stepper;
  step = 0;
  @ViewChild(ProfileInfoUploadDocumentComponent) upload: ProfileInfoUploadDocumentComponent;
  @ViewChild(ProfileInfoCompanyComponent) companyInfo: ProfileInfoCompanyComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private appCookieService: AppCookieService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    let stepper = document.querySelector('.profile-stepper');
    this.stepper = new Stepper(stepper, {
      linear: true,
      animation: true,
    });
    this.disbleBtns();
    this.route.params.subscribe((params) => {
      if (params.step) {
          this.stepper.to(parseInt(params.step));
          this.step=parseInt(params.step)
      }
    });
    this.getDefaultCompanyInfo();
    
  }
  stepperMove(data) {
    if (data.step == 1) {
      this.stepper.to(1);
      this.companyInfo.getCompanyInfo();
    } else if (data.step == 2) {
      this.stepper.to(2);
      this.upload.getCompanyInfo();
    }
    this.disbleBtns();
  }
  
  getCompanyInfo(data) {
    this.stepperMove(data);
    this.step = data.step;
  }

  getDefaultCompanyInfo() {
    let user = JSON.parse(this.appCookieService.get('digiUser'));
    this.spinnerService.show();
    this.httpService.getData(PATH.COMPANY_INFORMATION+'/profile').subscribe((res: any) => {
          this.spinnerService.hide();
        },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }

  disbleBtns(){
    document.getElementById("upload-part-trigger").removeAttribute("disabled");
    document.getElementById("companyInformation-part-trigger").removeAttribute("disabled");
  }
  
  pages(step){
    let data = {step:step}
    this.stepperMove(data);
    this.disbleBtns();
  }
}
