<div class="switch-profile" >
   <form [formGroup]="profileForm">
    <div class="row">
        <div class="col-md-6 form-group">
            <div>
                <label class="label" for="selectEntityType">Select Entity Type<span class="text-danger" >*</span> </label>
                <select class="form-select" id="selectEntityType" aria-label="Default select example" (change)="entityTypeChange($event)" placeholder="Select Entity Type" formControlName="entityType">
                <option selected=""   value="">Select Entity Type</option>
                <option *ngFor="let entity of entityTypeList" [value]="entity.value">{{entity.label}}
                </option>
                <!-- <p-dropdown [options]="entityTypeList" id="country" filter="true" name="country" formControlName="country" optionLabel="name"
                optionValue="name"  placeholder="Select a Country"  [ngClass]="{'is-invalid':formSubmitAttempt && f.country.errors}">
                </p-dropdown> -->
                </select>
            </div>
            <div app-errors class="error-msg" [control]="f.entityType" [submit]="formSubmitAttempt"
                label="Entity Type">
            </div>
        </div>
        <div class="col-md-6 form-group">
            <div>
                <label class="label" for="selectEntityName">Select Entity Name<span class="text-danger" >*</span></label>
                <select class="form-select" id="selectEntityName" aria-label="Default select example"  formControlName="entityId" [attr.disabled]="!f.entityType.value ? '' : null">
                <option  value="" selected="">Select Entity Name</option>
                <option *ngFor="let entity of entityNameList" [value]="entity.entityId">{{entity.entityName}} <span *ngIf="entity.invitedByOwnerName">- {{entity.invitedByOwnerName}}</span>
                </option>
                </select>
            </div>
            <div app-errors class="error-msg" [control]="f.entityId" [submit]="formSubmitAttempt"
                label="Entity Name">
            </div>
        </div>
    </div>

        <div class="buttons  mt-2">
            <button  class="btn cancel" id="closeBtn" type="button" [disabled]="profileForm.pristine"(click)="close()">Close</button>
            <button class="btn next" id="submitBtn" type="button" [disabled]="profileForm.pristine" (click)="submit()">Submit</button>
        </div>
    </form>
    <p-toast position="top-right">
    </p-toast>
    
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
</div>   

