<app-header-login></app-header-login>
<div class="login-cont-wrapper">
    <div class="login-container">
        <p class="subhead" id="createPassword">Create New Password</p>
        <div class="para">
            <p id="newPasswordText">Your new Password must be different from previously used passwords. </p>
        </div>

        <form [formGroup]="resetPasswordForm" (ngSubmit)="sendForgot()">
            <div class="form-group pass-form">
                <div class="login-input">
                    <em class="icon-lock icon"></em>
                    <input [type]="password" id="password" class="form-control  fc-icon" (keyup)="getValue($event)"  (mouseout)="passwordPopUP=false" (mouseover)="passwordPopUP=true" appBlockcopypaste
                    placeholder="Password" formControlName="password" [ngClass]="{'is-invalid':formSubmitAttempt && f.password.errors}" preventCutCopyPaste>
                    <em (click)="viewPassword()" *ngIf="iconShow" class="password-icon" [ngClass]="show ? 'icon-hide' : 'icon-view'" aria-hidden="true"></em>
                </div>
                <div class="password-checker" *ngIf="passwordPopUP">
                    <ul> 
                        <li [ngClass]="notMatch ? 'error-Msg' : 'non-validate'" >Password should meet the following requirements:</li>
                        <li [ngClass]="length ? 'validate' : 'non-validate'" ><span >✔</span> Length greater than or equal to 8 characters</li>
                        <li [ngClass]="isValidLength ? 'validate' : 'non-validate'" ><span >✔</span> Length less than or equal to 15 characters</li>
                        <li [ngClass]="UpperChar ? 'validate' : 'non-validate'" ><span>✔</span> Upper case character</li>
                        <li [ngClass]="LowerChar ? 'validate' : 'non-validate'" ><span>✔</span> Lower case character</li>
                        <li [ngClass]="Numeric ? 'validate' : 'non-validate'" ><span>✔</span> Numeric character</li>
                        <li [ngClass]="SpecialChar ? 'validate' : 'non-validate'" ><span>✔</span> Special character</li>
                    </ul>
                </div>
                 <div app-errors class="error-msg" [control]="f.password" [submit]="formSubmitAttempt" label="Password"></div>
              </div>
            <div class="form-group">
                <div class="login-input">
                    <em class="icon-lock icon"></em>
                    <input  [type]="reTypepassword" id="retypePaasword" class="form-control  fc-icon" (keyup)="rePassword($event)"  placeholder="Re-enter Password" formControlName="retypePassword" [ngClass]="{'is-invalid':formSubmitAttempt && f.retypePassword.errors}" appBlockcopypaste>
                    <em (click)="viewRePassword()" *ngIf="iconShow" class="password-icon" [ngClass]="showReType ? 'icon-hide' : 'icon-view'" aria-hidden="true"></em>
                </div>
                    <div app-errors class="error-msg" [control]="f.retypePassword" [submit]="formSubmitAttempt" label="Re-Enter Password"></div>
            </div>
            <div class="buttons">
                <button class="btn login-btn" id="createBtn">Create</button>
            </div>
        </form>
    </div>   
</div>