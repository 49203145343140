import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html'
})
export class DynamicFormComponent implements OnInit {

  @Input() subSection: {
    label: string;
    children: any[];
    parentGroup: any;
    form: FormGroup;
  };
  @Input() section;
  @Input() info;
  @Input() answerList;
  @Output() formResData: EventEmitter<any> = new EventEmitter();

  dynamicId:String;
  dynamicTarget:string;
  
  isDisable:boolean=false;

  constructor(private httpService:HttpService, 
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService
    ) {}

  ngOnInit() {
    if(this.info?.status!='PENDING'){
      this.isDisable = true;
    }
    this.dynamicId = this.subSection.label.replace(/\s/g, '');
    this.dynamicTarget = '#' + this.dynamicId;
  }

  handleTopRadioChange(ctrl, checked: boolean) {
    if (this.subSection.parentGroup) {
      const childEntries = this.subSection.parentGroup[ctrl.id];
      if(!childEntries){
        return
      }
      childEntries.forEach((c) => {
        const ct = this.subSection.form.get(c.id);
        if (ct && !checked) {
          ct.reset();
          ct.clearValidators();
        } else if (checked && c.mandatory) {
          ct.addValidators([Validators.required]);
        }
        ct.updateValueAndValidity();
      });
    }
  }

  saveSubSection(section, subSection, form){
    let question=[];
    for (const [key, value] of Object.entries(form)) {
      question.push({questionId:`${key}`,answer:`${value}`})
    }
    let payload = {
      id: this.getQuesId(this.answerList,section,subSection),
      question: question,
      section: section,
      subSection: subSection
    }
    this.spinnerService.show();
    this.httpService.updateData(PATH.INFO_SEC_RISK+'/settings/update-vendor-ques',payload).subscribe((res:any)=>{
      this.toastrService.success(subSection+' Saved Successfully');
      this.formResData.emit(res)
      this.spinnerService.hide();
    },(err)=>{
      this.spinnerService.hide();
    })

  }

  getQuesId(answer,section,subSection){
    let id:string="";
    if(answer){
      answer.forEach((elm:any)=>{
        if(elm.section==section && elm.subSection==subSection){
          id = elm.id
        }
      })
    }
    return id
  }

}
