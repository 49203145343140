<div class="digicomp-registrations">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xl-9">
            <h2 class="fw-bold" id="boardingHeading">Onboarding Journey</h2>

            <div class="stepper-container">
                <div class="bs-stepper stepper">
                    <div class="bs-stepper-header" role="tablist">
                        <div class="step" data-target="#companyInformation">
                            <button type="button" class="step-trigger" role="tab" id="companyInfoBtn"
                                aria-controls="companyInformation-part" id="companyInformation-part-trigger">
                                <span *ngIf="1<step" class="bs-active-circle"><img src="../../../assets/images/tick.png" alt="tick"></span>
                                <span *ngIf="1>=step" class="bs-stepper-circle">1</span>
                                <div class="text active">
                                    <span class="bs-stepper-label">Company <span class="info">Information</span> </span>
                                </div>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#upload-part">
                            <button type="button" class="step-trigger" role="tab" inputmode="uploadDocumentBtn" aria-controls="upload-part"
                                id="upload-part-trigger">
                                <span *ngIf="2<step" class="bs-active-circle"><img src="../../../assets/images/tick.png" alt="tick"></span>
                                <span *ngIf="2>=step" class="bs-stepper-circle">2</span>
                                <div class="texts">
                                    <span class="bs-stepper-label">Upload <span class="infos">Documents</span></span>
                                </div>
                            </button>
                        </div>


                        <div class="line"></div>
                        <div class="step" data-target="#questionnaire">
                            <button type="button" class="step-trigger" role="tab" id="questionnaireBtn" aria-controls="questionnaire"
                                id="questionnaire-trigger">
                                <span *ngIf="3<step" class="bs-active-circle"><img src="../../../assets/images/tick.png" alt="tick"></span>
                                <span *ngIf="3>=step" class="bs-stepper-circle">3</span>
                                <span class="bs-stepper-label">Questionnaire</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#esg">
                            <button type="button" class="step-trigger" role="tab" inputmode="esgBtn" aria-controls="esg" id="esg-trigger">
                                <span *ngIf="4<step" class="bs-active-circle"><img src="../../../assets/images/tick.png" alt="tick"></span>
                                <span *ngIf="4>=step" class="bs-stepper-circle">4</span>
                                <span class="bs-stepper-label-esg">ESG</span>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#regulations">
                            <button type="button" class="step-trigger" role="tab" id="regulationsBtn" aria-controls="regulations-part" id="regulations-part-trigger">
                                <span *ngIf="5<step" class="bs-active-circle"><img src="../../../assets/images/tick.png" alt="tick"></span>
                                <span *ngIf="5>=step" class="bs-stepper-circle">5</span>
                                <span class="bs-stepper-label">Regulations</span>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content p-0">
                        <div id="companyInformation" class="content" role="tabpanel"
                            aria-labelledby="companyInformation-part-trigger">
                            <div class="component">
                                <app-company-information-form (getCompanyInfo)="getCompanyInfo($event)">
                                </app-company-information-form>
                            </div>
                        </div>
                        <div id="upload-part" class="content" role="tabpanel" aria-labelledby="upload-part-trigger">
                            <div class="component">
                                <app-upload-documents (getCompanyInfo)="getCompanyInfo($event)"></app-upload-documents>
                            </div>
                        </div>

                        <div id="questionnaire" class="content" role="tabpanel" aria-labelledby="questionnaire-trigger">
                            <div class="component">
                                <company-info-questionnaire (getCompanyInfo)="getCompanyInfo($event)">
                                </company-info-questionnaire>
                            </div>
                        </div>

                        <div id="esg" class="content" role="tabpanel" aria-labelledby="esg-trigger">
                            <div class="component">
                                <company-info-esg (getCompanyInfo)="getCompanyInfo($event)"></company-info-esg>
                            </div>
                        </div>

                        <div id="regulations" class="content" role="tabpanel"
                            aria-labelledby="regulations-part-trigger">
                            <div class="component">
                                <app-regulations (getCompanyInfo)="getCompanyInfo($event)"></app-regulations>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-xl-3 col-sm-12 activities">
            <div class="right-container text-center">
                <circle-progress [percent]="percent" [radius]="80" [space]="-14" [outerStrokeWidth]="14"
                    [innerStrokeWidth]="14" [outerStrokeColor]="'#34a853'" [innerStrokeColor]="'#ebeff0'"
                    [animation]="true" [backgroundPadding]="20" [titleFontSize]="39" [titleFontWeight]="700"
                    [showSubtitle]="false" [unitsFontSize]="39" [unitsFontWeight]="700" [animationDuration]="300"
                    [renderOnClick]="false"></circle-progress>
                <p class="paragraph">Complete Your Profile</p>

                <div class="vendor-rfi-content" *ngIf="RfiShow">
                     <ul class="nav p-0 nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active flexbox-link" id="pills-activities-tab" data-bs-toggle="pill" data-bs-target="#pills-activities" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                            <span>Request For Information</span>
                        </a>
                    </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active px-2" id="pills-activities" role="tabpanel" aria-labelledby="pills-activities-tab">
                        <ul class="company-info-side-list list-none text-left">
                            <ng-container *ngFor="let rfi of responseRFI">
                                <div class="rfi-list">
                                    <li class="activityName mb-2 mt-2">Category :  {{rfi.category}}</li>
                                    <li class="activitysubCategory">SubCategory : {{rfi.subCategory}}</li>
                                    <li class="activitysubCategory">Date : {{rfi?.createdOn | date}}</li>
                                    <li class="activitysubCategory">Comment : {{rfi.comment}}</li>
                                </div>
                            </ng-container>
                        </ul>
                    </div>
                </div> 
                </div>

                <div *ngIf="!RfiShow">
                    <ul class="nav p-0 nav-pills nav-justified mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active flexbox-link" id="pills-activities-tab" data-bs-toggle="pill" data-bs-target="#pills-activities" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 141.4 117.75"><defs><style>.cls-1{fill:#fff;}</style></defs><title>activity</title><path d="M146.7,81.47c-1.1,4.63-1.72,9.45-3.39,13.86-8.5,22.43-24.71,36.12-48.51,38.93-24.38,2.88-43.55-6.95-57.16-27.43-.35-.53-.62-1.1-1-1.81.86-.54,1.61-1,2.38-1.48s1.6-.87,2.54-1.38c10,16.14,24.36,25.46,43.34,26.67,15.39,1,28.77-4.27,39.89-15,21.53-20.67,21.16-55.73-.45-76.36-24.2-23.1-65.55-18.41-82.89,12.11l-4.85-2.77A57.41,57.41,0,0,1,58.92,24.63c36.68-20.43,81.57,2.19,87.28,44a15.2,15.2,0,0,0,.5,1.84Z" transform="translate(-5.3 -17)"/><path d="M5.3,73.18h38v5.53H5.3Z" transform="translate(-5.3 -17)"/><path d="M114,45.58l3.9,4.29c-.47.52-1,1.19-1.64,1.8-5.59,5.6-11.15,11.21-16.79,16.75a2.62,2.62,0,0,0-.76,3.31A11.51,11.51,0,0,1,94,85.9,11.75,11.75,0,0,1,77.85,69.64a11.56,11.56,0,0,1,14-4.66c1.75.67,2.63.19,3.77-1C101.6,57.92,107.66,51.9,114,45.58ZM87.65,70.06a5.89,5.89,0,0,0,.28,11.77,5.89,5.89,0,0,0-.28-11.77Z" transform="translate(-5.3 -17)"/><path d="M55.27,55.56V61H17.19V55.56Z" transform="translate(-5.3 -17)"/><path d="M17.15,96.27V91H55.22v5.3Z" transform="translate(-5.3 -17)"/><path class="cls-1" d="M87.65,70.06a5.89,5.89,0,0,1,.28,11.77,5.89,5.89,0,0,1-.28-11.77Z" transform="translate(-5.3 -17)"/></svg>
                            <span id="activity">Activity</span>
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link flexbox-link" id="pills-notes-tab" data-bs-toggle="pill" data-bs-target="#pills-notes" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                            <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 127.24 127.14"><title>note</title><path d="M50.17,115.13c-6.42,0-12.46,0-18.5,0-13.18,0-21.62-8.37-21.65-21.49q0-28.69,0-57.39c0-13.15,8.45-21.49,21.63-21.5h84c13.17,0,21.51,8.36,21.52,21.56q0,28.55,0,57.07c0,13.46-8.26,21.72-21.66,21.75-8.57,0-17.14.08-25.71,0a7.11,7.11,0,0,0-5.75,2.32c-7,7.2-14.16,14.22-21.2,21.37-2.47,2.52-5.18,4-8.66,2.49s-4.11-4.42-4.08-7.79C50.21,127.53,50.17,121.57,50.17,115.13Zm6.75,20.14c8.54-8.56,16.41-16.35,24.14-24.28a7.83,7.83,0,0,1,6.27-2.67q15,.24,30.1,0a12.8,12.8,0,0,0,13-12.66q.21-30.73,0-61.47c0-7-5.62-12.31-12.64-12.67-.63,0-1.25,0-1.88,0q-42.34,0-84.68,0c-8.42,0-14.36,5.37-14.39,13.23q-.14,30.25,0,60.53a13.05,13.05,0,0,0,13,13.06c5.64.16,11.29,0,16.94,0,3.31,0,6.63,0,10.23,0Z" transform="translate(-10 -14.74)"/><path d="M30.29,47.93V41.75H117v6.18Z" transform="translate(-10 -14.74)"/><path d="M30.27,68V61.85h79.86V68Z" transform="translate(-10 -14.74)"/><path d="M30.27,88.1V81.94H83.56V88.1Z" transform="translate(-10 -14.74)"/></svg>                       
                            <span class="span" id="notes"> Notes</span>
                        </a>
                    </li>
    
                </ul>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active px-2" id="pills-activities" role="tabpanel" aria-labelledby="pills-activities-tab">
                        <app-activities></app-activities>
                    </div>
                    <div class="tab-pane fade px-2" id="pills-notes" role="tabpanel" aria-labelledby="pills-notes-tab">
                        <div class="form-group card-body">
                            <ul class="list-none text-left">
                                <ng-container *ngFor="let notes of notesRes">
                                    <li class="activityName mb-1">Note:- {{notes.notesText }}</li>
                                    <li class="activityDate">By {{notes.username}} {{notes.date | date: 'MMM dd,yyyy hh:mm:ss'}} </li>
                                </ng-container>
                            </ul>
                            <p id="addNotes">Add Notes Here...</p>
                            <textarea class="form-control noteTextarea"  rows="3" [(ngModel)]="notes" autoResize="autoResize" pInputTextarea >
                            </textarea>
                            <br>
                            <button class="btn btn-primary mt-2" id="addNoteBtn" (click)="addNote()">Add Note</button>
                        </div>
                    </div>
                </div> 
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false"></p-confirmDialog>