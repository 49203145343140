<div class="directors mt-4">
    <div class="heading">
        <h1 id="director">Internal</h1>
    </div>
    <div class="row">
        <div class="page-content mt-3">
            <div class="showList mt-2">
                <p-table class="table catalogue-table" [columns]="locationheaders" [value]="companyInfo?.boardOfDirectors"
                    class="p-table mt-5" responsiveLayout="scroll" [resizableColumns]="true"  >
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-container *ngFor="let col of columns; let i = index">
                                <th class="cursor-pointer "  id="{{ 'header' + i }}" pSortableColumn={{col.name}}>{{col.header}}<img
                                        *ngIf="col.sort" class="topbottom"
                                        src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" >
                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-directors>
                        <tr style="height: 56px;color: gray;">
                            <td>{{directors?.fullName}}</td>
                            <td>
                            <img (click)="download(directors?.personalIdentification)" class="pdf-img" src="../../../assets/images/dashboard-icons/pdf.png" alt="">                               
                            </td>
                            <td>
                            <img (click)="download(directors?.passport)" class="pdf-img" src="../../../assets/images/dashboard-icons/pdf.png" alt="">                               
                        </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>

    <div class="carosuel">
        <p-carousel [value]="companyInfo?.shareHolders" [numVisible]="4" [numScroll]="4" [circular]="false"
            [responsiveOptions]="responsiveOptions">
            <ng-template pTemplate="header">
                <p class="share" id="shareHolder">Shareholders</p>
            </ng-template>
            <ng-template let-share pTemplate="item">
                <div class="product-item">
                    <div class="product-item-content">

                        <div class="name">
                            <h4 class="p-mb-1" id="companyName">{{share?.companyName}}</h4>
                        </div>
                        <div class="circle">
                            <div class="mt4">
                                <circle-progress [percent]=share?.ownedPercentage [space]="-10" [radius]="60" [outerStrokeWidth]="10"
                                    [innerStrokeWidth]="10" [outerStrokeColor]="'#51449c'" [titleFontSize]="25" [titleFontWeight]="700" [unitsFontSize]="25" [unitsFontWeight]="700"
                                    [innerStrokeColor]="'#e7e8ea'" [toFixed]="1" [showSubtitle]="false" [animation]="false" [animationDuration]="1000">
                                </circle-progress>
                            </div>
                        </div>
                        <div class="percentage">
                            <p id="ownedPercentage">{{share?.ownedPercentage}}% of Share Held</p>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </div>

    <div class="row">
        <div class="page-content mt-3">
            <div class="showList mt-2">


                <p-table class="table catalogue-table" [columns]="shareholderheader" [value]="companyInfo?.shareHolders"
                    class="p-table mt-5" responsiveLayout="scroll" [resizableColumns]="true">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <ng-container *ngFor="let col of columns; let i = index">
                                <th class="cursor-pointer "  id="{{ 'headerTable' + i }}" pSortableColumn={{col.name}}>{{col.header}}<img
                                        *ngIf="col.sort" class="topbottom"
                                        src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" >
                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-share>
                        <tr style="height: 56px;color: gray;">
                            <td>{{share.companyName}}</td>
                            <td>{{share.ownedPercentage}} %</td>
                            <td>
                                <img (click)="download(share?.personalIdentification)" class="pdf-img" src="../../../assets/images/dashboard-icons/pdf.png" alt="">                               

                            </td>
                            <td>
                                <img (click)="download(share?.passport)" class="pdf-img" src="../../../assets/images/dashboard-icons/pdf.png" alt="">                               

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<div class="directors">
    <div class="heading">
        <h1 id="director">External</h1>
    </div>
    <app-bank-dashboard></app-bank-dashboard>
    
        <!-- <app-bank-dashboard ></app-bank-dashboard>  -->

</div>