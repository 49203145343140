import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ErrorsComponent } from './errors/errors.component';
import { NgbDateCustomParserFormatter } from '../services/dateformat.service';
import { HttpService } from '../services/http.service';
import { AppInterceptor } from '../services/interceptor';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';
import { PaginationComponent } from './pagination/pagination.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FormControlComponent } from './form-field/form-field.component';
import { NavigationService } from '../services/navigation.service';
import { BackButtonDirective } from './back-button.directive';

const IMP_EXP = [
  ReactiveFormsModule, 
  HttpClientModule,
  FormsModule,
  ConfirmDialogModule,
  ToastModule,
  MultiSelectModule,
  TabViewModule,
  DropdownModule,
  TooltipModule,
  NgbModule,
  Ng2TelInputModule,
  TableModule,
  MultiSelectModule,
  DialogModule,
  ButtonModule,
  RadioButtonModule,
  NgbModule,
  ConfirmDialogModule,
  ToastModule,
  InputTextModule,
  InputSwitchModule,
  DropdownModule,
  InputTextareaModule,
  InputNumberModule,
  TooltipModule
];

@NgModule({
  declarations: [ErrorsComponent, PaginationComponent, DynamicFormComponent, 
    FormControlComponent, BackButtonDirective
  ],
  imports: [...IMP_EXP,
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts
    })
  ],
  exports: [...IMP_EXP, CommonModule, ErrorsComponent, PaginationComponent, DynamicFormComponent, FormControlComponent
  ],
  providers: [
    HttpService, MessageService, ConfirmationService, DialogService, NavigationService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
})
export class SharedModule {}
