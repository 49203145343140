import { createAction, props } from "@ngrx/store";
export const invokeHeaderAPI = createAction(
    "[Header API] invoke header Fetch API"
)

export const HeaderAPISuccess = createAction(
    "[Header Success API] invoke header Fetch API",
    props<{ allData: any }>()
)

export const invokeCompanyAPI = createAction(
    "[Company API] invoke Company Fetch API"
)

export const CompanyAPISuccess = createAction(
    "[Company Success API] invoke Company Fetch API Success",
    props<{ allCompanyInfo: any }>()
) 

export const invokeUpdateBookAPI = createAction(
    "[Company Update ] invoke Company update api",
    props<{payload:any, user:string}>()
    
)

export const DeleteRedux = createAction(

    "[Redux Delete] delete Redux Logout",

    props<{payload:any}>()

)

export const invokeCustomerCompanyInfo = createAction(

    "[CustomerCompany Update ] invoke CustomerCompanyInformation  api",

    props<{customerPayload:any}>()
)

// export const UpdateBookAPISuccess = createAction(
//     "[Company Update Success] update Company api Success",
//     props<{response:any}>()
// )
