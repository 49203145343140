<div class="terms-conditions">
    <div class="input-container">
        <div >
            <pdf-viewer [src]="pdfSrc"
                [render-text]="true"
                [original-size]="false"
                style="width: 100%; height:400px" [stick-to-page]="true"
            ></pdf-viewer>
        </div>
    </div>
    <hr class="mt-3">
    <div class="buttons agree">
        <input  class="form-check-input" [disabled] ='disableBtn' type="checkbox" name="terms"  [(ngModel)]="inputData.value" >
        <label class="form-check-label" for="check" id="agreeCheckBox">
            I agree</label>
    </div>
    <div class="buttons agree">
            <button class="btn next" id="submitBtn" type="submit" [disabled]="disableBtn" (click)="submit()">SUBMIT</button>
    </div>
</div>
