<app-header-login></app-header-login>
<div class="login-cont-wrapper">
    <div class="login-container">
        <p class="subhead-login" id="welcomeText">Welcome TO</p>
        <div class="login-img">
            <img src="../../../assets/images/logo.png" alt="login image" width="185"> 
        </div>

        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-group">
                <div class="login-input">
                    <em class="icon-user-line icon"></em>
                    <label class="form-label" for="username">User Name: </label>
                    <input type="text" class="form-control" id="username" placeholder="Email" formControlName="username" name="username" [ngClass]="{'is-invalid':formSubmitAttempt && f.username.errors}" (keydown)="validators.space($event)">
                </div>
                <div app-errors class="error-msg" [control]="f.username" [submit]="formSubmitAttempt" label="Email" ></div>
            </div>
            <div class="form-group mt-4">
                <div class="login-input">
                    <em class="icon-lock icon"></em>
                    <label class="form-label" for="password">Password: </label>
                    <input [type]="password" id="password" (keyup)="rePassword($event)" class="form-control fc-icon" placeholder="Password" formControlName="password" name="password" 
                    [ngClass]="{'is-invalid':formSubmitAttempt && f.password.errors}" (keydown)="validators.space($event)" appBlockcopypaste (paste)="onPaste()">
                    <em *ngIf="iconShow"  (click)="onClick()" class="password-icon" [ngClass]="show ? 'icon-hide' : 'icon-view'" aria-hidden="true"></em>
                </div>
                <div app-errors class="error-msg" [control]="f.password" [submit]="formSubmitAttempt" label="Password"></div>
            </div>
            <div class="row flex-box">
                <div class="col">
                    <div class="form-check">
                        <input class="form-check-input" formControlName="rememberMe" type="checkbox" id="autoSizingCheck2">
                        <label class="form-check-label" for="autoSizingCheck2">
                        Remember me
                </label>
                    </div>
                </div>
                <div class="col text-end">
                    <a href="" class="forgot-pass" [routerLink]="['/forgot-password']" id="forgotPass">Forgot Password?</a>
                </div>
            </div>
            <div>
                <button type="submit" class="btn login-btn" id="login-button" id="loginBtn">Login</button>
            </div>
            <div class="text-center">
                <div class="new-user" id="joinDigicomp" >New User? <a [routerLink]="['/register']">Join DigiComp+</a></div>
            </div>
        </form>
    </div>
</div>