import { AppCookieService } from 'src/app/services/cookieService';
import { Component,Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { PATH } from 'src/app/app.constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SwitchProfileComponent } from '../switch-profile/switch-profile.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Store } from '@ngrx/store';
import { Selector } from 'src/app/redux/selector';
import { DeleteRedux, invokeCompanyAPI } from 'src/app/redux/action';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() collapsed:any;
  user: any;
  companyLogo:any;
  showRfi= true;
  notificationLength:0;
  notification:boolean=false;
  imgUrl;
  companyInfo:any={};
  isVendor:boolean=false;
  isOwner:boolean=false;
  loggedInUser:any={};
  isVendorLog:boolean=false;
  isMultipleAssociation:boolean=false;
  count:number=0;
  constructor(
    private router: Router,
    private httpService: HttpService,
    private appCookieService: AppCookieService,
    private spinnerService: NgxSpinnerService,
    private sharedDataService:SharedDataService,
    public dialogService: DialogService,
    public store: Store
  ) {}

  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));

    if(this.user?.eroles=='ROLE_VENDOR_ADMIN' || this.user?.eroles=='ROLE_VENDOR_NONADMIN'){
      this.getCompanyInfo();

      this.isVendorLog=true;
    }

    if(this.user?.eroles == 'ROLE_OWNER_ADMIN' || this.user?.eroles == 'ROLE_OWNER_NONADMIN'){
      this.customerInfo();
      this.isVendorLog=false;
    }

    this.getLogo();

    if (!this.user) {
      this.router.navigate(['/login']);
    }

    if(this.user?.eroles != 'ROLE_ADMIN'){
      this.getCustomerCompanyImg();
      this.getAssociation();
    }

    this.getLoggedInUser();
    if(this.count<1){
      this.vendorProfile();
    }
  }

  vendorProfile(){
    this.sharedDataService.header.subscribe((value) => {
        if(value=='vendor-profile'){
          this.count=this.count+1;
          this.ngOnInit();
        }
    });
  }
  
  getCustomerCompanyImg(){
    this.sharedDataService.customObservable.subscribe((res) => {
      if(res.imgUrl && res.companyLogo){
        this.imgUrl=res.imgUrl;
        this.companyLogo=res.companyLogo;
      }
    });
  }

  getCompanyInfo() {
    this.store.dispatch(invokeCompanyAPI());
    this.store.select(Selector).subscribe((res: any) => {
      if(res?.companyInfo?.length > 0){
        this.companyInfo = JSON.parse(JSON.stringify(res.companyInfo[0]));
        this.companyLogo=this.companyInfo.companyLogo
        this.getFile(this.companyInfo?.companyLogo)
      }
    })
  }

  getFile(data){
    if(data){
      this.httpService.getImage(PATH.FILE_UPLOAD+'?fileName='+data).subscribe((res)=>{
        this.imgUrl = res;
        this.spinnerService.hide();
      })
    }
  }

  getLogo() {
    let file = JSON.parse(this.appCookieService.get('digiLogo'));
    if(file){
      this.httpService
      .getData(PATH.FILE_UPLOAD + file.fileName)
      .subscribe((res: string) => {
        this.companyLogo = res;
      });
      this.spinnerService.hide();
    }else{
      this.companyLogo = '';
    }
  }
  
  logout() {
    sessionStorage.clear();
    let deleteRedux = 'deleteRedux';
    this.store.dispatch(DeleteRedux({payload:{deleteRedux}}));
    this.router.navigate(['/login']);

    
  }

  getRFI(id) {
    this.spinnerService.show();
    this.httpService.getData(PATH.RFI+'/findByVendorInformationId/'+id).subscribe((res: any) => {
      this.notificationLength = res.length; 
      this.notification = true; 
      this.spinnerService.hide();
    },(error) => {
        this.spinnerService.hide();
      }
    )
  }
  
  goToCompanyInfo(){
    this.router.navigate(['/vendor/vendor-info/1']);
  }

  customerInfo(){
    this.httpService.getData(PATH.COMPANY_BASIC_INFO).subscribe((res:any)=>{
      if(res){
        this.companyInfo = res;
        this.companyLogo=res.companyLogo
        this.getFile(this.companyInfo?.companyLogo);
      }
    },(error) => {
      this.spinnerService.hide();
    });
  }

  chooseProfile(){
    this.dialogService.open(SwitchProfileComponent, {
      header: 'Switch Profile',
      width: '40%',
    });
  }

  getAssociation(){
    this.httpService.getData(PATH.GET_ASSOCIATION).subscribe((res:any)=>{
      for(let i=0;i<res.length;i++){
        if(res[i]['eroles']=='ROLE_VENDOR_ADMIN'){
          this.isVendor=true;
        }
        if(res[i]['eroles']=='ROLE_OWNER_ADMIN'){
          this.isOwner=true;
        }
      }
      if(res.length>1){
        this.isMultipleAssociation=true;
      }
        this.spinnerService.hide();
    });
  }

  getLoggedInUser(){
  this.httpService.getData(PATH.USER_MANAGEMENT+'/loggedInUser').subscribe((res:any)=>{
      this.loggedInUser=res; 
      this.spinnerService.hide();
    });
  }

  linkProfile(){
    this.router.navigate(['vendor/profile-info',1])
  }
}










































