<div class="dynamic-form">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" id="piBtn" data-bs-toggle="collapse"
        [attr.data-bs-target]="dynamicTarget" aria-expanded="true" aria-controls="collapseOne">
        <h3>{{ subSection.label }}</h3>
      </button>
    </h2>
    <div [id]="dynamicId" class="accordion-collapse collapse show" aria-labelledby="headingOne"
      data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <div class="row">
          <div class="col-md-6" *ngFor="let ctrl of subSection.children">
            <div class="form-group" *ngIf="ctrl.type === 'RADIO'; else otherFields">
              <p class="paragraphQuestion">{{ ctrl.questionName }}</p>
                <div class="field-radiobutton">
                  <p-radioButton [name]="ctrl.id" value="Yes" [formControl]="$any(subSection.form.get(ctrl.id))" 
                  (click)="handleTopRadioChange(ctrl, false)" [disabled]="isDisable" inputId="yes"></p-radioButton>
                  <label for="yes">Yes</label>
                  <p-radioButton [name]="ctrl.id" value="No" [formControl]="$any(subSection.form.get(ctrl.id))" 
                  (click)="handleTopRadioChange(ctrl, false)" [disabled]="isDisable" inputId="no"></p-radioButton>
                  <label for="no">No</label>
              </div>
  
              <div *ngIf="subSection.form.get(ctrl.id).value === 'Yes' && subSection.parentGroup[ctrl.id]">
                <div *ngFor="let pc of subSection.parentGroup[ctrl.id]">
                  <app-form-field [control]="$any(subSection.form.get(pc.id))" [ctrl]="pc" [disable]="isDisable">
                  </app-form-field>
                </div>
              </div>
            </div>
            <ng-template #otherFields>
              <app-form-field [control]="$any(subSection.form.get(ctrl.id))" [ctrl]="ctrl" [disable]="isDisable">
              </app-form-field>
            </ng-template>
          </div>
        </div>
        <div class="buttons">
          <button *ngIf="!isDisable" class="btn next" type="button"
            (click)="saveSubSection(section, subSection.label, subSection.form.value)"
            [disabled]="subSection.form.invalid">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
