import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PATH } from 'src/app/app.constant';
import { SuccessDialogComponent } from 'src/app/components';
import { email, noSpecialCharAllow, noExtraWhiteSpace, onlyCharacters, phoneNumberOnly, regNumber } from 'src/app/services/custom.validations';
import { HttpService } from 'src/app/services/http.service';
import { ValidatorsServiceService } from 'src/app/services/validators-service.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html'
})
export class RegistrationComponent implements OnInit {
  toolTipIdentification: any;

  codes: any = [
    "Customer",
    "Vendor",
  ];
  state: any;
  @ViewChild('phone') input: ElementRef;
  countries: any;
  error: boolean = false;
  errorMsg: string = "";
  formSubmitAttempt: boolean = false;
  verificationCode: string;
  userDetails: any;
  companyNameVerificationCode: string;
  companyNameDetails: any;
  customerNameVerificationCode: string;
  customerNameDetails: any;
  countryCode = 'in';
  hasError: boolean = false;
  inputOptions = { initialCountry: this.countryCode, separateDialCode: true };
  codeObj: any;
  flagCountry: boolean = false;
  subscription: Subscription;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private modalService: NgbModal,
    private validators: ValidatorsServiceService) {
    this.subscription = router.events.subscribe((event) => {
      if(this.router.url!="/register" && this.router.url!="/login" ){

        localStorage.removeItem('dataset');
      }
      this.flagCountry = !router.navigated;
      if (this.flagCountry) {
        localStorage.removeItem('dataset');
      }
    });
  }

  public registerForm = this.formBuilder.group({
    country: [[], [Validators.required]],
    emailId: ["", [Validators.required, email, noExtraWhiteSpace, Validators.maxLength(80)]],
    firstName: ["", [Validators.required, noSpecialCharAllow, Validators.minLength(2), Validators.maxLength(20), onlyCharacters, noExtraWhiteSpace]],
    lastName: ["", [Validators.required, noSpecialCharAllow, Validators.minLength(2), Validators.maxLength(20), onlyCharacters, noExtraWhiteSpace]],
    companyName: ["", [Validators.required, noExtraWhiteSpace, Validators.minLength(2), Validators.maxLength(50)]],
    phoneNumber: ['', [Validators.required, noSpecialCharAllow, phoneNumberOnly]],
    identificationType: [],
    identificationNumber: ['', [Validators.required, noExtraWhiteSpace, regNumber, Validators.minLength(5), Validators.maxLength(50)]],
  })

  ngOnInit(): void {
    this.grtCountriesList();
    if (localStorage.getItem('dataset')) {
      this.codeObj = JSON.parse(localStorage.getItem('dataset'));
      this.inputOptions = { initialCountry: this.codeObj.country.countryCode, separateDialCode: true };
      this.registerForm.patchValue({
        country: this.codeObj.country,
        identificationType: this.codeObj.country.documentType,
        identificationNumber: this.codeObj.identificationNumber,
        firstName: this.codeObj.firstName,
        lastName: this.codeObj.lastName,
        emailId: this.codeObj.emailId,
        companyName: this.codeObj.companyName,
        phoneNumber: null
      })
      this.registerForm.updateValueAndValidity();
      this.registerForm.controls['identificationType'].disable();
    }
  }

  signup() {
    this.formSubmitAttempt = true
    this.error = false;
    if (this.registerForm.invalid) {
      return
    }
    if (!this.hasError) {
      this.toastrService.error('Phone number is not valid');
      return
    }

    let payload = this.registerForm.getRawValue();
    payload.emailId = payload.emailId.toLowerCase();
    payload.type = payload.country.documentTypeCode;
    payload.country = payload.country.countryName;
    payload.countryCode = this.countryCode
    this.spinnerService.show();
    this.httpService.postData(PATH.OWNER_SELF_REGISTRATION + '/register', payload).subscribe((res) => {
      this.spinnerService.hide();
      this.registerForm.reset();
      this.formSubmitAttempt = false;
      this.openSuccessModel();
    }, (err) => {
      this.spinnerService.hide();
      this.error = true;
      if (err.message.message) {
        this.errorMsg = err.message.message;
      } else {
        let error = err.message.errors;
        error = error.map(el => { return el.field + ': ' + el.message })
        this.errorMsg = error.join(", ");
      }
      this.toastrService.error(this.errorMsg);
    }
    )
  }

  get f() {
    return this.registerForm.controls;
  }


  onCountryChange(obj) {
    this.countryCode = obj.iso2
  }

  onError(obj) {
    this.hasError = obj;
  }


  openSuccessModel() {
    let modelRef = this.modalService.open(SuccessDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "center",
    })
    modelRef.componentInstance.type = 'register';
  }

  grtCountriesList() {
    this.spinnerService.show();
    this.httpService.getData(PATH.GET_ALL_COUNTRIES + '?page=1&size=1000').subscribe((res) => {
      let countryList = res['content'];
      this.countries = countryList.map((elm) => { return { name: elm.countryName, code: elm } })
      this.spinnerService.hide();
    })
  }

  changeCountry(ev) {
    this.inputOptions = null;
    let data: any = ev.value;
    this.toolTipIdentification = data.documentType;
    this.countryCode = data.countryCode;
    localStorage.setItem('countryCode', this.countryCode);
    localStorage.setItem('dataObj', JSON.stringify(data));
    this.inputValueEvent(ev);
    this.inputOptions = { initialCountry: this.countryCode, separateDialCode: true };
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  space(event: any) {
    this.validators.space(event);
  }

  inputValueEvent(event: any) {
    let parseObj = JSON.parse(localStorage.getItem('dataObj'));
    let payload: any = {
      'country': parseObj ? parseObj : null,
      'firstName': this.registerForm.controls['firstName'].value,
      'lastName': this.registerForm.controls['lastName'].value,
      'companyName': this.registerForm.controls['companyName'].value,
      'identificationNumber': this.registerForm.controls['identificationNumber'].value,
      'emailId': this.registerForm.controls['emailId'].value,
      'phoneNumber': this.registerForm.controls['phoneNumber'].value,
      'isChanged': this.flagCountry
    }

    localStorage.setItem('dataset', JSON.stringify(payload));
  }

  numberOnly(event: any) {
    const regexpNumber = /\d/;
    let inputCharacter = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
      event.preventDefault();
    }
  }

  characterOnly(event: any) {
    const regexpNumber = /^([a-zA-Z]*)$/;
    let inputCharacter = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
      event.preventDefault();
    }
  }


}
