import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { coreComponents } from './components';
import * as pages from './pages';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { rootReducer } from './redux/reducer';
import { Effects } from './redux/effects';
import { VendorModule } from './vendor-module/vendor.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CanDeactivateTeam } from './services/auth.gaurd';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { CheckYouremailComponent } from './pages/check-youremail/check-youremail.component';
import { CreateNewpasswordComponent } from './pages/create-newpassword/create-newpassword.component';
import { SharedModule } from './shared-module/shared.module';
import { SwitchProfileComponent } from './components/switch-profile/switch-profile.component';
import { BlockcopypasteDirective } from './pages/blockcopypaste.directive';

import { VendorCommentDialogComponent } from './components/vendor-comment-dialog/vendor-comment-dialog.component';
import { OwnerSurveyViewpageDialogComponent } from './components/view-survey-dialog/view-survey-dialog.component';
import { VendorAssessmentFormComponent } from './components/vendor-assessment-form/vendor-assessment-form.component';
import { DatePipe } from '@angular/common';
@NgModule({
  declarations: [...coreComponents, ...pages.corePages,
    AppComponent,
    ForgotPasswordComponent,
    CheckYouremailComponent,
    CreateNewpasswordComponent,
    SwitchProfileComponent,
    BlockcopypasteDirective,
    VendorCommentDialogComponent,
    OwnerSurveyViewpageDialogComponent,
    VendorAssessmentFormComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    VendorModule,
    Ng2TelInputModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    SharedModule,
    StoreModule.forFeature('DigiComp', rootReducer),
    EffectsModule.forFeature([Effects]),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    // it is for redux if you want to see the data flow on redux 
    // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
  ],
  providers: [CanDeactivateTeam, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
