import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PATH } from 'src/app/app.constant';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  @Input() companyInfo:any;
  options:any;
  customer: Array<any> = []; 
  basicOptions: any;
  pdfData:any=[];
  cardCount:any;
  logo:any;
  user:any;
  isfinancialData:boolean=false;
  entityId:any;
  imgUrl;
  cisFinancialData:any;
  turnOver:any;
  currentLiabilities:any;
  isOverlay:boolean=true;
  financialData:any;
  invitedCompDetails;
  financialValues:any;
  constructor( private router: Router,
    private httpService: HttpService,
    private spinnerService: NgxSpinnerService, private appCookieService: AppCookieService) { }

  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.entityId = this.user.entityId;
  this.customer=[
    {numbers:'0',heading:'Total Assests',img:'../../assets/images/arrow-up.png',cost:'0', assests:'0', year:'Previous Year'},
    {numbers:'0',heading:'Net Assets',img:'../../assets/images/arrow-down.png',cost:'0', assests:'0', year:'Previous Year'},
    {numbers:'0',heading:'Current Liablities',img:'../../assets/images/arrow-up.png',cost:'0', assests:'0', year:'Previous Year'},
    {numbers:'0',heading:'Cash in Hand',img:'../../assets/images/arrow-down.png',cost:'0', assests:'0', year:'Previous Year'},
    {numbers:'0',heading:'Turnover',img:'../../assets/images/arrow-up.png',cost:'0', assests:'0', year:'Previous Year'},
    {numbers:'0',heading:'Employees',img:'../../assets/images/arrow-up.png',cost:'0', assests:'0', year:'Previous Year'},
    ]
    this.cardInfo();
    this.prepareCharts();
    this.getInvCompDetails();
    this.getCisDashboardData();
    
  }


  getInvCompDetails(){
    this.spinnerService.show();
      this.httpService.getData(PATH.OWNER_BASIC_DETAILS).subscribe((res: any) => {
        this.invitedCompDetails = res;
        this.spinnerService.hide();
      },(error) => {
        this.spinnerService.hide();
      });
  }
  

  download(evt) {
    if(!evt){
      return
    }
    this.spinnerService.show();
    let type = this.checkDocumentType( evt);
    this.httpService.download(PATH.FILE_UPLOAD+'?fileName='+evt).subscribe((res)=>{
      let file = new Blob([res], {type: type});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.spinnerService.hide();

    })
  }

  checkDocumentType(filename){
    this.spinnerService.show();
      let fileType = filename.split('.').pop();
      if(fileType == 'jpeg'){
        return 'image/jpeg';
      }
      if(fileType == 'pdf'){
        return 'application/pdf';
      }
      if(fileType == 'png'){
        return 'image/png';
      }
      if(fileType == 'gif'){
        return 'image/gif';
      }
    }

  cardInfo() {
    this.spinnerService.show();
    this.httpService.getData(PATH.VENDOR_DASHBOARD_COUNT).subscribe((res: any) => {
          this.cardCount = res;
          this.spinnerService.hide();
          },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }

  gotovendordashboard(){
    this.router.navigate(['/vendor/vendor-info/1'])
  }


  prepareCharts(){
    this.options = {
      xAxis: {
        type: 'category',
        data: [2020,2021,2022]
      },
      yAxis: {  
        type: 'value'
      },
      series: [
        {
          data: [10, 20, 30],
          type: 'bar',
          showBackground: false,
          color:'#2b3858',
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)'
          }
        }
      ]

    };
  }

  getCisDashboardData(){
    // DASHBOARD
    this.httpService.getData(`${PATH.DASHBOARD}/FINANCIAL/${this.entityId}`).subscribe((res: any) => {
      this.cisFinancialData=res['content'];
      this.cisFinancialData.forEach(element => {
        this.financialData=element
        this.financialData?.cisFinancialSearchData?.financialDetail?.balanceSheets.forEach(element => {
          if(element.particular=='Total Assets'){
            this.isfinancialData=true;
            this.isOverlay=false;
            this.financialValues=element.financialValues;
          }
          if(element.particular=='Current Liabilities'){
            this.isfinancialData=true;
            this.isOverlay=false;
            this.currentLiabilities=element.financialValues;
          }
        });
        this.financialData?.cisFinancialSearchData?.financialDetail?.incomeStatements.forEach(element => {
          if(element.particular=='Operating Revenue / Turnover'){
            this.isfinancialData=true;
            this.isOverlay=false;
            this.turnOver=element.financialValues;
          }
        });
        
      });

      
    })
  }

}
