<div class="add-success-dialog">
    <div class="success-dialog">
        <div class="sucessbox">
            <div class="img">
                <img src="../../../assets/images/success.gif" alt="">
            </div>
            <div class="sucesstext">
                <h1 id="submitSurvey">Survey Submitted Successfully!</h1>
            </div>
        </div>
    </div>
</div>