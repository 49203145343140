import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PATH } from 'src/app/app.constant';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';
@Component({
  selector: 'app-vendor-info-sec-risk',
  templateUrl: './vendor-info-sec-risk.component.html',
  styleUrls: ['./vendor-info-sec-risk.component.scss']
})
export class VendorInfoSecRiskComponent implements OnInit {
  pageSize = 10;
  page: number = 0;
  pager: any = {};
  size = 10;
  user:any;
  headers:any;
  vendorInfoRisk:any;
  constructor(private router:Router, private httpService:HttpService, 
    private spinnerService: NgxSpinnerService, private appCookieService: AppCookieService,
    private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.prepareHeader();
    this.getVendorRiskData();
  }

  prepareHeader(){
    this.headers = [
      { name: 'ownerName', header: 'Owner Name',width:'25%',sort: true,isAsc:false},
      { name: 'createdDate', header: 'Date',width:'25%',sort: true,isAsc:false},
      { name: 'status', header: 'Status',width:'25%',sort: true,isAsc:false},
      { name: 'action', header: 'Actions', width:'25%',sort: false,isAsc:false}
    ]
  }

  view(id){
    this.router.navigate(['vendor/vendor-assessment-form/'+id])
  }

  getVendorRiskData(){
    this.spinnerService.show();
    this.httpService.getData(`${PATH.INFO_SEC_RISK}/logged-in-vendor/?pageNumber=${this.page}&pageSize=${this.size}`).subscribe((res:any)=>{
      this.vendorInfoRisk = res.content;
      this.pager.totalPages = res['totalPages'];
      this.spinnerService.hide();
    },(err) => {
      this.spinnerService.hide();
      this.toastrService.error(err.message.message);
    })
  }

}
