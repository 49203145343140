<div class="add-usermanagment">
    <div class="page-heading  mt-4">
        <div class="left">
            <h1 id="addUserAccess">Add User Access Management</h1>
        </div>
        <div class="right">
            <a class="btn btn-back" id="back" [routerLink]="['/vendor/users']">
                <em class="icon-back icon" aria-hidden="true"></em><span>Back</span>
            </a>
        </div>
    </div>

    <form  [formGroup]="userForm">
        <div class="row companyuser">
            <div class="col-12">
                <div class="row mt-2">
                    <div class="col-md-3 form-group">
                        <label for="firstName" class="">First Name <span class="text-danger">*</span></label>
                        <input id="firstName" ngDefaultControl type="text" class="form-control" name="firstName" formControlName="firstName" (keydown)="validators.space($event)">
                        <div app-errors class="error-msg" [control]="f.firstName"  label="First Name"></div>
                    </div>
                    
                    <div class="col-md-3 form-group">
                        <label for="lastName" class="">Last Name<span class="text-danger" >*</span></label>
                        <input id="lastName" ngDefaultControl type="text" class="form-control" name="lastName" formControlName="lastName" (keydown)="validators.space($event)">
                        <div app-errors class="error-msg" [control]="f.lastName"  label="Last Name"></div>
                    </div>

                    <div class="col-md-3 form-group">
                        <label for="emailAddress" class="">Email Address<span class="text-danger">*</span></label>
                        <input type="text" ngDefaultControl id="emailAddress" name="emailAddress" class="form-control" formControlName="email" [disabled]="editMode" (keydown)="validators.space($event)">
                        <div app-errors class="error-msg" [control]="f.email"  label="Email Address"></div>
                    </div>

                    <div class="col-md-3 form-group">
                        <label for="mobileNumber" class="user-mangment">Phone Number<span class="text-danger">*</span></label>
                        <input id="mobileNumber" ngDefaultControl type="text" (keypress)="numberOnly($event)" name="mobileNumber" formControlName="mobileNumber" class="form-control" placeholder="   " [ng2TelInputOptions]="{initialCountry:countryCode,separateDialCode:true}"
                        (hasError)="onError($event)" (countryChange)="onCountryChange($event)" ng2TelInput>
                        <div app-errors class="error-msg" [control]="f.mobileNumber"  label="Phone Number"></div>
                        <div class="error-msg" *ngIf="(f.mobileNumber.touched &&  f.mobileNumber.value!='') && !hasError">
                            Invalid Phone Number
                        </div>
                    </div>
                </div>
                <div class="row flex-box" >
                
                    <div class="col-md-3 form-group">
                        <div class="heading">
                            <h3 for="Locations" id="locationLabel" class="user-mangment mt-4 mb-2">Locations<span
                                class="text-danger">*</span></h3>
                        </div>
                            <p-multiSelect class="borders" [options]="countries" ngDefaultControl filter="true" formControlName="locations" filterPlaceHolder="Search Location" [showToggleAll]="false"
                                optionLabel="name" id="Locations" (keypress)="characterOnly($event)" optionValue="code"
                                (onChange)="changeCountry($event)" placeholder="Select a Location"
                                [ngClass]="{'is-invalid':formSubmitAttempt && f.country.errors}" [displaySelectedLabel]="false">
                            </p-multiSelect>
                        <div app-errors class="error-msg phone" [control]="f.locations" [submit]="formSubmitAttempt"
                            label="Location">
                        </div>
                    </div>
                    
                    <div class="list col-md-9">
                        <div class="heading">
                            <label for="SelectedLocations" class="user-mangment mt-4 mb-3" *ngIf="userForm.value.locations.length>0">Selected Locations</label>
                        </div>
                        <div class="country-list mt-2"  >
                            <div class="grid flex-box">
                                <div *ngFor="let location of userForm.value.locations; let i=index" >
                                    <div class="tab">
                                        <div class="tab-1">
                                            <label [for]="location" class="tab-label">{{location}}</label>
                                        </div>
                                        <div class="tab-2">
                                            <em class="icon-cross-circle cursor-pointer data-table" id="{{ 'deleteLocation' + i }}" (click)="deleteLocation(location,i)" ></em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
        
                <h3 class="mt-4" id="roleHeading">Role<span class="text-danger">*</span></h3>
                <div app-errors class="error-msg" [control]="f.eroleSet"  label="Role"></div>
                <div class="row">
                    <div class="col-md-3 form-group"  style="display: flex;"  *ngFor="let role of roles">
                        <input [id]="role.label" ngDefaultControl type="checkbox" (change)="onAccessChange($event)" [value]="role.value" [checked]='role.check' class="form-check-input ml-2" />
                        <label [for]="role.label" class=" form-check ">{{role.label}}</label>
                    </div>
                    
                </div>
        
                <h3 class="mt-4" id="departmentHeading">Departments<span class="text-danger">*</span></h3>
                <div app-errors class="error-msg" [control]="f.departments"  label="Departments"></div>
                <div class="row" >
                    <div class="col-md-3 form-group"  style="display: flex;" *ngFor="let department of departments">
                        <input [id]="department.label" ngDefaultControl type="checkbox" (change)="onDepartmentsChange($event)" [value]="department.value" [checked]='department.check' class="form-check-input ml-2" />
                        <label [for]="department.label" class=" form-check ">{{department.label}}</label>
                    </div>
                </div>
               
                <div class="buttons mt-5 mb-5">
                    <button type="button" class="btn cancel" id="reset-button" (click)="cancelClick()" [disabled]="userForm.pristine && !isCheckboxChange">Reset</button>
                    <button type="button" class="btn next" id="submit-button" (click)="submit()" [disabled]="userForm.pristine && !isCheckboxChange">Submit</button>
                </div>
            </div>
        </div>
    </form>
</div>