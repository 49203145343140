<form class="info-form" [formGroup]="infoForm" *ngIf="loggdinVendorDetails">
    <div class="buttons import-btn mb-3 mt-2" *ngIf="isProfileRes && this.infoForm?.get('status')?.value!='Submitted'">
        <button class="btn cancel" type="button" id="importProfileBtn" (click)="importProfile()">Import Profile</button>
    </div>
    <div class="accordion" id="accordionPanelsStayOpenExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" id="piBtn" data-bs-toggle="collapse"
                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Particular Information
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label for="companyName">Company Name <span class="text-danger"
                                    *ngIf="this.vendorSetting?.companyName">*</span> </label>
                            <input type="text" id="companyName" class="form-control" readonly name="companyName"
                                (keydown)="validators.space($event)" formControlName="companyName"
                                placeholder="Company Name">
                            <div app-errors class="error-msg" [control]="f.companyName" [submit]="formSubmitAttempt"
                                label="company name"></div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="formerName">Former Name (if any) <span class="text-danger"
                                    *ngIf="this.vendorSetting?.formerName">*</span></label>
                            <input type="text" id="formerName" formControlName="formerName" class="form-control"
                                (keydown)="validators.space($event)">
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="contactPerson">Contact Person <span class="text-danger"
                                    *ngIf="this.vendorSetting?.contactPerson">*</span></label>
                            <input type="text" id="contactPerson" class="form-control" formControlName="contactPerson"
                                (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.contactPerson" [submit]="formSubmitAttempt"
                                label="Contact Person"></div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="phoneNumber">Phone Number <span class="text-danger"
                                    *ngIf="this.vendorSetting?.phoneNumber">*</span></label>
                            <div class="input-group">
                                <input #phone type="text" id="phoneNumber" (keypress)="numberOnly($event)"
                                    class="form-control w-100" ng2TelInput placeholder="   "
                                    formControlName="phoneNumber"
                                    [ng2TelInputOptions]="{initialCountry:countryCode,separateDialCode:true}"
                                    (hasError)="onError($event)" (countryChange)="onCountryChange($event)" />
                            </div>
                            <div app-errors class="error-msg" [control]="f.phoneNumber" [submit]="formSubmitAttempt"
                                label="Phone Number"></div>
                            <div class="error-msg"
                                *ngIf="(f.phoneNumber.touched && f.phoneNumber.value!='') && !hasError">
                                Invalid Phone Number
                            </div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="emailAddress">Email Address <span class="text-danger"
                                    *ngIf="this.vendorSetting?.emailAddress">*</span></label>
                            <input type="email" id="emailAddress" class="form-control" name="emailAddress" readonly
                                (keydown)="validators.space($event)" formControlName="emailAddress">
                            <div app-errors class="error-msg" [control]="f.emailAddress" [submit]="formSubmitAttempt"
                                label="Email"></div>

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="companyRegistrationNumber">Company Registration Number <span class="text-danger"
                                    *ngIf="this.vendorSetting?.companyRegistrationNumber">*</span></label>
                            <input type="text" id="companyRegistrationNumber" class="form-control"
                                formControlName="companyRegistrationNumber" (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.companyRegistrationNumber"
                                [submit]="formSubmitAttempt" label="Company Registration Number"></div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="category">Category <span class="text-danger"
                                    *ngIf="this.vendorSetting?.category">*</span></label>
                            <select class="form-select" id="category" aria-label="Default select example"
                                formControlName="category">
                                <option selected disabled value="">Select Category</option>
                                <option *ngFor="let category of categories " [value]="category">{{category}}</option>

                            </select>
                            <div app-errors class="error-msg" [control]="f.category" [submit]="formSubmitAttempt"
                                label="Category"></div>

                        </div>


                        <div class="col-md-6 form-group">
                            <label for="vatRegistrationNumber">VAT Registration Number <span class="text-danger"
                                    *ngIf="this.vendorSetting?.vatRegistrationNumber">*</span></label>
                            <input type="text" id="vatRegistrationNumber" formControlName="vatRegistrationNumber"
                                class="form-control" (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.vatRegistrationNumber"
                                [submit]="formSubmitAttempt" label="VAT Registration Number"></div>

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="dunsNumber">Duns Number <span class="text-danger"
                                    *ngIf="this.vendorSetting?.dunsNumber">*</span></label>
                            <input type="text" id="dunsNumber" class="form-control" formControlName="dunsNumber"
                                (keypress)="numberOnly($event)" (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.dunsNumber" [submit]="formSubmitAttempt"
                                label="Duns Number"></div>
                        </div>
                        <div class="col-md-6 form-group">

                            <label for="dateOfIncorporation">Date of Incorporation <span class="text-danger"
                                    *ngIf="this.vendorSetting?.dateOfIncorporation">*</span></label>
                            <div class="input-group">
                                <p-calendar class="w-100" id="dateOfIncorporation" [maxDate]="maxDate"
                                    dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon"
                                    formControlName="dateOfIncorporation" [readonlyInput]="true"></p-calendar>
                            </div>
                            <div app-errors class="error-msg" [control]="f.dateOfIncorporation"
                                [submit]="formSubmitAttempt" label="Date of Incorporation"></div>

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="legalStatus">Legal Status <span class="text-danger"
                                    *ngIf="this.vendorSetting?.legalStatus">*</span></label>
                            <select class="form-select" aria-label="Default select example"
                                formControlName="legalStatus" id="legalStatus">
                                <option selected disabled value="">Select Legal Status</option>
                                <option *ngFor="let legalStatus of legalStatuses" [value]="legalStatus">{{legalStatus}}
                                </option>
                            </select>
                            <div app-errors class="error-msg" [control]="f.legalStatus" [submit]="formSubmitAttempt"
                                label="Legal Status"></div>

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="websiteUrl">Website URL <span class="text-danger"
                                    *ngIf="this.vendorSetting?.websiteUrl">*</span></label>
                            <input type="text" id="websiteUrl" class="form-control" formControlName="websiteUrl"
                                (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.websiteUrl" [submit]="formSubmitAttempt"
                                label="Website URL"></div>

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="productOrService">Product / Service <span class="text-danger"
                                    *ngIf="this.vendorSetting?.productOrService">*</span></label>
                            <p-multiSelect [options]="productandservices" id="productOrService" required
                                formControlName="productOrService" class="select-height"></p-multiSelect>
                            <div app-errors class="error-msg" [control]="f.productOrService"
                                [submit]="formSubmitAttempt" label="Product/Service"></div>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="annualRevenueInUsd">Annual Revenue in USD <span class="text-danger"
                                    *ngIf="this.vendorSetting?.annualRevenueInUsd">*</span></label>
                            <input type="text" id="annualRevenueInUsd" (keypress)="numberOnly($event)"
                                class="form-control" formControlName="annualRevenueInUsd"
                                (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.annualRevenueInUsd"
                                [submit]="formSubmitAttempt" label="Annual Revenue"></div>
                        </div>
                        <div class="col-md-6">
                            <label for="companyLogo">Company Logo <span class="text-danger"
                                    *ngIf="this.vendorSetting?.companyLogo">*</span></label>
                            <div class="uploadContainer space-between"
                                [ngClass]="{  'disable-div': this.companyInfo?.status == 'Submitted'}">

                                <p class="title" id="logoname"> {{logoname}}</p>
                                <span class="inline-icon align-middle">
                                    <em *ngIf="logoView" class="icon-view icon" pTooltip="View" tooltipPosition="bottom"
                                        (click)="download()"></em>
                                    <em *ngIf="!disableBtn" class="icon-upload icon" pTooltip="Upload"
                                        tooltipPosition="bottom" (click)="upload()"></em>
                                </span>

                            </div>
                            <div app-errors class="error-msg" [control]="f.companyLogo" [submit]="formSubmitAttempt"
                                label="Company Logo"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" id="addressBtn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Addresses
                </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <h3 class="registered" id="registeredAddressHeading">Registered Address </h3>
                    <div class="row" formGroupName="registeredAddress">

                        <div class="col-md-6 form-group">
                            <label for="country">Country <span class="text-danger"
                                    *ngIf="this.vendorSetting?.registeredAddress.country">*</span></label>
                            <select class="form-select" id="country" aria-label="Default select example"
                                formControlName="country" (change)="changeCountry('registered')">
                                <option selected value="" disabled>Select Country</option>
                                <option *ngFor="let country of countries" [value]="country.name">{{country.name}}
                                </option>
                            </select>
                            <div app-errors class="error-msg" [control]="f.registeredAddress.get('country')"
                                [submit]="formSubmitAttempt" label="Country"></div>
                            <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['registeredAddress'].get('country') && infoForm.controls['registeredAddress'].get('country').errors?.required">
                                Country is required
                            </div> -->

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="city">City / Town <span class="text-danger"
                                    *ngIf="this.vendorSetting?.registeredAddress.city">*</span></label>
                            <select class="form-select" id="city" aria-label="Default select example"
                                formControlName="city">
                                <option selected value="" disabled>Select City</option>
                                <!-- <option *ngFor="let city of registeredCities" [value]="city.name">{{city.name}}</option> -->
                                <option *ngFor="let city of registeredCities" [value]="city">{{city}}</option>
                            </select>
                            <div app-errors class="error-msg" [control]="f.registeredAddress.get('city')"
                                [submit]="formSubmitAttempt" label="City"></div>
                            <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['registeredAddress'].get('city') && infoForm.controls['registeredAddress'].get('city').errors?.required">
                                City is required
                            </div> -->
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="addresses">Address <span class="text-danger"
                                    *ngIf="this.vendorSetting?.registeredAddress.addresses">*</span></label>
                            <input type="text" class="form-control" id="addresses" formControlName="addresses"
                                (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.registeredAddress.get('addresses')"
                                [submit]="formSubmitAttempt" label="Address"></div>
                            <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['registeredAddress'].get('addresses') && infoForm.controls['registeredAddress'].get('addresses').errors?.required">
                                Address is required
                            </div> -->
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="postalCode">Postal Code<span class="text-danger"
                                    *ngIf="this.vendorSetting?.registeredAddress.postalCode">*</span></label>
                            <input type="text" class="form-control" id="postalCode" formControlName="postalCode"
                                (keypress)="numberOnly($event)" (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.registeredAddress.get('postalCode')"
                                [submit]="formSubmitAttempt" label="Postal Code"></div>
                            <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['registeredAddress'].get('postalCode') && infoForm.controls['registeredAddress'].get('postalCode').errors?.required">
                                Postal Code is required
                            </div> -->
                        </div>

                    </div>

                    <h3 class="h3"  id="operationalHeading">Operational Address</h3>
                    <div class="form-check">
                        <input class="form-check-input mr-2" type="checkbox" formControlName="isSameAddress"
                            id="flexCheckDefault" (change)="sameAddressChecked()">
                        <label class="form-check-label mt-1" for="flexCheckDefault">
                            Same as Registered Address
                        </label>
                    </div>

                    <div class="row" formGroupName="operationalAddress">
                        <div class="col-md-6 form-group">
                            <label for="countryOP">Country <span class="text-danger"
                                    *ngIf="this.vendorSetting?.operationalAddress.country">*</span></label>
                            <select class="form-select" id="countryOP" aria-label="Default select example"
                                formControlName="country" (change)="changeCountry('operational')">
                                <option selected value="" disabled>Select Country</option>
                                <option *ngFor="let country of countries" [value]="country.name">{{country.name}}
                                </option>
                            </select>
                            <div app-errors class="error-msg" [control]="f.operationalAddress.get('country')"
                                [submit]="formSubmitAttempt" label="Country"></div>
                            <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['operationalAddress'].get('country') && infoForm.controls['operationalAddress'].get('country').errors?.required">
                                Country is required
                            </div> -->
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="cityOp">City / Town <span class="text-danger"
                                    *ngIf="this.vendorSetting?.operationalAddress.city">*</span></label>
                            <select class="form-select" id="cityOp" aria-label="Default select example"
                                formControlName="city">
                                <option selected value="" disabled>Select City</option>
                                <option *ngFor="let city of oprationalCities" [value]="city">{{city}}</option>
                            </select>
                            <div app-errors class="error-msg" [control]="f.operationalAddress.get('city')"
                                [submit]="formSubmitAttempt" label="City"></div>
                            <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['operationalAddress'].get('city') && infoForm.controls['operationalAddress'].get('city').errors?.required">
                                City is required
                            </div> -->
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="addressesOp">Address <span class="text-danger"
                                    *ngIf="this.vendorSetting?.operationalAddress.addresses">*</span></label>
                            <input type="text" id="addressesOp" class="form-control" formControlName="addresses"
                                (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.operationalAddress.get('addresses')"
                                [submit]="formSubmitAttempt" label="Address"></div>
                            <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['operationalAddress'].get('addresses') && infoForm.controls['operationalAddress'].get('addresses').errors?.required">
                                Address is required
                            </div> -->
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="postalCodeOp">Postal Code <span class="text-danger"
                                    *ngIf="this.vendorSetting?.operationalAddress.postalCode">*</span></label>
                            <input type="text" class="form-control" id="postalCodeOp" formControlName="postalCode"
                                (keypress)="numberOnly($event)" (keydown)="validators.space($event)">
                            <div app-errors class="error-msg" [control]="f.operationalAddress.get('postalCode')"
                                [submit]="formSubmitAttempt" label="Postal Code"></div>
                                <!-- <div class="error-msg"
                                *ngIf="infoForm.controls['operationalAddress'].get('postalCode') && infoForm.controls['operationalAddress'].get('postalCode').errors?.required">
                                Postal Code is required
                            </div> -->
                        </div>
                    </div>

                    <h3 class="h3"  id="previousAddress">Previous Address (If Any)</h3>
                    <div class="row" formGroupName="previousAddress">
                        <div class="col-md-6 form-group">
                            <label for="countryPa">Country <span class="text-danger"
                                    *ngIf="this.vendorSetting?.previousAddress.country">*</span></label>
                            <select class="form-select" id="countryPa" aria-label="Default select example"
                                formControlName="country" (change)="changeCountry('previous')">
                                <option selected value="" disabled>Select Country</option>
                                <option *ngFor="let country of countries" [value]="country.name">{{country.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="cityPa">City / Town <span class="text-danger"
                                    *ngIf="this.vendorSetting?.previousAddress.city">*</span></label>
                            <select class="form-select" id="cityPa" aria-label="Default select example"
                                formControlName="city">
                                <option selected value="" disabled>Select City</option>
                                <option *ngFor="let city of previousCities" [value]="city">{{city}}</option>
                            </select>
                        </div>
                        <div class="col-md-6 form-group">
                            <label for="addressesPa">Address <span class="text-danger"
                                    *ngIf="this.vendorSetting?.previousAddress.addresses">*</span></label>
                            <input type="text" class="form-control" id="addressesPa" formControlName="addresses"
                                (keydown)="validators.space($event)">

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="postalCodePa">Postal Code <span class="text-danger"
                                    *ngIf="this.vendorSetting?.previousAddress.postalCode">*</span></label>
                            <input type="text" class="form-control" id="postalCodePa" formControlName="postalCode"
                                (keypress)="numberOnly($event)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" id="bodButton" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Board of Directors
                </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">

                    <p id="listMember">(Please list all members of the board of directors or partnership)</p>
                    <div formArrayName="boardOfDirectors">
                        <ng-container
                            *ngFor="let director of infoForm.get('boardOfDirectors')['controls'] let i=index;">
                            <div [formGroupName]="i" class="row">
                                <div class="col-md-4 form-group">
                                    <label for="{{'fullName' + i}}">Full Name <span class="text-danger"
                                            *ngIf="this.vendorSetting?.boardOfDirectors.fullName">*</span></label>
                                    <input type="text" class="form-control" id="{{'fullName' + i}}" name="fullName"
                                        formControlName="fullName" (keydown)="validators.space($event)">
                                    <div class="error-msg"
                                        *ngIf="director.get('fullName').touched && director.get('fullName').errors?.required">
                                        Full Name is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'nationality' + i}}">Nationality <span class="text-danger"
                                            *ngIf="this.vendorSetting?.boardOfDirectors.nationality">*</span></label>
                                    <select class="form-select" id="{{'nationality' + i}}"
                                        aria-label="Default select example" formControlName="nationality">
                                        <option selected disabled value="">Select Country</option>
                                        <option *ngFor="let country of countries" [value]="country.name">
                                            {{country.name}}</option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="director.get('nationality').touched && director.get('nationality').errors?.required">
                                        Nationality is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'countryOfResidence' + i}}">Country of Residence <span
                                            class="text-danger"
                                            *ngIf="this.vendorSetting?.boardOfDirectors.countryOfResidence">*</span></label>
                                    <select class="form-select" id="{{'countryOfResidence' + i}}"
                                        aria-label="Default select example" formControlName="countryOfResidence">
                                        <option selected value="" disabled>Select Country</option>
                                        <option *ngFor="let country of countries" [value]="country.name">
                                            {{country.name}}</option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="director.get('countryOfResidence').touched && director.get('countryOfResidence').errors?.required">
                                        Country is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'identificationNumber' + i}}">Identification Number <span
                                            class="text-danger"
                                            *ngIf="this.vendorSetting?.boardOfDirectors.identificationNumber">*</span></label>
                                    <input type="text" class="form-control" id="{{'identificationNumber' + i}}"
                                        name="identificationNumber" (keydown)="validators.space($event)"
                                        formControlName="identificationNumber">
                                    <div class="error-msg"
                                        *ngIf="director.get('identificationNumber').touched && director.get('identificationNumber').errors?.required">
                                        Passport number is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'FormAddress' + i}}">Address <span class="text-danger"
                                            *ngIf="this.vendorSetting?.boardOfDirectors.address">*</span></label>
                                    <input type="text" class="form-control" id="{{'FormAddress' + i}}" name="address"
                                        formControlName="address" (keydown)="validators.space($event)">
                                    <div class="error-msg"
                                        *ngIf="director.get('address').touched && director.get('address').errors?.required">
                                        Address is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'FormPostalCode' + i}}">Postal Code <span class="text-danger"
                                            *ngIf="this.vendorSetting?.boardOfDirectors.postalCode">*</span></label>
                                    <input type="text" class="form-control" id="{{'FormPostalCode' + i}}"
                                        name="postalCode" (keydown)="validators.space($event)"
                                        formControlName="postalCode" (keypress)="numberOnly($event)">
                                    <div class="error-msg"
                                        *ngIf="director.get('postalCode').touched && director.get('postalCode').errors?.required">
                                        Postal Code is required
                                    </div>
                                </div>

                                <div class="col" *ngIf="infoForm.get('boardOfDirectors').length>1">
                                    <button class="btn btn-primery remove" id="{{'deleteBtn' + i}}"
                                        (click)="removeDirector(i)">
                                        <span aria-hidden="true" class="close">&times;</span>
                                    </button>
                                    <div class="line">
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col" *ngIf="infoForm.get('boardOfDirectors')['controls'].length < 10"
                            id="{{addBod}}">
                            <a class="add" *ngIf="!disableBtn" (click)="addDirectors()">Add More Board of Directors
                                +</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" id="shareholderBtn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Shareholders
                </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <p id="textLabel">(Please state the name of all shareholders/partners/members who own more than 10%
                        of the company
                        shares or voting rights or name of any individuals who otherwise exercise control over the
                        management of the company)</p>
                    <div formArrayName="shareHolders">
                        <ng-container *ngFor="let holder of infoForm.get('shareHolders')['controls'] let i=index;">
                            <div [formGroupName]="i" class="row">
                                <div class="vendor-share col-md-12">
                                    <input class="form-check-input ratiobtn " id="{{ 'flexRadioDefault' + i }}"
                                        type="radio" formControlName="type" (keydown)="validators.space($event)"
                                        value="company">
                                    <label class="form-check-radiolabel radioValue" for="{{ 'flexRadioDefault' + i }}">
                                        Company
                                    </label>
                                    <input class="form-check-input ratiobtn ml-3" type="radio" formControlName="type"
                                        (keydown)="validators.space($event)" value="individual"
                                        id="{{ 'ratiobtn' + i }}">
                                    <label class="form-check-radiolabel radioValue " for="{{ 'ratiobtn' + i }}">
                                        Individual
                                    </label>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{ 'companyName' + i }}">Company / Full Name (Individual) <span
                                            class="text-danger"
                                            *ngIf="this.vendorSetting?.shareHolders.companyName">*</span></label>
                                    <input type="text" class="form-control" id="{{ 'companyName' + i }}"
                                        name="companyName" formControlName="companyName"
                                        (keydown)="validators.space($event)">
                                    <div class="error-msg"
                                        *ngIf="holder.get('companyName').touched  && holder.get('companyName').errors?.required">
                                        Company/Full Name is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{ '%Owned' + i }}">% Owned <span class="text-danger"
                                            *ngIf="this.vendorSetting?.shareHolders.ownedPercentage">*</span></label>
                                    <input type="text" class="form-control" id="{{ '%Owned' + i }}" min="10" max="100"
                                        name="ownedPercentage" (keydown)="validators.space($event)"
                                        formControlName="ownedPercentage" (keypress)="numberOnlyWithDismal($event)">

                                    <div class="error-msg"
                                        *ngIf="holder.get('ownedPercentage').touched && holder.get('ownedPercentage').errors?.required">
                                        Percentage is required
                                    </div>
                                    <div class="error-msg"
                                        *ngIf="(holder.get('ownedPercentage').touched && holder.get('ownedPercentage').errors?.percentage )">
                                        Percentage value should be between 10 and 100
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label *ngIf="holder.get('type').value=='company'"
                                        for="{{'companyOrIndividual' + i}}">Country of Incorporation <span
                                            class="text-danger"
                                            *ngIf="this.vendorSetting?.shareHolders.countryOfIncorporation">*</span></label>
                                    <label *ngIf="holder.get('type').value=='individual'"
                                        for="{{'companyOrIndividual' + i}}">Country of Residence <span
                                            class="text-danger"
                                            *ngIf="this.vendorSetting?.shareHolders.personalIdentification">*</span></label>
                                    <select class="form-select" aria-label="Default select example"
                                        id="{{'companyOrIndividual' + i}}" formControlName="countryOfIncorporation">
                                        <option selected value="" disabled>Select Country</option>
                                        <option *ngFor="let country of countries" [value]="country.name">
                                            {{country.name}}</option>
                                    </select>
                                    <div class="error-msg"
                                        *ngIf="holder.get('countryOfIncorporation').touched && holder.get('countryOfIncorporation').errors?.required">
                                        Country Name is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'identificationNumber' + i}}">Identification Number <span
                                            class="text-danger"
                                            *ngIf="this.vendorSetting?.shareHolders.identificationNumber">*</span></label>
                                    <input type="text" class="form-control" id="{{'identificationNumber' + i}}"
                                        name="identificationNumber" (keydown)="validators.space($event)"
                                        formControlName="identificationNumber">
                                    <div class="error-msg"
                                        *ngIf="holder.get('identificationNumber').touched && holder.get('identificationNumber').errors?.required">
                                        Identification number is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'FormAdd' + i}}">Address <span class="text-danger"
                                            *ngIf="this.vendorSetting?.shareHolders.address">*</span></label>
                                    <input type="text" class="form-control" id="{{'FormAdd' + i}}" name="address"
                                        formControlName="address" (keydown)="validators.space($event)">
                                    <div class="error-msg"
                                        *ngIf="holder.get('address').touched && holder.get('address').errors?.required">
                                        Address is required
                                    </div>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label for="{{'postalCode' + i}}">Postal Code <span class="text-danger"
                                            *ngIf="this.vendorSetting?.shareHolders.postalCode">*</span></label>
                                    <input type="text" class="form-control" name="postalCode" id="{{'postalCode' + i}}"
                                        formControlName="postalCode" (keypress)="numberOnly($event)"
                                        (keydown)="validators.space($event)">
                                    <div class="error-msg"
                                        *ngIf="holder.get('postalCode').touched && holder.get('postalCode').errors?.required">
                                        PostalCode is required
                                    </div>
                                </div>

                                <div class="col" *ngIf="infoForm.get('shareHolders').length>1">
                                    <button class="btn btn-primery remove" aria-label="close" id="{{'remove' + i}}"
                                        title="remove shareholder" (click)="removeShareholder(i)">
                                        <span aria-hidden="true" class="close">&times;</span>
                                        
                                    </button>
                                    <div class="line">
                                        <hr>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col mt-2" *ngIf="infoForm.get('shareHolders')['controls'].length < 10"
                            id="addShareHolder">
                            <a class="add" *ngIf="!disableBtn" (click)="addShareholders()">Add More Shareholders</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button collapsed" id="parentCompany" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Parent Company
                </button>
            </h2>
            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row" formGroupName="parentCompany">
                        <div class="col-md-6 form-group">
                            <label for="parCompany">Parent Company Name <span class="text-danger"
                                    *ngIf="this.vendorSetting?.parentCompany?.name">*</span></label>
                            <input type="text" class="form-control" id="parCompany" formControlName="name"
                                (keydown)="validators.space($event)">

                            <div class="error-msg"
                                *ngIf="infoForm.controls['parentCompany'].get('name') && infoForm.controls['parentCompany'].get('name').errors?.required">
                                Parent Company Name
                            </div>

                        </div>
                        <div class="col-md-6 form-group">
                            <label for="countryOfDomicile">Country of Domicile <span class="text-danger"
                                    *ngIf="this.vendorSetting?.parentCompany?.countryOfDomicile">*</span></label>

                            <select class="form-select" id="countryOfDomicile" aria-label="Default select example"
                                formControlName="countryOfDomicile">
                                <option selected value="" disabled>Select Country</option>
                                <option *ngFor="let country of countries" [value]="country.name">
                                    {{country.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" id="branchesBtn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Branches In other Country
                </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div formArrayName="branchInOtherCountry">
                        <ng-container
                            *ngFor="let branch of infoForm.get('branchInOtherCountry')['controls']; let i=index">
                            <div [formGroupName]="i" class="row mt-2 mb-3">
                                <div class="col-md-6">
                                    <label for="{{'BranchOutside' + i}}">Branch In Other Country <span
                                            class="text-danger"
                                            *ngIf="this.vendorSetting?.branchInOtherCountry?.branch">*</span></label>
                                    <input type="text" class="form-control" id="{{'BranchOutside' + i}}" name="branch"
                                        formControlName="branch" (keydown)="validators.space($event)">
                                </div>
                                <div class="col col-md-6" *ngIf="infoForm.get('branchInOtherCountry').length>1">
                                    <button class="btn btn-primery remove mt-5" id="{{'removeBranch' + i}}"
                                        (click)="removeBranch(i)">
                                        <span aria-hidden="true" class="close">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <div class="col" *ngIf="infoForm.get('branchInOtherCountry')['controls'].length < 10"
                            id="addBranch">
                            <a class="add" *ngIf="!disableBtn" (click)="addBranches()">Add More Branch</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accordion-item">
            <h2 class="accordion-header" id="headingSeven">
                <button class="accordion-button collapsed" id="bankInfoBtn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    Bank Information
                </button>
            </h2>

            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="row" formGroupName="bankDetails">

                        <div class="col-md-6 form-group">
                            <label for="bankName">Bank Name <span class="text-danger"
                                    *ngIf="this.vendorSetting?.bankDetails.bankName">*</span></label>
                            <input type="text" id="bankName" class="form-control" formControlName="bankName"
                                (keydown)="validators.space($event)">
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="accountNumber">Account Number <span class="text-danger"
                                    *ngIf="this.vendorSetting?.bankDetails.accountNumber">*</span></label>
                            <input type="text" (keypress)="numberOnly($event)" id="accountNumber" class="form-control"
                                formControlName="accountNumber" (keydown)="validators.space($event)">
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="beneficiaryName">Beneficiary Name <span class="text-danger"
                                    *ngIf="this.vendorSetting?.bankDetails.beneficiaryName">*</span></label>
                            <input type="text" class="form-control" id="beneficiaryName"
                                formControlName="beneficiaryName" (keydown)="validators.space($event)">
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="iban">IBAN <span class="text-danger"
                                    *ngIf="this.vendorSetting?.bankDetails.iban">*</span></label>
                            <input type="text" class="form-control" id="iban" formControlName="iban"
                                (keydown)="validators.space($event)">
                        </div>

                        <div class="col-md-6 form-group">
                            <label for="swiftCode">Swift Code <span class="text-danger"
                                    *ngIf="this.vendorSetting?.bankDetails.swiftCode">*</span></label>
                            <input type="text" class="form-control" id="swiftCode" formControlName="swiftCode"
                                (keydown)="validators.space($event)">
                        </div>

                        <div class="form-group col-md-12">
                            <input class="form-check-input mr-2" id="consent" type="checkbox" formControlName="consent"
                                id="consent" (keydown)="validators.space($event)">
                            <label class="form-check-label ml-2 mt-1" for="consent">
                                I Agree to verify my bank account details
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="buttons mb-4">
        <ng-container *ngIf="companyInfo?.status != 'Submitted'">
            <ng-container *ngIf="!companyInfo">
                <button class="btn cancel" type="button" id="saveBtn" (click)="save()" [disabled]="infoForm.pristine" >SAVE</button>
                <button class="btn next" type="button" id="saveAndNextBtn" (click)="updateCompanyInfo('next')"  [disabled]="infoForm.invalid">SAVE &
                    NEXT</button>
            </ng-container>

            <ng-container *ngIf="companyInfo">
                <button class="btn next" type="button" id="saveAndNextCompanyBtn"
                    (click)="updateCompanyInfo('next')">SAVE & NEXT</button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="companyInfo?.status == 'Submitted'">
            <button class="btn save" type="button" id="nextBtn" (click)="goNext()">Next</button>
        </ng-container>
    </div>
</form>
<!-- 
<input type="file" accept="image/png, image/jpeg,image/jpg" style="visibility: hidden; height: 0px;width: 0px;" max="1"
    (change)="uploadFile()" #file> -->
    <input type="file" id="fileUpload" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*,image/png, image/jpeg,image/jpg" style="visibility: hidden; height: 0px;width: 0px;" max="1" (change)="uploadFile()" #file />