<div class="dashboards mt-4" *ngIf="isCompanyInfo">
    <h2 class="fw-bold" *ngIf="companyName"><span>Hello {{companyName}}</span></h2>
    <div class="vendor-profile">
        <div class="vendor-top">
            <h3 id="profileStatus"><span id="textId">Profile Status:</span> Incomplete</h3>
            <div class="progressContainer">
                <div class="br first borders">
                    <span class="img first">
                        <div class="image">
                            <img src="../../../assets/images/ticmark.png" alt="">
                        </div>
                    </span>
                </div>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" [style]="style" aria-valuenow="this.progress"
                        aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="br last bars" [ngClass]="message=='COMPLETED'?'completed':''">
                    <span class="img last" [ngClass]="message=='COMPLETED'?'completed':''">
                        <div class="image">
                            <img src="../../../assets/images/star.png" alt="">
                        </div>
                    </span>
                </div>
            </div>
            <p class='step' id="messageVal">{{message}}</p>
        </div>
    </div>
    <div class="container btnContainer">
        <button class='btn dataBtn mr-2' id="onboardingStatus" (click)="goTo()">COMPLETE YOUR ONBOARDING JOURNEY</button>
        <button class='btn dataBtn ml-2 bg-success' id="home" *ngIf="message=='COMPLETED'" (click)="goHome()">Home</button>

    </div>
</div>