<div class="vendor-questionnaire mt-4">
    <div class="heading">
        <h1 id="questionnaire">Questionnaire</h1>
    </div>
    <div class="task" *ngFor="let question of companyInfo?.answers ; let i=index">
        <div class="questions ">
            <h2 id="{{'questions' + i}}"> <span class="mr-1">Q{{i+1}}.</span>{{question?.question}} </h2>
            <p class="ans" id="{{'answers' + i}}">{{question?.answer}}</p>
        </div>
        
    </div>
</div>