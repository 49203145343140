import { Component, OnInit, Input } from '@angular/core';
import {  Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PATH } from 'src/app/app.constant';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';
import { ConfirmationService,LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  page = 1;
  checked: boolean=true;
  headers: Array<any>;
  status: any;
  users: any = [];
  user:any;
  sortBy='lastModifiedDate';
  sortOrder='desc';
  entityId:any;
  size: number = 10;
  pager: any = {};
  activePage:any;
  constructor(
    private router: Router,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private appCookieService: AppCookieService,
    private spinnerService: NgxSpinnerService,
    private confirmationService:ConfirmationService,
    ) {  
  }     

  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.entityId =this.user.entityId
    this.prepareHeader();
  }

  prepareHeader(){
    this.headers = [
      { name: 'createdDate', header: 'Created Date',width:'135px',sort: true,isAsc:false},
      { name: 'firstName', header: 'Name',width:'200px',sort: true,isAsc:false},
      { name: 'email', header: 'Email ID',width:'70px',sort: true,isAsc:false},
      { name: 'mobileNumber', header: 'Phone Number',width:'170px', sort: true,isAsc:false},
      { name: 'eroleSet', header: 'Role',width:'80px', sort: false,isAsc:false},
      { name: 'locations', header: 'Location',width:'300px', sort: false,isAsc:false},
      { name: 'action', header: 'Actions', width:'40px',sort: false,isAsc:false},
      { name: 'enable', header: 'Active / Inactive',width:'150px', sort: false,isAsc:false}
    ]; 
  }


  delete(item){
    let name= item.username;
    let id=item.id;
    this.confirmationService.confirm({
      message:"Are you sure that you want to delete?",
      accept:()=>{
        this.spinnerService.show();
        this.httpService.deleteData(PATH.USER_MANAGEMENT+'/' + name+'?organizationId='+ this.entityId)
          .subscribe((res) => {
              this.spinnerService.hide();
              this.toastrService.success('Deleted Successfully!')
              this.getUser();
            },
            (error) => {
              this.spinnerService.hide();
              this.toastrService.error(error.message?.error);
            }
          );
      }
    })
  }
  

 getUser(){
  this.spinnerService.show();
  let subscription = this.httpService
    .getData(`${PATH.USER_MANAGEMENT}/all-users/${this.user.entityId}?page=${this.page}&size=${this.size}&sort=${this.sortBy},${this.sortOrder}`)
    .subscribe((res:any) => {
      this.users = res['content'];
      this.pager.totalPages = res['totalPages'];
      if(res){
        this.users.forEach(elm => {
          elm.name = elm.firstName+' '+elm.lastName
        });
      }
      this.spinnerService.hide();
    },
    (err) => {
        this.spinnerService.hide();
    })
 }
  
 changeStatus(users){   
  this.confirmationService.confirm({
    message:"Are you sure that you want to change status?",
    accept:()=>{
    this.status = users.active;
    let url =  PATH.USER_MANAGEMENT+'/enable-disable-user/'+users.username+'?isActive='+this.status+"&organizationId="+this.entityId;
    this.spinnerService.show();
    this.httpService.statusData(url).subscribe((res) => {
      this.spinnerService.hide();
      this.toastrService.success('Status Changed Successfully!');
      this.getUser();
    }, (err) => {
      this.spinnerService.hide();
      this.toastrService.error('Status Changed Failed');
      this.getUser();
    })
    },reject:()=>{      
    this.getUser();
  }
  })
}

  refreshPage(event:LazyLoadEvent){
    if(event.sortField){
      this.sortBy=event.sortField;
    }
    this.sortOrder=event.sortOrder==-1?'asc':'desc';
    this.getUser();
  }

  setPage(pager) {
    this.page = pager.page;
    this.size = pager.size;
    this.activePage = pager.page;
    this.getUser();
  }

  editUser(user){
    this.router.navigate(['/vendor/user','edit',user.id]);
  }

}
