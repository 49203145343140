import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PATH } from 'src/app/app.constant';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';
import { DialogService } from 'primeng/dynamicdialog';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { Store } from '@ngrx/store';
import { Selector } from 'src/app/redux/selector';
import { invokeUpdateBookAPI } from 'src/app/redux/action';

@Component({
  selector: 'app-regulations',
  templateUrl: './company-info-regulations.component.html',
})
export class CompanyInfoRegulationsComponent implements OnInit {
  @Output('getCompanyInfo') callParent: EventEmitter<any> = new EventEmitter();

  checkboxes: Array<any>;
  user: any;
  companyInfo: any = {};
  regulations: Array<any> = [];
  disableBtn: boolean = false;
  isDisabled:boolean=false;
  constructor(
    private httpService: HttpService,
    private router: Router,
    private appCookieService: AppCookieService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    public dialogService: DialogService,
    public store: Store
  ) { }

  ngOnInit(): void {
    this.checkboxes = [
      { title: 'terms', label: 'Terms & Conditions', value: false },
      { title: 'privacy', label: 'Privacy Policy', value: false },
    ];
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.getCompanyInfo();
  }

  getCompanyInfo() {
    this.spinnerService.show();
    this.httpService.getData(PATH.GET_REGULATIONS).subscribe(
      (res: any) => {
        let resData = res;
        this.checkboxes.forEach((elm) => {
          if (elm.title == 'terms') {
            elm.fileName = resData.termsAndConditions
          }
          if (elm.title == 'privacy') {
            elm.fileName = resData.privacyPolicy
          }
        })
        this.getCompanyInfo1()
      },
      (err) => {
        this.spinnerService.hide();
      }
    );
  }

  getCompanyInfo1() {
    this.spinnerService.show();
    // this.httpService.getData(PATH.COMPANY_INFORMATION).subscribe((res: any) => {
    this.store.select(Selector).subscribe((res: any) => {
      this.spinnerService.hide();
      if(res?.companyInfo?.id || res?.companyInfo?.length > 0){
        let resData:any;
        if(res?.companyInfo?.id){
          resData = JSON.parse(JSON.stringify(res.companyInfo));
        }
        if(res?.companyInfo?.length > 0){
          resData = JSON.parse(JSON.stringify(res.companyInfo[0]));
        }
        
        if (!resData.checkboxes) {
          resData.checkboxes = {};
          this.checkboxes.map((o) => {
            resData.checkboxes[o] = false;
          });
        } else {
          this.checkboxes.map((o) => {
            o.value = resData.checkboxes[o.title];
          });
        }
        this.spinnerService.hide();
        this.companyInfo = resData;
        if (this.companyInfo.status == 'Submitted') {
          this.disableBtn = true;
        }
      }
    },
      (error) => {
        this.spinnerService.hide();
      }
    );
  }

  onSubmit(form: UntypedFormGroup) {
    let formVal = form.value;
    if (!formVal.terms) {
      this.toastrService.error("Please accept Terms & Conditions");
      return
    }
    if (!formVal.privacy) {
      this.toastrService.error("Please accept Privacy Policy");
      return
    }
    let checkboxData = {};
    this.checkboxes.forEach((q) => {
      checkboxData[q.title] = q.value;
    });
    form.markAllAsTouched();
    this.companyInfo['checkboxes'] = checkboxData;
    this.updateCompanyInfo();
  }

  updateCompanyInfo() {
    this.spinnerService.show();
    let branches = [];
    this.companyInfo.status = 'Submitted';
    this.companyInfo.step = 6;
    this.httpService.updateData(PATH.COMPANY_INFORMATION, this.companyInfo).subscribe((res) => {
      this.store.dispatch(invokeUpdateBookAPI({ payload: { ...res }, user:this.user.entityId }));
      this.extractOcr();
      this.spinnerService.hide();
      this.router.navigate(['/vendor/completed']);
    }, (error) => {
      this.spinnerService.hide();
      this.toastrService.error(error.message.message);
    }
    );
  }

  back() {
    this.isDisabled=false;
    this.router.navigate(['/vendor/vendor-info/4']);
    this.callParent.emit({
      step: 4,
      url: '/vendor/vendor-info/4',
      progressStep: this.companyInfo.step
    });
  }

  getLoggedInVendor() {

  }

  extractOcr() {
    this.httpService.postData(PATH.OCR_EXTRACT, {}).subscribe((res) => {

    }, (error) => {

    })
  }

  checkDocumentType(filename) {
    let fileType = filename.split('.').pop();
    if (fileType == 'jpeg') {
      return 'image/jpeg';
    }
    if (fileType == 'pdf') {
      return 'application/pdf';
    }
    if (fileType == 'png') {
      return 'image/png';
    }
    if (fileType == 'gif') {
      return 'image/gif';
    }
  }

  openFile(filename) {
    this.isDisabled=true;
    filename.status = this.companyInfo.status
    const ref = this.dialogService.open(TermsAndConditionsComponent, {
      header: filename.label,
      width: '50%',
      data: filename
    });
    ref.onClose.subscribe((res) => {
      if (res) {

      }
    });
  }

}
