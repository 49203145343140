import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationComponent } from './pages';
import { LoginComponent } from './pages/login/login.component';
import { IndexComponent } from './pages/index/index.component';
import { CanDeactivateTeam } from './services/auth.gaurd';
import { InviteEmailComponent } from './pages/invite-email/invite-email.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { CreateNewpasswordComponent } from './pages/create-newpassword/create-newpassword.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'register',
    component: RegistrationComponent,
  },
  {
    path: 'invite-email/:type',
    component: InviteEmailComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password-email/:type',
    component: CreateNewpasswordComponent,
  },
  {
    path: '',
    canDeactivate: [CanDeactivateTeam],
    component: IndexComponent,
    children: [
      {
        path: 'vendor',

        loadChildren: () =>
          import('./vendor-module/vendor.module').then((m) => m.VendorModule),
        canDeactivate: [CanDeactivateTeam],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin-module/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'owner',
        loadChildren: () =>
          import('./owner-module/owner.module').then((m) => m.CustomerModule),
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
