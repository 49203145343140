
<div class="pageFooter">
    <nav>
        <ul class="pagination pagination-circle pg-blue d-flex flex-center" ng-reflect-klass="pagination pagination-circle p">

             <li (click)="setPage(1)" class="page-item" [ngClass]="{disabled:activePage === 1}">
                <a aria-label="Previous" class="page-link">
                    <span aria-hidden="true" class="icon-bacword"></span>
                </a>
            </li>

            <li class="page-item" (click)="setPage(activePage - 1)" [ngClass]="{disabled:activePage === 1}">
                <a aria-label="Previous" class="page-link">
                    <span aria-hidden="true" class="icon-previous"></span>
                </a>
            </li> 
             <ng-container *ngFor="let page of pages;let i=index;">
                <li class="page-item" *ngIf="i<activePage+2&&i>activePage-4">
                    <a class="page-link" [ngClass]="activePage==i+1?'active':''" (click)="setPage(i+1)">{{i+1}}</a>
                </li>
            </ng-container> 
            <li class="page-item" (click)="setPage(activePage + 1)" [ngClass]="{disabled:activePage === totalPages}">
                <a aria-label="Previous" class="page-link">
                    <span aria-hidden="true" class="icon-next"></span>
                </a>
            </li>


            <li class="page-item" (click)="setPage(totalPages)" [ngClass]="{disabled:activePage === totalPages}">
                <a aria-label="Next" class="page-link">
                    <span aria-hidden="true" class="icon-forward"></span>
                </a>
            </li>
        </ul>
    </nav>
    <div class="m-0 row pagesize">
        <label class="mt-2 p-0 col-form-label" for="select3" id="rowPerPage">Rows Per Page</label>
        <div class="col-md-6 p-0">
        <select [(ngModel)]="size"  class="form-select" id="select3" (change)="setPage(1)" [disabled]="totalPages<= 1 && size === 10">
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
      </select>
        </div>
    </div>
</div>




