import { Router } from '@angular/router';
import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';
import { PATH } from 'src/app/app.constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { Store } from '@ngrx/store';
import { Selector } from 'src/app/redux/selector';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html'
})
export class SidenavComponent implements OnInit {
  collapsed:boolean=true;
  isAdmin:boolean=false;
  isVendor:boolean=false;
  isOwnerAdmin:boolean=false;
  isOwnerNonAdmin:boolean=false;
  isVendorAdmin:boolean=false;
  isVendorNonAdmin:boolean=false;
  user:any;
  dashboardStep:any;
  isCompanyRes:boolean=false;
  count:number=0;
  selectedItem:any;
  constructor(private appCookieService:AppCookieService,
    private router: Router,
    private httpService: HttpService,
    private spinnerService: NgxSpinnerService,
    private sharedDataService:SharedDataService,
    public store: Store
    ) { }

  @Output() sendData: EventEmitter<any> = new EventEmitter();
 
  ngOnInit(): void {
   if(localStorage.getItem('dashboardStep')){
    this.dashboardStep = localStorage.getItem('dashboardStep');
   }
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    if(this.user?.eroles[0]=='ROLE_ADMIN'){
      this.isAdmin = true;
    }
    if(this.user?.eroles[0]=='ROLE_VENDOR_ADMIN'){
      this.isVendorAdmin = true;
    }
    if(this.user?.eroles[0]=='ROLE_VENDOR_NONADMIN'){
      this.isVendorNonAdmin = true;
    }
    if(this.user?.eroles[0]=='ROLE_OWNER_ADMIN'){
      this.isOwnerAdmin = true;
    }
    if(this.user?.eroles[0]=='ROLE_OWNER_NONADMIN'){
      this.isOwnerNonAdmin = true;
    }
    if(this.isVendorAdmin || this.isVendorNonAdmin){
      this.getCompanyInfo();
    }
    if(this.count<1){
      this.switchProfile();
    }
    
  }

  switchProfile(){
    this.sharedDataService.sidenav.subscribe((value) => {
        if(value=='switch-profile'){
          this.count=this.count+1;
          this.isAdmin=false;
          this.isVendor=false;
          this.isVendorAdmin=false;
          this.isVendorAdmin=false;
          this.isOwnerAdmin=false;
          this.isOwnerNonAdmin=false;
          this.ngOnInit();
        }  
    });
  }

  getCompanyInfo(){    
    this.store.select(Selector).subscribe((res: any) => {
      if(res.length){
        this.isCompanyRes=true;
        let companyInfo = JSON.parse(JSON.stringify(res.companyInfo[0]));
        localStorage.setItem('dashboardStep', companyInfo.step);
      }else{
        this.isCompanyRes=true;
        localStorage.setItem('dashboardStep', '0');
      }
    },(err)=>{
      this.isCompanyRes=true;
      localStorage.setItem('dashboardStep', '0');
      this.spinnerService.hide();
    })
  }

  toggleSidebar() {
    this.collapsed = !this.collapsed;
    if(this.collapsed){
      this.sendData.emit('collapsed');
    }
    else
    {
      this.sendData.emit('Not collapsed');
    } 
  }


  logout() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  goToAdmin(){
    this.sharedDataService.setOwnerStatus('ALL');
  }

  listClick(itm){
  this.selectedItem=itm;  
  }

}
