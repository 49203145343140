<div class="container upload-container">
    <div class="row">
        <div class="col-md-12">
            <h3 id="uploadDocument">Upload Documents</h3>
            <p id="maxSize">Maximum upload file size 10MB. Only PDF File format accepted.</p>
            <div class="row">
                <div class="col-md-6" *ngFor="let document of documents; let i = index">
                    <div class="uploadContainer space-between" [ngClass]="{  'disable-div': companyInfo?.status == 'Submitted'}"  [id]="document.name">
                        <p class="title" id="{{ 'documentTitle' + i }}"> {{document.title}}</p>

                        <span class="inline-icon">
                            <em class="icon-view icon" *ngIf="companyInfo[document.name]" pTooltip="View Document" tooltipPosition="bottom" (click)="download(companyInfo[document.name])"> </em>
                            <em *ngIf="!disableBtn" class="icon-upload icon" pTooltip="Upload Document" tooltipPosition="bottom" (click)="upload('doc',document.name,null)" ></em>
                        </span>
                    </div>
                    <p *ngIf="document.title=='Incorporation Documents'" id="licenses" class="subtitle">(Licenses / Certificates / Gazettes / Notices / etc)</p>
                </div>
            </div>



            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOneFileUpload">
                        <button class="accordion-button" id="documentShareHolderBtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneFileUpload" aria-expanded="true" aria-controls="collapseOneFileUpload">
                            Documents for Shareholders
                        </button>
                    </h2>
                    <div id="collapseOneFileUpload" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <ng-container *ngFor="let shareHolder of companyInfo.shareHolders;let i = index">
                                <h6 id="companyNameDocument">Upload Documents For <span class="text-primary">{{shareHolder.companyName}}</span></h6>
                                <div class="row">
                                    <ng-container *ngFor="let holder of shareHolder.type=='company'?companyShareholders:shareholders;let j = index" >
                                        <div class="col-md-6" >
                                            <div class="uploadContainer space-between" [ngClass]="{  'disable-div': companyInfo?.status == 'Submitted'}"  [id]="holder.name+i">
                                                <p class="title" id="{{ 'holderTitle' + i + j }}"> {{holder.title}}</p>
                                                <span class="inline-icon">
                                                    <em class="icon-view icon" *ngIf="shareHolder[holder.name]" pTooltip="View Document" tooltipPosition="bottom" (click)="download(shareHolder[holder.name])"> </em>
                                                    <em class="icon-upload icon"  *ngIf="!disableBtn" pTooltip="Upload Document" tooltipPosition="bottom" (click)="upload('shareholder',holder.name,i)" ></em>
                                                </span>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwoFileUpload">
                        <button class="accordion-button" id="documentBtnDirector" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoFileUpload" aria-expanded="true" aria-controls="collapseTwoFileUpload">
                            Documents for Directors / Partners / Owners
                        </button>
                    </h2>
                    <div id="collapseTwoFileUpload" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <ng-container *ngFor="let boardOfDirector of companyInfo.boardOfDirectors; let i = index">
                                <h6 id="{{ 'headingboardOfDirector' + i }}">Upload Documents For <span class="text-primary"> {{boardOfDirector.fullName}} </span></h6>
                                <div class="row">
                                    <div class="col-md-6" *ngFor="let director of directors;let j = index">
                                        <div class="uploadContainer space-between" [ngClass]="{  'disable-div': companyInfo?.status == 'Submitted'}"  [id]="'bod'+director.name+i">
                                            <p class="title" id="{{ 'directorTitle' + i + j }}"> {{director.title}}</p>
                                            <span class="inline-icon">
                                                <em class="icon-view icon"  pTooltip="View Document" tooltipPosition="bottom" *ngIf="boardOfDirector[director.name]" (click)="download(boardOfDirector[director.name])"> </em>
                                                <em *ngIf="!disableBtn" class="icon-upload icon"  pTooltip="Upload Document" tooltipPosition="bottom" (click)="upload('director',director.name,i)" ></em>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThreeFileUpload">
                        <button class="accordion-button" type="button" id="contractInfo" data-bs-toggle="collapse" data-bs-target="#collapseThreeFileUpload" aria-expanded="true" aria-controls="collapseThreeFileUpload">
                            Key Contacts Information
                        </button>
                    </h2>
                    <div id="collapseThreeFileUpload" class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <form [formGroup]="infoForm">
                                <div class="row" formArrayName="keyContacts" *ngFor="let contract of contracts; let j = index">
                                    <ng-container *ngFor="let contractDoc of infoForm.get('keyContacts')['controls'] ;let i = index;let isLast=last">
                                        <div [formGroupName]="i" class="row mt-3">
                                            <div class="key-contract-heading" >
                                                <h1 class="key-heading" [id]="i+'key'">Key Contact {{i+1}}</h1>
                                            </div>
                                        <div class="col-md-6 form-group">
                                            <label for="{{'contractName' + j + i}}">Name</label>
                                            <input (keypress)="keypress($event)" type="text" for="{{'contractName' + j + i}}" formControlName="name" [readonly]="disableBtn"  style="height: 50px;" class="form-control" name="name" (keydown)="validators.space($event)">
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="{{'phoneNumber' + j + i}}">Phone</label>
                                            <!-- <input  type="text" for="{{'phoneNumber' + j + i}}" formControlName="phoneNumber" [readonly]="disableBtn"  style="height: 50px;" class="form-control" name="name" (keydown)="validators.space($event)"> -->
                                            <div class="input-group">
                                                <input #phone (keypress)="keypress($event)" type="text" id="phoneNumber"  for="{{'phoneNumber' + j + i}}" (keypress)="numberOnly($event)"
                                                    class="form-control w-100" ng2TelInput placeholder="   " class="form-control" style="height: 50px;"
                                                    formControlName="phoneNumber"
                                                    [ng2TelInputOptions]="{initialCountry:countryCode,separateDialCode:true}"
                                                    (hasError)="onError($event,contractDoc)" (countryChange)="onCountryChange($event)" />
                                            </div>
                                            <div app-errors class="error-msg" [control]="contractDoc.get('phoneNumber')" [submit]="formSubmitAttempt"
                                                label="Phone Number"></div>
                                            <div class="error-msg"
                                                *ngIf="!contractDoc.get('phoneNumber').errors && !contractDoc.hasError">
                                                Invalid Phone Number
                                            </div>
                                            <div class="text-right delete-container" >
                                                <em  class="icon-delete icon positions"  (click)="deleteContract(i)" *ngIf="infoForm.get('keyContacts').controls.length>1" aria-hidden="true" pTooltip="Delete" tooltipPosition="bottom"  id="deleteContractBtn" ></em>  
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="{{'email' + j + i}}">Email ID</label>
                                            <input  (keypress)="keypress($event)" type="text"  style="height: 50px;" id="{{'email' + j + i}}" class="form-control" [readonly]="disableBtn" formControlName="emailId" name="emailId" (keyup)="emailChange()" (keydown)="validators.space($event)">
                                            <div app-errors class="error-msg" [control]="contractDoc.get('emailId')"
                                            label="Email"></div>
                                        </div>
                                        <div class="text-left add-button buttons mb-3 mt-3" *ngIf="isLast">
                                            <button class="btn cancel qns-btn"  *ngIf="!disableBtn" (click)="addContract()" [disabled]="contractDoc.get('name').value && contractDoc.get('emailId').value && contractDoc.get('phoneNumber').value?false:true"  id="add-question" ><em  class="icon-add icons-position" aria-hidden="true"></em>Add Contact</button>
                                        </div>
                                        <!-- <div class="buttons mb-2 delete-container" *ngIf="!disableBtn">
                                            <button class="btn btn-primary" *ngIf="infoForm.get('keyContacts')['controls']?.length>1" (click)="deleteContract(i)">Delete</button>
                                        </div> -->
                                     </div>   
                                    </ng-container>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="buttons">
                <ng-container *ngIf="companyInfo?.status != 'Submitted'">
                    <button class="btn cancel" id="backBtn" (click)="back()" >BACK</button>
                    <button class="btn next" id="saveNextBtn" (click)="next('save')" [disabled]="!isDocumentUploaded">SAVE & NEXT</button>
                </ng-container>

                <ng-container *ngIf="companyInfo?.status == 'Submitted'">
                    <button class="mr-2 btn cancel" id="backBtnSubmitted" (click)="back()" >BACK</button>
                    <button class="btn next" id="nextBtn" type="button" (click)="goNext()" >Next</button>
                </ng-container>
        </div>
    
        </div>
    </div>
</div>
<input type="file"  accept="application/pdf" style="visibility: hidden; height: 0px;width: 0px;" max="1" (change)="uploadFile()" #file>
