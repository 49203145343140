import { Component, Input } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'view-survey-dialog',
  templateUrl: './view-survey-dialog.component.html',
})
export class OwnerSurveyViewpageDialogComponent{
  @Input() surveyData:any;
  
  
  constructor(private dyanmicDialog: DynamicDialogConfig,) { }
}
