<div class="financial mt-4" *ngIf="companyInfo.length>0">
    <div class="heading">
        <h2 id="financialHeading">Financial</h2>
    </div>

    <div class="banner mt-3">
        <div class="grid flex-box">
            <div class=" col-4 col-width">
                <div class="field">
                    <label for="nameField" id="nameFieldLable">Name</label>
                    <p id="nameField">{{companyInfo[0].cisFinancialSearchData.companyName}}</p>
                </div>
            </div>
            <div class=" col-4 col-width">
                <div class="field">
                    <label for="currencyField" id="currencyField">Currency</label>
                    <p id="currencyField">{{companyInfo[0].cisFinancialSearchData.currency}}</p>
                </div>
            </div>
            <div class=" col-4 col-width">
                <div class="field">
                    <label for="countryField" id="countryFieldLabel">Country</label>
                    <p id="countryField">{{companyInfo[0].cisFinancialSearchData.countries[0]}}</p>
                </div>
            </div>
            <div class=" col-4 col-width">
                <div class="field">
                    <label for="startDate" id="startDateLabel">Start Date</label>
                    <p id="startDate"></p>
                </div>
            </div>
            <div class=" col-4 col-width">
                <div class="field">
                    <label for="capitalField" id="capitalFieldLabel">Capital</label>
                    <p id="capitalField">{{companyInfo[0].cisFinancialSearchData.capital.capitalIssued |currency:'':''}}</p>
                </div>
            </div>
            <div class=" col-4 col-width">
                <div class="field">
                    <label for="activityField" id="activityFieldLabel">Activity</label>
                    <p id="activityField"></p>
                </div>
            </div>
        </div>

    </div>
    <div class="balancesheet mt-4">
        <div class="heading">
            <h1 id="balancesheet">Balance Sheet</h1>
        </div>

    <div class="row">
        <div class="page-content mt-2 mb-3">
            <div class="showList mt-2" *ngIf="locationData && balanceSheetYears">
                <p-table  [columns]="locationheaders" [value]="locationData"
                    class="p-table mt-5 bank-financial-table" responsiveLayout="scroll" [resizableColumns]="true" >
                    <ng-template pTemplate="header" let-columns>
                        <tr class="head">
                            <ng-container *ngFor="let col of columns; let i = index">
                                <th class="cursor-pointer center " id="{{'header' + i}}" *ngIf="col.name=='financial'" [colSpan]="balanceSheetYears?.length+1">{{col.header}}<img
                                    *ngIf="col.sort" class="topbottom"
                                    src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom"  alt="Top-Bottom">
                            </th>
                            </ng-container>
                        </tr>
                        <tr class="particulartr">
                            <th id="particularHeading">
                                <div class="td">Particular
                                </div>
                            </th>
                        <ng-container *ngFor="let col of this.balanceSheetYears;let i = index">
                                <th id="{{'balanceSheetYearsHeading' + i}}">
                                    <div class="td">
                                        <span>{{col.year | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </th>     
                        </ng-container>   
                        </tr>  
                    </ng-template>
                    <ng-template pTemplate="body" let-user>
                        <tr class="tr">
                            <td >
                                <div class="td">{{user.particular}}
                                    <span>{{user.span}}</span>
                                </div>
                            </td>
                            <ng-container *ngFor="let col of user.financialValues">
                                    <td>
                                        <div class="td">
                                            <span>{{col.value?(col.value | currency):'-'}}</span>
                                        </div>
                                    </td>     
                            </ng-container>   
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    </div>

    <div class="balancesheet">
        <div class="heading mt-3">
            <h1 id="incomeStatement">Income Statement</h1>
        </div>

    <div class="row">
        <div class="page-content  bank-financial-medium-screen mt-2 mb-3">
            <div class="showList mt-2">
                <p-table [columns]="locationheaders" [value]="incomeStatement"
                    class="p-table mt-2 table catalogue-table income-statement" responsiveLayout="scroll" [resizableColumns]="true"  >
                    <ng-template pTemplate="header" let-columns>
                        <tr class="head">
                            <ng-container *ngFor="let col of columns;let i = index">
                                <th class="cursor-pointer center" id="{{'columnHeading' + i}}" *ngIf="col.name=='financial'" [colSpan]="incomeStatementYears?.length+1">{{col.header}}<img
                                    *ngIf="col.sort" class="topbottom"
                                    src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" alt="top-bottom" >
                            </th>
                            </ng-container>
                        </tr>
                        <tr class="particulartr">
                            <th id="particulartrHeading">
                                <div class="td">Particular
                                </div>
                            </th>
                        <ng-container *ngFor="let col of this.incomeStatementYears">
                                <th>
                                    <div class="td">
                                        <span>{{col.year | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </th>     
                        </ng-container>   
                        </tr>  
                    </ng-template>
                    <ng-template pTemplate="body" let-user>
                        <tr class="tr">
                            <td >
                                <div class="td">{{user.particular}}
                                    <span>{{user.span}}</span>
                                </div>
                            </td>
                            <ng-container *ngFor="let col of user.financialValues">
                                    <td>
                                        <div class="td">
                                            <span>{{col.value?(col.value | currency):'-'}}</span>
                                        </div>
                                    </td>     
                            </ng-container>   
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    </div>

    <div class="balancesheet">
        <div class="heading">
            <h1 id="cashFlow">Cash Flow</h1>
        </div>

    <div class="row">
        <div class="page-content mt-2 mb-3">
            <div class="showList mt-2">
                <p-table [columns]="locationheaders" [value]="cashFlow"
                    class="p-table mt-5 cash-flow " responsiveLayout="scroll" [resizableColumns]="true" >
                    <ng-template pTemplate="header" let-columns>
                        <tr class="head">
                            <ng-container *ngFor="let col of columns;let i = index">
                                <th class="cursor-pointer center " id="{{'cashFlowHeading' + i}}" *ngIf="col.name=='financial'" [colSpan]="cashFlowYears?.length+1">{{col.header}}<img
                                    *ngIf="col.sort" class="topbottom"
                                    src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom"  alt="top-bottom">
                            </th>
                            </ng-container>
                        </tr>
                        <tr class="particulartr">
                            <th id="cashFlowParticularHeading">
                                <div class="td">Particular
                                </div>
                            </th>
                        <ng-container *ngFor="let col of this.cashFlowYears; let i = index">
                                <th id="{{'cashFlowYearsHeading' + i}}">
                                    <div class="td">
                                        <span>{{col.year | date: 'dd-MM-yyyy'}}</span>
                                    </div>
                                </th>     
                        </ng-container>   
                        </tr>  
                    </ng-template>
                    <ng-template pTemplate="body" let-user>
                        <tr class="tr">
                            <td >
                                <div class="td">{{user.particular}}
                                    <span>{{user.span}}</span>
                                </div>
                            </td>
                            <ng-container *ngFor="let col of user.financialValues">
                                    <td>
                                        <div class="td">
                                            <span>{{col.value?(col.value |currency):'-'}}</span>
                                        </div>
                                    </td>     
                            </ng-container>   
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    </div>
</div>
<div class="empty-msg"*ngIf="companyInfo.length==0">
    <p>Data Not Available </p>
</div>