<div class="container upload-container">
    <div class="row">
        <div class="col-md-12">
            <h3 id="esgHeading">ESG</h3>
            <div class="row">
                <form name="form" (ngSubmit)=" onSubmit(f.form)" #f="ngForm" novalidate>
                    <div class="col-md-12 mb-4" *ngFor="let question of esg; let i=index">
                        <h6 id="questionHeading">
                            <b>Q{{i+1}}. </b> {{question.question}}<em class="red-star">*</em>
                        </h6>
                        <div class="field-radiobutton">
                            <p-radioButton [disabled]="disableBtn" (click)="keypress(question.answer)" name="esg{{i}}"  inputId="yes{{i}}" class="yes-radio-field mr-2" value="yes" required [(ngModel)]="question.answer"></p-radioButton>
                            <label  for="yes{{i}}">Yes</label>
                            <p-radioButton [disabled]="disableBtn" (click)="keypress(question.answer)" name="esg{{i}}" inputId="no{{i}}" class="no-radio-field mr-2" value="no"  required [(ngModel)]="question.answer"></p-radioButton>
                            <label  for="no{{i}}">No</label>
                        </div>
                        <div *ngIf="(f.submitted || ('esg'+i).touched) && ('esg'+i).errors?.required" class="text-danger">
                            Answer can not be empty
                        </div>
                    </div>
                    <div class="buttons">
                        <ng-container *ngIf="companyInfo?.status != 'Submitted'">
                        <button class="btn cancel" type="button" (click)="back()" id="backBtn">BACK</button>
                        <button class="btn next" type="submit" (click)="f.form.markAllAsTouched()" id="saveAndExitBtn">SAVE & NEXT</button>
                        </ng-container>
                        <ng-container *ngIf="companyInfo?.status == 'Submitted'">
                        <button class="mr-2 btn cancel" type="button" (click)="back()" [disabled]="!isDisabled" id="backBtnSubmitted">BACK</button>
                        <button class="btn next" type="button" (click)="goNext()" [disabled]="!isDisabled" id="nextBtn">Next</button>
                        </ng-container>
                
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>