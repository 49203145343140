<div class="container upload-container"  >
    <div class="row">
        <div class="col-md-12">
            <h3 id="uploadDocumentHeading">Upload Documents</h3>
            <p id="sizeText">Maximum upload file size 10MB. Only PDF File format accepted.</p>
            <div class="row">
                <div class="col-md-6" *ngFor="let document of documents">
                    <div class="uploadContainer space-between" [ngClass]="{  'disable-div': companyInfo?.status == 'Submitted'}"  [id]="document.name">
                        <p class="title" id="documentTitle"> {{document.title}}</p>

                        <span class="inline-icon">
                            <em class="icon-view icon" *ngIf="companyInfo[document.name]" pTooltip="View Document" tooltipPosition="bottom" (click)="download(companyInfo[document.name])"> </em>
                            <em *ngIf="!disableBtn" class="icon-upload icon" pTooltip="Upload Document" tooltipPosition="bottom" (click)="upload('doc',document.name,null)" ></em>
                        </span>
                    </div>
                    <p *ngIf="document.title=='Incorporation Documents'" id="subtitle" class="subtitle">(Licenses / Certificates / Gazettes / Notices / etc)</p>
                </div>
            </div>



            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOneFileUpload">
                        <button class="accordion-button" id="documents" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneFileUpload" aria-expanded="false" aria-controls="collapseOneFileUpload">
                            Documents for Shareholders
                        </button>
                    </h2>
                    <div id="collapseOneFileUpload" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <ng-container *ngFor="let shareHolder of companyInfo.shareHolders;let i = index">
                                <h6 id="documentHeading">Upload Documents For <span class="text-primary">{{shareHolder.companyName}}</span></h6>
                                <div class="row">
                                    <ng-container *ngFor="let holder of shareHolder.type=='company'?companyShareholders:shareholders" >
                                        <div class="col-md-6" >
                                            <div class="uploadContainer space-between" [ngClass]="{  'disable-div': companyInfo?.status == 'Submitted'}"  [id]="holder.name+i">
                                                <p class="title" id="holderTitle"> {{holder.title}}</p>
                                                <span class="inline-icon">
                                                    <em class="icon-view icon" *ngIf="shareHolder[holder.name]" pTooltip="View Document" tooltipPosition="bottom" (click)="download(shareHolder[holder.name])"> </em>
                                                    <em class="icon-upload icon"  *ngIf="!disableBtn" pTooltip="Upload Document" tooltipPosition="bottom" (click)="upload('shareholder',holder.name,i)" ></em>
                                                </span>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwoFileUpload">
                        <button class="accordion-button" id="documentsBtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoFileUpload" aria-expanded="false" aria-controls="collapseTwoFileUpload">
                            Documents for Directors / Partners / Owners
                        </button>
                    </h2>
                    <div id="collapseTwoFileUpload" class="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <ng-container *ngFor="let boardOfDirector of companyInfo.boardOfDirectors; let i = index">
                                <h6 id="bodDocument">Upload Documents For <span class="text-primary"> {{boardOfDirector.fullName}} </span></h6>
                                <div class="row">
                                    <div class="col-md-6" *ngFor="let director of directors">
                                        <div class="uploadContainer space-between" [ngClass]="{  'disable-div': companyInfo?.status == 'Submitted'}"  [id]="'bod'+director.name+i">
                                            <p class="title" id="directorTitle"> {{director.title}}</p>
                                            <span class="inline-icon">
                                                <em class="icon-view icon"  pTooltip="View Document" tooltipPosition="bottom" *ngIf="boardOfDirector[director.name]" (click)="download(boardOfDirector[director.name])"> </em>
                                                <em *ngIf="!disableBtn" class="icon-upload icon"  pTooltip="Upload Document" tooltipPosition="bottom" (click)="upload('director',director.name,i)" ></em>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThreeFileUpload">
                        <button class="accordion-button" id="keyContactsBtn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreeFileUpload" aria-expanded="false" aria-controls="collapseThreeFileUpload">
                            Key Contacts Information
                        </button>
                    </h2>
                    <div id="collapseThreeFileUpload" class="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="text-right">
                                <button class="btn btn-primary" id="addContractBtn" *ngIf="!disableBtn" (click)="addContract()">Add Contract</button>
                            </div>
                            <form [formGroup]="infoForm">
                                <div class="row" formArrayName="contractInformation" *ngFor="let contract of contracts">
                                    <ng-container *ngFor="let contractDoc of infoForm.get('contractInformation')['controls'] ;let i = index">
                                        <div [formGroupName]="i" class="row mt-3">
                                        <div class="col-md-6 form-group">
                                            <label for="contractName" id="contractNameLabel">Contract Name</label>
                                            <input  type="text" id="contractName" formControlName="contractName" [readonly]="disableBtn"  style="height: 50px;" class="form-control"   name="contractName" (keydown)="validators.space($event)">
                                        </div>
                                        <div class="col-md-6 form-group">

                                            <label for="startDate">Start Date 
                                            </label>
                                            <div class="input-group">
                                                <p-calendar class="w-100" id="startDate" [disabled]="disableBtn" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" name="startDate" formControlName="startDate" [readonlyInput]="true"></p-calendar>
                                            </div>

                                        </div>
                                        <div class="col-md-6 form-group">

                                            <label for="endDate">End Date 
                                            </label>
                                            <div class="input-group">
                                                <p-calendar class="w-100" id="endDate" [disabled]="disableBtn" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" [minDate]="infoForm.get('contractInformation')['controls'][i].value['startDate']" name="endDate" formControlName="endDate" [readonlyInput]="true"></p-calendar>
                                            </div>

                                        </div>
                                        <div class="col-md-6 form-group">

                                            <label for="renewalDate">Renewal Date 
                                            </label>
                                            <div class="input-group">
                                                <p-calendar class="w-100" id="renewalDate" [disabled]="disableBtn" dateFormat="dd-mm-yy" [showIcon]="true" inputId="icon" [minDate]="infoForm.get('contractInformation')['controls'][i].value['endDate']"  formControlName="renewalDate" [readonlyInput]="true"></p-calendar>
                                            </div>

                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="currency">Currency</label>
                                            <input  type="text" id="currency" style="height: 50px;" class="form-control" [readonly]="disableBtn" formControlName="currency" >
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="annualValue">Annual Value</label>
                                            <input  type="text" id="annualValue" style="height: 50px;" class="form-control" [readonly]="disableBtn" formControlName="annualValue" >
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="contractOwner">Contract Owner</label>
                                            <input  type="text" id="contractOwner" style="height: 50px;" class="form-control" [readonly]="disableBtn" formControlName="contractOwner" >
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label for="emailChange">Contact Email</label>
                                            <input  type="text" id="emailChange" style="height: 50px;" class="form-control" [readonly]="disableBtn" formControlName="email" name="email" (keyup)="emailChange()" (keydown)="validators.space($event)">
                                            <div app-errors class="error-msg" [control]="contractDoc.get('email')"
                                            label="Email"></div>
                
                                        </div>
                                        
                                        <div class="col-md-6 mt-2">
                                            <div class="uploadContainer space-between" [ngClass]="{  'disable-div': companyInfo?.status == 'Submitted'}" >

                                                <p class="title" id="contractTitle"> {{contract.title}}</p>
                                                <span class="inline-icon align-middle">
                                                <em class="icon-view icon" pTooltip="View Document" tooltipPosition="bottom" *ngIf="infoForm.get('contractInformation')['controls'][i].value['contractDocument']" (click)="download(infoForm.get('contractInformation')['controls'][i].value['contractDocument'])" ></em>
                                                <em *ngIf="!disableBtn"  pTooltip="Upload Document" tooltipPosition="bottom" class="icon-upload icon"  (click)="upload('contract',contract.name,i)" ></em>
                                            </span>

                                            </div>
                                        </div>
                                        <div class="buttons mb-2" *ngIf="!disableBtn">
                                            <button class="btn btn-primary" id="deleteContractBtn" *ngIf="infoForm.get('contractInformation')['controls']?.length>1" (click)="deleteContract(i)">Delete</button>
                                        </div>
                                     </div>   
                                    </ng-container>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="buttons">
                <ng-container >
                    <button class="btn cancel" id="backBtn" (click)="back()">BACK</button>
                    <button class="btn next" id="updateBtn" (click)="next('save')">UPDATE</button>
                </ng-container>
            </div>
    
        </div>
    </div>
</div>
<input type="file"  accept="application/pdf" style="visibility: hidden; height: 0px;width: 0px;" max="1" (change)="uploadFile()" #file>