<div class="container regulation-container">
    <div class="row">
        <div class="col-md-12">
            <h3 id="regulationsHeading">Regulations</h3>
            <form name="form" (ngSubmit)="f.form.valid && onSubmit(f.form)" #f="ngForm" novalidate>
                <ng-container *ngIf="companyInfo.checkboxes">
                    <div class="form-check" *ngFor="let check of checkboxes; let i = index">
                        <input [disabled]='disableBtn' class="form-check-input" type="checkbox" [name]="check.title" required
                            [(ngModel)]="check.value" (click)="openFile(check)" [id]="check">
                        <label class="form-check-label" for="check">
                            I agree to the <span class="openFile" (click)="openFile(check)">{{check.label}}</span>
                        </label>
                    </div>
                </ng-container>
                <div class="buttons">
                    <ng-container *ngIf="companyInfo?.status != 'Submitted'">
                        <button class="btn cancel" type="button" (click)="back()" id="backBtn" >BACK</button>
                        <button class="btn next" type="submit" id="submitBtn" [disabled]="f.invalid">SUBMIT</button>
                    </ng-container>
                    <ng-container *ngIf="companyInfo?.status == 'Submitted'">
                        <button class="btn cancel" type="button" (click)="back()" id="backBtnSubmitted">BACK</button>
                    </ng-container>
                </div>
            </form>
        </div>
    </div>
</div>