import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'primeng/dynamicdialog';
import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';
import { ConfirmationService } from 'primeng/api';
import {Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/shared-data.service';

@Component({
  selector: 'app-vendor-list-by-owner',
  templateUrl: './vendor-list-by-owner.component.html',
  styleUrls: ['./vendor-list-by-owner.component.scss']
})
export class VendorListByOwnerComponent implements OnInit {

  checked: boolean=true;
  showMe: boolean = false
  page = 1;
  pageSize = 10;

  customerHeaders: Array<any>
  customerPortList: Array<any>
  order: any = 'desc';
  col: any = 'createdDate';
  numberOfElements: any;
  invitedVendorList:any
  allVendorList: Array<any>=[];
  regStatusData:any;
  status: any;
  users: any = [];
  user:any;
  filterType: any = 'all';
  filterData:any;
  first=0;
  subscription:any; 
  constructor(public dialogService: DialogService,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private confirmationService:ConfirmationService,
    private router: Router,
    private sharedDataService:SharedDataService
    ) { }


  ngOnInit(): void {
    this.prepareCustomerHeaders();
    this.getAllVendors();
  }

  getAllVendors(){
    this.spinnerService.show();
    this.httpService.getData(PATH.VENDOR).subscribe((res:any)=>{
      this.filterData = res;
      this.invitedVendorList=res;
      this.allVendorList=res;
     
      this.invitedVendorList.forEach(elm => {
        elm.fullName = elm.firstName+' '+elm.lastName
        if(elm.status=='Submitted'){
          elm.status = 'Approval Pending'
        }
        if(elm.status=='Rfi Generated'){
          elm.status = 'RFI'
        } 	
        if(elm.status=='Profile Incomplete'){
          elm.status = 'Information Pending'
        } 
      });
      this.spinnerService.hide();
    },
    (error) => {
      this.spinnerService.hide();
      
    })
  }

  prepareCustomerHeaders() {
    this.customerHeaders = [
      { name: 'name', header: 'Company Name', sort: true, isAsc: true },
      { name: 'fullName', header: 'Name', sort: true, isAsc: false },
      { name: 'emailId', header: 'Email ID', sort: true, isAsc: false },
      { name: 'phoneNumber', header: 'Phone Number', sort: true, isAsc: false },
      { name: 'status', header: 'Status', sort: true, isAsc: false },
      { name: 'view', header: 'View', sort: false, isAsc: false  },
    ];
  }

  toogleTag() {
    this.showMe = !this.showMe
  }

  changeStatus(user) {
    this.confirmationService.confirm({
      message:"Are you sure that you want to change status?",
      accept:()=>{
    let vendorId = user.companyId;
    this.status = user.isActive;
    let url =  PATH.VENDOR + '/update-isActive'+"?status="+ this.status + "&vendorId="+vendorId;
    this.spinnerService.show();
    this.httpService.statusData(url).subscribe((res) => {
        this.spinnerService.hide();
        this.toastrService.success('Status Changed Successfully');
        
      }, (err) => {
        this.spinnerService.hide();
        this.toastrService.error('Status Changed Failed');
        
      })
    },reject:()=>{      
      
    }
  })
  }

  

  vendorOpen(id){
    this.router.navigate(['/owner/dashboard']);
  }

}
