<a class="responsive-sidenav" (click)="toggleSidebar()">
  <em class="icon-nav"></em> 
</a>

<div id="my-nav" [ngClass]="collapsed?'collapsed-sidenav':'expand-sidenav'" class="sidenav">
  <ul  *ngIf="isAdmin">
    <li>
      <a (click)="toggleSidebar()" id="menu">
        <em class="icon-nav"></em> 
      </a>  
    </li>
    <li class="user-details">   
      <em class="icons icon-user" id="user"></em> 
        <h1 id="userName">{{user?.username}}</h1>
    </li>
    <li [routerLinkActive]="'active'" >
      <a [routerLink]="['admin/dashboard']"  id="dashboard">
        <em class="icon-dashboard icon" pTooltip="Dashboard"></em> 
        <span>Dashboard</span>
      </a>
    </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['admin/info-sec-risk']"id="ownerInfoSecRisk">
        <em class="icon-info-sec-risk icon" pTooltip="Information Security Risk"></em> 
        <span>Information Security Risk</span>
      </a>
    </li>
    <li  [routerLinkActive]="'active'">
      <a [routerLink]="['admin/owners']" (click)="goToAdmin()" id="owners">
        <em class="icon-join icon" pTooltip="Listing Page"></em> 
        <span>Listing Page</span>
      </a>
      <a routerLink="/admin/invite-owner"  style="display: none"></a>
      <a routerLink="/admin/bulk-invite"  style="display: none"></a>
    </li>
    <li  [routerLinkActive]="'active'">
      <a [routerLink]="['admin/self-invited-owners']" (click)="goToAdmin()" id="selfRegisteredOwners">
        <em class="icon-self-reg icon" pTooltip="Self Registered Owners"></em> 
        <span>Self Registered Owners</span>
      </a>
      <a routerLink="/admin/owner/view"  style="display: none"></a>
    </li> 
    <li  [routerLinkActive]="'active'">
      <a [routerLink]="['admin/setting']" (click)="goToAdmin()" id="setting">
        <em class="icon-setting icon" pTooltip="Settings"></em> 
        <span>Settings</span>
      </a>
    </li>    
    <li class="user-logout">
      <a class="logout" href="" (click)="logout()" id="logOut">
        <em class="icon icon-logout" pTooltip="Settings"></em> 
        <span>Logout</span>
      </a>
    </li>
  </ul>  

  <ul *ngIf="(isVendorAdmin || isVendorNonAdmin) && (isCompanyRes && dashboardStep)">
    <li>
      <a (click)="toggleSidebar()" id="menu">
        <em class="icon-nav"></em> 
      </a>
    </li>
    <li class="user-details" >   
      <em class="icons icon-user"></em> 
       <h1 id="userName">{{user?.username}}</h1>
    </li>
    <li *ngIf="dashboardStep >=6"  [routerLinkActive]="'active'">
      <a [routerLink]="['vendor/dashboard']" id="dashboard">
        <em class="icon-dashboard icon" pTooltip="Dashboard"></em> 
        <span>Dashboard</span>
      </a>
      <a routerLink="vendor/dashboard"  style="display: none"></a>
    </li>
    <li *ngIf="dashboardStep < 6"  [routerLinkActive]="'active'">
      <a [routerLink]="['vendor/vendor-info']" id="companyInfo">
        <em class="icon-dashboard icon" pTooltip="Dashboard"></em> 
        <span>Dashboard</span>
      </a>
      <a routerLink="vendor/dashboard"  style="display: none"></a>
    </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['vendor/info-sec-risk']"id="vendorInfoSecRisk">
        <em class="icon-info-sec-risk icon" pTooltip="Information Security Risk"></em> 
        <span>Information Security Risk</span>
      </a>
      <a routerLink="/vendor/vendor-assessment-form"  style="display: none" ></a>
    </li>

    <li [routerLinkActive]="['active']" >
      <a [routerLink]="['vendor/users']" id="users">
        <em class="icon-add-user icon" pTooltip="User Management"></em> 
        <span>User Management</span>
      </a>
      <a routerLink="/vendor/user/new"  style="display: none"></a>
      <a routerLink="/vendor/user/edit"  style="display: none"></a>
    </li>
    
    <li class="user-logout" >
      <a class="logout" href="" (click)="logout()" id="logout">
        <em class="icon icon-logout" pTooltip="Settings"></em> 
        <span>Logout</span>
      </a>
    </li>
  </ul>

  <ul *ngIf="isOwnerAdmin || isOwnerNonAdmin">
    <li>
      <a (click)="toggleSidebar()" id="menu">
        <em class="icon-nav"></em> 
      </a>
    </li>
    <li class="responsive-user-logo" >
      <img src="../../../assets/images/logo.png" alt="logo" id="logo" />
    </li>
    <li class="user-details">   
      <em class="icons icon-user" pTooltip="User"></em> 
       <h1 id="userName">{{user?.username}}</h1>
   </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['owner/dashboard']" id="dashboard">
        <em class="icon-dashboard icon" pTooltip="Dashboard"></em> 
        <span>Dashboard</span>
      </a>
      <a routerLink="/owner/compliance"  style="display: none"></a>
      <a routerLink="/owner/risk"  style="display: none"></a>
      <a routerLink="/owner/risk-type"  style="display: none"></a>
    </li>

    <li [routerLinkActive]="'active'">
      <a [routerLink]="['owner/contract']" >
        <em class="icon-contract icon" pTooltip="Company Contract"></em> 
        <span>Company Contract</span>
      </a>
      <a routerLink="/owner/add-contract"  style="display: none"></a>
    </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['owner/info-sec-risk']"id="ownerInfoSecRisk">
        <em class="icon-info-sec-risk icon" pTooltip="Information Security Risk"></em> 
        <span>Information Security Risk</span>
      </a>
    </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['owner/vendors']" id="vendorListing">
        <em class="icon-join icon" pTooltip="Listing Page"></em> 
        <span>Listing Page</span>
      </a>
      <a routerLink="/owner/invite"  style="display: none"></a>
      <a routerLink="/owner/bulk-invite"  style="display: none"></a>
    </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['owner/vendor-info-listing']" id="vendorInfoListing">
        <em class="icon-vendor-info icon" pTooltip="Onboarding Status"></em> 
        <span>Onboarding Status </span>
      </a>
      <a routerLink="/owner/view-vendor"  style="display: none"></a>
    </li>
    <li [routerLinkActive]="'active'" >
      <a [routerLink]="['owner/search']" id="search">
        <em class="icon-smart-search icon" pTooltip="Search"></em> 
        <span>Search</span>
      </a>
      <a routerLink="/owner/search-result/"  style="display: none"></a>
      <a routerLink="/owner/bank-dashboard/"  style="display: none"></a>
    </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['owner/survey']" id="survey">
        <em class="icon-survey icon" pTooltip="Survey Listing"></em> 
        <span>Survey Listing</span>
      </a>
      <a routerLink="/owner/owner-non-admin-survey/"  style="display: none"></a>
    </li>
    <li [routerLinkActive]="'active'" *ngIf="isOwnerAdmin">
      <a [routerLink]="['owner/setting']" id="setting">
        <em class="icon-setting icon" pTooltip="Settings"></em> 
        <span>Settings</span>
      </a>
    </li>
    <li [routerLinkActive]="'active'">
      <a [routerLink]="['owner/top-vendors/customers']" id="topCustomers">
        <em class="icon-top10 icon" pTooltip="Our Top Customers / Vendors"></em> 
        <span>Our Top Counterparties</span>
      </a>
    </li>
    <li [routerLinkActive]="'active'"  *ngIf="isOwnerAdmin">
      <a [routerLink]="['owner/users']" id="users">
        <em class="icon-add-user icon" pTooltip="User Management"></em> 
        <span>User Management</span>
      </a>
      <a routerLink="/owner/user/new"  style="display: none"></a>
      <a routerLink="/owner/user/edit"  style="display: none"></a>
    </li>
    <li class="user-logout">
      <a class="logout" href="" (click)="logout()" title="Logout" id="logOut">
        <em class="icon icon-logout"></em> 
        <span>Logout</span>
      </a>
    </li>
  </ul>
</div>