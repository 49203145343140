<div class="success-dialog" *ngIf="dialogFor=='email'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h1 id="registration">Registration Successful!</h1>
            <span id="text">Your ID is Active. You can now login to DigiComp+</span>
        </div>
    </div>
    <button class="btn" id="okBtn" (click)="gotoLogin()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='invite'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h1 id="inviteHeading">Invite sent Successfully!</h1>
        </div>
    </div>
    <button class="btn" id="closeBtn" (click)="closeDialog()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='invite-owner'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h1 id="inviteHeadingOwner">Invite sent Successfully!</h1>
        </div>
    </div>
    <button class="btn" id="closeBtn1" (click)="closeDialog1()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='register'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h1 id="thankYouHeading">Thank You!</h1>
            <span id="checkText">Please check your inbox for further assistance.</span>
        </div>
    </div>
    <button class="btn" id="closeBtn3" (click)="closeDialog()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='activate'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h1 id="createUserHeading">User Created Successfully!</h1>
        </div>
    </div>
    <button class="btn" id="goToInvite" (click)="goToInvite()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='addVendorUser'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h1 *ngIf="!message" id="createCompanyUser">Company User Created Successfully!</h1>
            <h1 *ngIf="message" id="messageHeading">{{message}}</h1>
        </div>
    </div>
    <button class="btn" id="goToVendor" (click)="goToVendor()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='addUser'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h1 *ngIf="!message" id="addUserHeading">Company User Created Successfully!</h1>
            <h1 *ngIf="message" id="addMessageHeading">{{message}}</h1>
        </div>
    </div>
    <button class="btn" id="goToUser" (click)="goToUser()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='rfi'">
    <h3 class="font-medium leading-tight text-3xl mt-0 mb-2 text-black-600" id="requestHeading">Request For Company Information</h3>
    
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h3 class="font-medium leading-tight text-3xl mt-0 mb-2 text-black-600" id="requestSentHeading">Request Sent Successfully</h3>
            <p>Your request for information sent to <span class="text-black-600" id="requestMessage">{{message}}  </span></p>
        </div>
    </div>
    <button class="btn" id="goToCustomer" (click)="goToCustomer()">Ok</button>
</div>

<div class="success-dialog" *ngIf="dialogFor=='uploadRfi'">
    <h3 class="font-medium leading-tight text-3xl mt-0 mb-2 text-black-600" id="documentUploadHeading">Request For Document Upload</h3>
    
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/success.gif" alt="">
        </div>
        <div class="sucesstext">
            <h3 class="font-medium leading-tight text-3xl mt-0 mb-2 text-black-600" id="requestSentRfi">Request Sent Successfully</h3>
            <p>Your request for information sent to <span class="text-black-600" id="messageRfi">{{message}} </span></p>
        </div>
    </div>
    <button class="btn" id="goToCustomerRfi" (click)="goToCustomer()">Ok</button>
</div>
<div class="success-dialog" *ngIf="dialogFor=='upload-document'">
    <div class="sucessbox">
        <div class="img">
            <img src="../../../assets/images/notification.png" alt="">
        </div>
        <div class="sucesstext">
            <p id="sucesstext">Please ensure the document uploaded matches the information provided in Company Information for Company Name, Registration Number, Date of Incorporation and Registered Address.</p>
        </div>
    </div>
    <button class="btn" id="closeRfiBtn" (click)="closeDialog1()">Ok</button>
</div>