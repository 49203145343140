import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';
import {  Router } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-vendor-comment-dialog',
  templateUrl: './vendor-comment-dialog.component.html',
})
export class VendorCommentDialogComponent implements OnInit {

  @Output('getCompanyInfo') callParent: EventEmitter<any> = new EventEmitter();
  error: boolean = false;
  errorMsg: string = '';
  companyInfo:any;
  companyInformationId:any;
  status:any;
  remarks:any;
  companyId:any;
  display:boolean=false;
  isRfiGenerated:boolean=false;
  rejectionNameList:any;
  rejectionReason:any;
  getStatus:any;
  vendorData:any;
  vendorInformationId:any;
  constructor(  private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private confirmationService: ConfirmationService,
    private ref: DynamicDialogRef,
    private httpService:HttpService, 
    private spinnerService: NgxSpinnerService,public dialogService:DialogService,
    private dyanmicDialog: DynamicDialogConfig) { }
    public Form = this.formBuilder.group({
      remarks: ["",[Validators.required]],
      rejectionReason:["",[Validators.required]]
    })

  ngOnInit(): void {
    this.vendorData=this.dyanmicDialog.data;
    this.vendorInformationId=this.vendorData.data;
    this.getReasonForRejection();
  }

    
  
   
  getReasonForRejection(){
    this.spinnerService.show();
    this.httpService.getData(PATH.OWNER + '/rejectionReasons' ).subscribe((res:any)=>{
      this.rejectionNameList = res
      
      
      this.rejectionNameList = res.map(elm=>{return {id:elm.id,rejectionReason:elm.rejectionReason}})
    }
  )
  }
  

  rejectData(){
    
    if(!this.Form.valid){
      this.toastrService.error('Please fill required fields');
      return
    }
    this.ref.destroy();
    let payload:any={};
    let data=this.Form.getRawValue();
    payload.remarks=data.remarks;
    payload.rejectionReason=data.rejectionReason;
    payload.vendorInformationId=this.vendorInformationId;
    payload.status='REJECTED';
    this.confirmationService.confirm({
      message: "Are you sure that you want to REJECT?",
      accept: () => {
        this.spinnerService.show();
        this.httpService
          .patchData(PATH.VENDOR_APPROVE_REJECT ,payload)
          .subscribe(
            (res: any) => {
              this.spinnerService.hide();
              this.toastrService.success('Rejected Successfully!');
              this.router.navigate(['/owner/vendor-info-listing']);
            },
            (error) => {
              this.spinnerService.hide();
              this.toastrService.error(error.message?.error);
            }
          );
        this.confirmationService.close();
      },
      reject:()=>{
        this.confirmationService.close();
      }
    })
  }

  onClose(){
    this.ref.destroy();
  }

}
