<div class="form-fields">
  <div *ngIf="ctrl?.type == 'TEXT'" class="form-group">
    <label [for]="ctrl.id">{{ ctrl.questionName }}<em *ngIf="ctrl.mandatory" class="text-danger">*</em></label>
    <input class="form-control" [id]="ctrl.id" type="text" [formControl]="control" />
  </div>
  
  <div *ngIf="ctrl?.type == 'NUMERIC'" class="form-group">
    <label [for]="ctrl.id">{{ ctrl.questionName }}<em *ngIf="ctrl.mandatory" class="text-danger">*</em></label>
    <input class="form-control" [id]="ctrl.id" type="text" [formControl]="control" (keypress)="numberOnly($event)" />
  </div>
  
  <div *ngIf="ctrl?.type == 'FILE_UPLOAD'" class="form-group">
    <label [for]="ctrl.id">{{ ctrl.questionName }}<em *ngIf="ctrl.mandatory" class="text-danger">*</em></label>
    <div class="uploadContainer space-between"  [ngClass]="{  'disable-div': this.companyInfo?.status == 'Submitted'}" >
      <p class="title"> {{control.value}}</p>
      <span class="inline-icon align-middle">
        <em class="icon-view icon" pTooltip="View" tooltipPosition="bottom" *ngIf="control.value" (click)="download(control)"></em>
        <em class="icon-upload icon" *ngIf="!disable" pTooltip="Upload" tooltipPosition="bottom" (click)="upload()" ></em>
      </span>
      </div>
      <input *ngIf="!disable" type="file" accept="application/pdf" style="display:none;" max="1" (change)="uploadFile()" #file>
  </div>
  <div *ngIf="control.touched && control.errors" app-errors class="error-msg" [control]="control" [submit]="true" [label]="ctrl.questionName"></div>
</div>

