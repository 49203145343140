import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: '[app-errors]',
  templateUrl: './errors.component.html'
})
export class ErrorsComponent {

  @Input()
  control: UntypedFormControl;
  @Input()
  label: string;
  @Input()
  submit: boolean

}
