import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CompanyAPISuccess,
  HeaderAPISuccess,
  invokeCompanyAPI,
  invokeHeaderAPI,
  invokeUpdateBookAPI,
} from './action';
import { ReduxService } from './redux.service';
import { switchMap, map} from 'rxjs';
import { Store } from '@ngrx/store';
import { PATH } from '../app.constant';



@Injectable()
export class Effects {
  constructor(
    public action$: Actions,
    private store: Store,
    public service: ReduxService
  ) {}

  gg:any='ram';

  loadHeaderInfo$ = createEffect(() =>
    this.action$.pipe(
      ofType(invokeHeaderAPI),
      switchMap(() => {
        return this.service
          .get(PATH.USER_MANAGEMENT + `/loggedInUser`)
          .pipe(map((headerData) => HeaderAPISuccess({ allData: headerData })));
      })
    )
  );

  loadCompanyInfo$ = createEffect(() =>
    this.action$.pipe(
      ofType(invokeCompanyAPI),
      switchMap(() => {
        return this.service
          .get(PATH.COMPANY_INFORMATION)
          .pipe(
            map((companyData) =>
              CompanyAPISuccess({ allCompanyInfo: companyData })
            )
          );
      })
    )
  );

  // updateBook$ = createEffect(() =>
  //   this.action$.pipe(
  //     ofType(invokeUpdateBookAPI),
      
  //     switchMap((action) => {
  //       return this.service
  //         .updated(PATH.COMPANY_INFORMATION, action.payload)
  //         .pipe(map((data) => UpdateBookAPISuccess({ response: data })));
  //     })
  //   )
  // );
    
}
