import { environment } from "src/environments/environment";

let userManagement = 'user-management/api/v1/users';
let ownerManagement = 'owner-management/api/v1/owners';
let infosec = 'owner-management/api/v1/infosecs';
let ownerSelfReg = 'owner-management/api/v1/self-registration';
let ownerSetting = 'owner-management/api/v1/owner-settings/';
let ownerContract = 'owner-management/api/v1/owners/contracts'
let vendorManagement = 'vendor-management/api/v1/vendors';
let vendorInfo = 'vendor-management/api/v1/vendor-information';
let fileUpload = 'owner-management/api/v1/s3Files';
let searchCis = 'search/api/v1/cis';
let ownersSurvey = 'owner-management/api/v1/owners/survey';
let dashboard = 'search/api/v1/cis/dashboard/'
let ocr = 'ocr-management/api/v1/ocr/';

export const PATH = {
  // User Management
  LOGIN: userManagement+'/signIn',
  GET_ASSOCIATION:userManagement+'/associations',
  GET_LOGGEDIN_USER:userManagement+'/loggedInUser',
  USER_SET_PASSWORD:userManagement+'/password',
  USER_MANAGEMENT:userManagement,
  GET_USER_FORGOT_PASSWORD:userManagement+'/forget-password?userName=',
  FORGOT_PASSWORD:userManagement+'/password',
  UPDATE_USER:userManagement,
  GET_USER:userManagement,

  // Owner Management
  OWNER: ownerManagement,
  GET_DROPDOWNS: 'owner-management/api/v1/dropdowns',
  RFI:ownerManagement+"/rfi",

  // Vendor Management
  VENDOR: vendorManagement,
  GET_ALL_COUNTRIES:vendorManagement+'/countries',
  GET_VENDOR_DETAILS:vendorManagement+'/loggedInVendor',
  SURVEY_INVITE:vendorManagement+"/survey/invite",
  OWNER_BASIC_DETAILS:vendorInfo+'/owner-information',

  // Vendor Information
  COMPANY_INFORMATION: vendorInfo,
  SAVE_COMPANY_INFORMATION: vendorInfo+'/save',
  GET_VENDOR_SETTINGS: vendorInfo+'/vendor-settings',
  GET_REGULATIONS:vendorInfo+'/regulations-document',
  VENDOR_APPROVE_REJECT:vendorInfo+"/status",
  VENDOR_QUESTIONS: vendorInfo+'/questions',
  VENDOR_ESG: vendorInfo+'/esg',
  NOTES_SUBMIT:vendorInfo+'/notes',
  GET_SUBMIT:vendorInfo+'/notes',
  AUDIT_HISTORY: vendorInfo+'/audit/',
  GET_DASHBOARD_ESG:vendorInfo+'/dashboard/esgScore',
  VENDOR_ID_INFO: vendorInfo+'/dashboard/esg',
  VENDOR_DASHBOARD_COUNT:vendorInfo+'/dashboard/count',
  APPROVE_PENDING_USER:vendorInfo+'/companies/approve/',
  GET_VENDOR_SURVEY:vendorInfo+'/surveys',

  // Owner Self Registration
  OWNER_SELF_REGISTRATION:ownerSelfReg,

  // Owner Settings Controller
  OWNER_SETTING:ownerSetting,
  COMPANY_BASIC_INFO:ownerSetting+'owner-information',
  SETTING_ESG:ownerSetting+'esg',
  QUESTIONNAIRE:ownerSetting+'questions',
  SURVEYS:ownerSetting+'survey',
  VENDOR_SETTINGS:ownerSetting+'vendor-settings',
  VENDOR_TOP10:ownerSetting,
  FILE_UPLOAD: fileUpload,
  FILE_UPLOAD_OWNER: 'owner-management/api/v1/infosecs/admin/score-report',
  DELETE_FILE: fileUpload+'/delete?fileName=',
  REGULATIONS:ownerSetting+'regulations-document',
  OWNER_RISK_SETTING:ownerSetting+'risk-profile-information',
  ENTERPRISE_RISK:ownerSetting+'enterprise-risk',
  INFORMATION_DATA: 'owner-management/api/v1/infosecs/settings/admin/vendor-ques',
  GET_QUESTION: 'owner-management/api/v1/infosecs/settings/id/',
  GET_ALL_QUESTION: 'owner-management/api/v1/infosecs/settings',
  GET_VENDOR_NAME: 'owner-management/api/v1/infosecs/settings/vendor-ques-response/',
  GET_CONTRACT: 'owner-management/api/v1/owners/contracts/',
  GET_ALL_CONTRACT: 'owner-management/api/v1/owners/contracts/all',
  SEARCH_ALL_CONTRACT: 'owner-management/api/v1/owners/contracts/owner-search',
  // Search CIS
  SEARCH_CIS:searchCis,

  // Owner Survey
  OWNER_SURVEY:ownersSurvey,  
  
  // OCR
  GET_OCR:ocr+'compare-extracted-data/',
  OCR_EXTRACT:ocr+'extract-data',

  // Dashboard
  DASHBOARD:dashboard,

  // Information Security Risk
  INFO_SEC_RISK:infosec,

  // Contracts
  CONTRACT:ownerContract,

  REGISTRATION_STATUS_VENDOR:'agent-management/api/v1/companies/vendor/registrationStats',
  APPROVE_COMPANY_STATUS:'agent-management/api/v1/companies/approveStatus/',
};

export const SERVER_PATHS = {
  DEV: environment.apiEndPoint,
};
