<div class="vendorcomment-box">
    <form [formGroup]="Form">
        <div class="rejection-box">
            <div class="condition">
                <h3 class="mb-2 comment-section" id="rejectionReason">Reason for Rejection</h3>
            </div>
            <div class="input-wrapper margin-box" >
                <p-dropdown [style]="{'width': '48%'}" [options]="rejectionNameList" formControlName="rejectionReason" appendTo="body" optionValue="rejectionReason" optionLabel="rejectionReason" [showClear]="true" placeholder="Select a List"></p-dropdown>
            </div>
        </div>

       <div class="comment">
        <h3 class="mb-2 comment-section" id="commentMessage"> Comment / Message</h3>
        <textarea class="regulation-comment" autoResize="autoResize" name="remarks" formControlName="remarks"   [rows]="5" [cols]="80"   pInputTextarea></textarea>
       </div>

        <div class="buttons">
            <button class="btn cancel" id="cancelBtn" (click)="onClose()">Cancel</button>


            <button class="btn next" (click)="rejectData()" id="rejectData"
                [disabled]="isRfiGenerated==true || getStatus=='Profile Incomplete' || getStatus=='Rejected' || getStatus=='Not Found'">REJECT</button>
        </div>
    </form>
</div>