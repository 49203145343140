<div class="digicomp-registrations profile-linking">
    <div class="row">
        <div class="col-md-12">
            <h2 class="fw-bold" id="profileHeading">Profile</h2>

            <div class="stepper-container">
                <div class="bs-stepper profile-stepper">
                    <div class="bs-stepper-header" role="tablist">
                        <div class="step" data-target="#companyInformation">
                            <button type="button" class="step-trigger" id="companyInformationBtn" role="tab" [attr.disabled]="null" (click)="pages(1)"
                                aria-controls="companyInformation-part" id="companyInformation-part-trigger">
                                <span  class="bs-stepper-circle cursor-pointer data-table"  >1</span>
                                <div class="text active">
                                    <span class="bs-stepper-label">Company <span class="info">Information</span> </span>
                                </div>
                            </button>
                        </div>
                        <div class="line"></div>
                        <div class="step" data-target="#upload-part">
                            <button type="button" class="step-trigger" id="uploadDocumentBtn" role="tab" aria-controls="upload-part" (click)="pages(2)"
                                id="upload-part-trigger" [attr.disabled]="null">
                                <span class="bs-stepper-circle cursor-pointer data-table"  >2</span>
                                <div class="texts">
                                    <span class="bs-stepper-label">Upload <span class="infos">Documents</span></span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="bs-stepper-content p-0">
                        <div id="companyInformation" class="content" role="tabpanel"
                            aria-labelledby="companyInformation-part-trigger">
                            <div class="component">
                                <app-profile-info-company (getCompanyInfo)="getCompanyInfo($event)">
                                </app-profile-info-company>
                            </div>
                        </div>
                        <div id="upload-part" class="content" role="tabpanel" aria-labelledby="upload-part-trigger">
                            <div class="component">
                                <app-profile-info-upload-document (getCompanyInfo)="getCompanyInfo($event)"></app-profile-info-upload-document>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>