<div class="digicomp-registrations">
    <div class="page-heading  mt-4">
        <div class="left">
            <h1 id="securityRiskLabel">Information Security Risk</h1>
        </div>
    </div>
    <div class="row">
        <div class="page-content mt-4">
            <div class="showList">
                <p-table class="p-table" [columns]="headers" [value]="vendorInfoRisk"
                class="p-table mt-5" [resizableColumns]="true"
                responsiveLayout="scroll">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <ng-container *ngFor="let col of columns; let i = index">
                            <th class="cursor-pointer "[pSortableColumn]="col.name" id="{{'heading' + i}}" [pSortableColumnDisabled]="!col.sort" [ngStyle]="{'width': col.width}">{{col.header}}<img *ngIf="col.sort" class="topbottom"
                                src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" >
                            </th>
                            
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-risk let-i="rowIndex">
                    <tr >
                        <td>{{risk.ownerName}}</td>
                        <td>{{risk.createdDate | date}}</td> 
                        <td>
                            <div class="status" [ngClass]="[risk.status=='PENDING' ? 'bg-yellow' :'',risk.status=='SUBMITTED' ? 'bg-voilet' :'',  risk.status=='COMPLETED' ? 'bg-green' : '']">
                            
                                <span>{{risk.status | titlecase}}</span>
                        </div>
                    </td>
                        <td>
                            <button class="bluestatus" *ngIf="risk.status=='PENDING'" (click)="view(risk.infosecId)">Start Assessment</button>
                            <button class="bluestatus" *ngIf="risk.status!='PENDING'" (click)="view(risk.infosecId)">View Assessment</button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8" class="text-center" id="emptyLabel">No Data Available.</td>
                    </tr>
                </ng-template>
            </p-table>
            </div>
            <div class="boot-paginator" *ngIf="vendorInfoRisk?.length>0">
                <app-pagination [totalPages]="pager.totalPages" [size]="size" (onPageChange)="setPage($event)"></app-pagination>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [closable]="false"></p-confirmDialog>