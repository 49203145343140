<app-header-login></app-header-login>
<div class="login-cont-wrapper" *ngIf="!isForgotPass">
    <div class="login-container">
        <p class="Forgot-heading-label" id="forgotLabel">Forgot Password</p>
        <div class="para">
            <p id="registeredMail">Enter your registered email below to receive password reset instruction</p>
        </div>

        <form [formGroup]="forgotPassword" (ngSubmit)="sendForgot()">
            <div class="form-group">
                <div class="login-input">
                    <em class="icon-user-line icon"></em>
                    <input type="text" class="form-control" id="userName" placeholder="Username" formControlName="userName" [ngClass]="{'is-invalid':formSubmitAttempt && f.userName.errors}" (keydown)="validators.space($event)">
                </div>
             <div app-errors class="error-msg" [control]="f.userName" [submit]="formSubmitAttempt" label="Username"></div>
            </div>
            <div class="buttons">
                <button class="btn login-btn" id="sendBtn">Send</button>
            </div>
            <div class="buttons">
                <button class="back" id="backBtn" [routerLink]="['/login']" >Back to login</button>
            </div>
        </form>
    </div>   
</div>

<div class="login-cont-wrapper" *ngIf="isForgotPass">
    <div class="login-container">
        <p class="Forgot-heading-label" id="checkMailText">Check Your Email</p>
        <div class="para">
            <p id="recoveryText">We have sent a password recovery instruction to your email.</p>
        </div>

        <div class="buttons">
            <button class="btn login-btn" id="okBtn"  [routerLink]="['/login']" >OK</button>
        </div>
    </div>   
</div>

