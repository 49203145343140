<app-header-login></app-header-login>
<div class="register-cont-wrapper">
    <div class="register-container">
        <h1 class="login-heading" id="registerCompany">Register Your Company</h1>
        <form  [formGroup]="registerForm">
            <div class="row form-group">
                <div class="col-md-6 mb-3">
                    <label class="form-label" for="country">Country: </label>
                    <p-dropdown id="country" [options]="countries" filter="true" name="country" id="country" formControlName="country" optionLabel="name" (keypress)="characterOnly($event)"
                        optionValue="code" (onChange)="changeCountry($event)" placeholder="Select a Country"  [ngClass]="{'is-invalid':formSubmitAttempt && f.country.errors}">
                    </p-dropdown>
                    <div app-errors class="error-msg" [control]="f.country" [submit]="formSubmitAttempt"
                        label="Country"></div>
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label" for="companyName">Company Name: </label>
                    <input id="companyName" type="text" class="form-control" placeholder="Company Name" name="companyName" id="companyName" formControlName="companyName"
                        [ngClass]="{'is-invalid':formSubmitAttempt && f.companyName.errors}" (change)="inputValueEvent($event)" (keydown)="space($event)">
                    <div app-errors class="error-msg" [control]="f.companyName" [submit]="formSubmitAttempt"
                        label="Company Name"></div>
                </div>

                <div class="col-md-6 mb-3" [pTooltip]="toolTipIdentification">
                    <label class="form-label" for="identificationType">Registration Reference Type:</label>
                    <input id="identificationType" type="text" readonly class="form-control" (change)="inputValueEvent($event)" name="identificationType" id="identificationType" placeholder="Registration Reference Type"
                        formControlName="identificationType" [ngClass]="{'is-invalid':formSubmitAttempt && f.identificationType.errors}"
                        (keydown)="space($event)">
                    <div app-errors class="error-msg" [control]="f.identificationType"  [submit]="formSubmitAttempt"
                        label="Registration Reference Type"></div>
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label" for="identificationNumber">Registration Number:</label>
                    <input id="identificationNumber" type="text" class="form-control" (change)="inputValueEvent($event)" placeholder="Registration Number" id="identificationNumber" name="identificationNumber"
                        formControlName="identificationNumber" 
                        [ngClass]="{'is-invalid':formSubmitAttempt && f.identificationNumber.errors}"
                        (keydown)="space($event)">
                    <div app-errors class="error-msg" [control]="f.identificationNumber" [submit]="formSubmitAttempt"
                        label="Registration Number"></div>
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label" for="firstName">First Name:</label>
                    <input id="firstName" type="text" class="form-control" (change)="inputValueEvent($event)" placeholder="First Name" name="firstName"  formControlName="firstName" id="firstName"
                        [ngClass]="{'is-invalid':formSubmitAttempt && f.firstName.errors}" (keydown)="space($event)">
                    <div app-errors class="error-msg" [control]="f.firstName" [submit]="formSubmitAttempt"
                        label="First Name"></div>
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label" for="lastName">Last Name:</label>
                    <input id="lastName" type="text" class="form-control" (change)="inputValueEvent($event)" placeholder="Last Name" name="lastName" formControlName="lastName" id="lastName"
                        [ngClass]="{'is-invalid':formSubmitAttempt && f.lastName.errors}" (keydown)="space($event)">
                    <div app-errors class="error-msg" [control]="f.lastName" [submit]="formSubmitAttempt"
                        label="Last Name"></div>
                </div>

                <div class="col-md-6 mb-3">
                    <label class="form-label" for="emailId">Email ID:</label>
                    <input id="emailId" type="text" class="form-control" (change)="inputValueEvent($event)" placeholder="Email ID" name="emailId" formControlName="emailId" id="emailId"
                        [ngClass]="{'is-invalid':formSubmitAttempt && f.emailId.errors}" (keydown)="space($event)">
                    <div app-errors class="error-msg" [control]="f.emailId" [submit]="formSubmitAttempt"
                        label="Email ID"></div>
                </div>

                <div class="col-md-6 mb-3" *ngIf="inputOptions">
                    <label class="form-label" for="phoneNumber">Phone Number:</label>
                    <input id="phoneNumber" #phone class="form-control" (change)="inputValueEvent($event)" (keypress)="numberOnly($event);onError($event)" placeholder="  "
                        ng2TelInput name="phoneNumber" [searchCountryFlag]="true" formControlName="phoneNumber" id="phoneNumber" [ng2TelInputOptions]="inputOptions"
                        (countryChange)="onCountryChange($event)" (hasError)="onError($event)"
                        [ngClass]="{'is-invalid':(formSubmitAttempt && f.phoneNumber.errors) && !hasError}" (keydown)="space($event)">
                    <div app-errors class="error-msg" [control]="f.phoneNumber"  [submit]="formSubmitAttempt"
                        label="Phone Number"></div>
                    <div class="error-msg" *ngIf="!f.phoneNumber.errors && !hasError">
                        Invalid Phone Number
                    </div>
                </div>
            </div>
            
            <div class="text-center">
                <button type="button" (click)="signup()" id="register-button" class="btn login-btn">Register Now</button>
            </div>

            <div class="text-center">
                <p class="register-user" id="register">Already Registered? <a [routerLink]="['/login']">Login</a></p>
            </div>

        </form>
    </div>
</div>