
<div class="directors mt-4" *ngIf="financialData">
    <div class="bank-wrappers mb-4">
        <div class="bank-box">
            <div class="left">
                <img src="../../../assets/images/directors.png" alt="">
            </div>
            <div class="right">
                <label for="totalDirectors" id="totalDirectorLabel">Total Directors</label>
                <p id="totalDirectors">{{financialData?.cisFinancialSearchData?.totalDirectors}}</p>
                <p id="totalDirectorss" *ngIf="financialData.cisFinancialSearchData.totalDirectors==null" >NA</p>
            </div>
        </div>
        <div class="bank-box">
            <div class="left">
                <img src="../../../assets/images/shareholder.png" alt="">
            </div>
            <div class="right">
                <label for="totalShareHolders" id="totalShareHoldersLabel">Total Shareholders</label>
                <p id="totalShareHolders">{{financialData?.cisFinancialSearchData?.totalShareholders}}</p>
                <p id="totalShareHolderss" *ngIf="financialData.cisFinancialSearchData.totalShareholders==null" >NA</p>
            </div>
        </div>
        <div class="bank-box">
            <div class="left">
                <img src="../../../assets/images/no.png" alt="">
            </div>
            <div class="right">
                <label for="noOfEmployees" id="noOfEmployeesLabel"> No of Employees </label>
                <p id="noOfEmployees">{{financialData?.cisFinancialSearchData?.noOfEmployee}}</p>
                <p id="noOfEmployeess" *ngIf="financialData.cisFinancialSearchData.noOfEmployee==null" >NA</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="heading">
                <h1 id="directorHeading">Directors</h1>
            </div>
            <div class="row">
                <div class="page-content mt-3">
                    <div class="showList mt-2">
                        <p-table class="table catalogue-table" [columns]="locationheaders" [value]="locationData"
                            class="p-table mt-5" responsiveLayout="scroll" [resizableColumns]="true">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <ng-container *ngFor="let col of columns; let i = index">
                                        <th class="cursor-pointer " id="{{header + i}}" pSortableColumn={{col.name}}>{{col.header}}<img
                                                *ngIf="col.sort" class="topbottom"
                                                src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" >
                                        </th>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-user>
                                <tr style="height: 56px;color: gray;">
                                    <td>{{user.name}}</td>
                                    <td>{{user.position}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="heading">
                <h1 id="shareholder">Shareholders</h1>
            </div>
            <div class="row">
                <div class="page-content mt-3">
                    <div class="showList mt-2">
        
        
                        <p-table class="table catalogue-table" [columns]="shareholderheader" [value]="shareholderdata"
                            class="p-table mt-5" responsiveLayout="scroll" [resizableColumns]="true">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <ng-container *ngFor="let col of columns;let i = index">
                                        <th class="cursor-pointer " id="{{'shareholder' + i}}" pSortableColumn={{col.name}}>{{col.header}}<img
                                                *ngIf="col.sort" class="topbottom"
                                                src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" >
                                        </th>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-user>
                                <tr style="height: 56px;color: gray;">
                                    <td>{{user.companyName}}</td>
                                    <td>{{user.percentage |currency:'':''}}</td>
                                    <td>{{user.country}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="directors mt-5 center-form" *ngIf="!financialData">
    <h3 class="font-medium leading-tight text-3xl mt-0 mb-2 text-black-600 mt-4" id="dataNotAvailable">Data Not Available</h3>
</div>