import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PATH } from 'src/app/app.constant';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';
import {DialogService} from 'primeng/dynamicdialog';
@Component({
  selector: 'app-bank-dashboard',
  templateUrl: './bank-dashboard.component.html',
  styleUrls: ['./bank-dashboard.component.scss']
})
export class BankDashboardComponent implements OnInit {

  responsiveOptions;
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  order: any = 'desc';
  col: any = 'createdDate';
  locationheaders: Array<any>;
  shareholderheader: Array<any>;
  numberOfElements:any;
  locationData:any;
  cisFinancialData:any;
  shareholderdata:any;
  user:any;
  numberOfEmployee:any;
  entityId:any;
  isFinInfo:boolean=false;
  @Input() financialData:any;
  constructor(public dialogService:DialogService,
    private ar: ActivatedRoute,private router: Router,
    private httpService: HttpService,
    private spinnerService: NgxSpinnerService, private appCookieService: AppCookieService) { 
  }

  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.entityId = this.user.entityId;
    this.prepareHeader();
    this.prepareShareHolder();
    this.getCisDashboardData();
 
  }
 

    prepareHeader(){
      this.locationheaders = [
        { name: 'name', header: 'Name', sort: true,isAsc:false},
        { name: 'position', header: 'Position', sort: true,isAsc:false},
      ];
    }


    prepareShareHolder(){
      this.shareholderheader = [
        { name: 'companyName', header: 'Name', sort: true,isAsc:false},
        { name: 'percentage', header: 'Percentage', sort: true,isAsc:false},
        { name: 'country', header: 'Nationality', sort: true,isAsc:false},
      ];
    }


    
  getCisDashboardData(){
    // DASHBOARD
    this.httpService.getData(`${PATH.DASHBOARD}/FINANCIAL/${this.entityId}`).subscribe((res: any) => {
      this.cisFinancialData=res['content'];
      this.cisFinancialData.forEach(element => {
        this.financialData=element
        this.shareholderdata=this.financialData?.cisFinancialSearchData?.shareHolders;
        this.locationData=this.financialData?.cisFinancialSearchData?.directors;
        
      });

      
    })
  }


}
