import {
  Component,
  OnInit
} from '@angular/core';

import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { AppCookieService } from 'src/app/services/cookieService';
@Component({
  selector: 'app-vendor-dashboard',
  templateUrl: './vendor-dashboard.component.html',
  styleUrls: ['./vendor-dashboard.component.scss']
})
export class VendorDashboardComponent implements OnInit {
  isDashboard:true;
  isDirectors:true;
  adverseMedia:any;
  invitedCompDetails:any;
  companyLogoUrl;
  companyInfo:any;
  vendorEntityId:any;
  financialData:Array<any>=[];
  isFinancialRes:boolean=false;
  constructor(private httpService: HttpService,
    private spinnerService: NgxSpinnerService,
    private appCookieService: AppCookieService,) { }

  ngOnInit(): void {
    let user = JSON.parse(this.appCookieService.get('digiUser'));
    this.vendorEntityId=user.entityId;
    this.getCompanyInfo();
    this.getCompanyLogo();
    this.getAdverseMediaData();
    this.getFinancial();
   }

  getCompanyLogo(){
    this.spinnerService.show();
      this.httpService.getData(PATH.OWNER_BASIC_DETAILS).subscribe((res: any) => {
        this.invitedCompDetails = res;
        this.getFile(res.companyLogo)
        this.spinnerService.hide();
      },(error) => {
        this.spinnerService.hide();
      });
    }

    getFile(data){
      this.httpService.getImage(PATH.FILE_UPLOAD+'?fileName='+data).subscribe((res)=>{
        this.companyLogoUrl = res;
      })
    }

    getAdverseMediaData(){
      this.adverseMedia=[
        {
          // title:'DigiComp+ platform enables a seamless way to link past, present and future data for due diligence checks in an economical and efficient way. This real-time data analytics process can also generate actionable insights for the future. It is a platform that is designed for transparency, resilience and sustainability, maximizing the return on data investment. Risk mitigation, fast and effective decision-making and creating trust with your customers is now a click away. Subscribe to DigiComp+ platform and manage your business efficiently whilst avoiding costly penalties and sanctions'
        },
        
      ]
    }

    getCompanyInfo() {
      this.spinnerService.show();
      this.httpService.getData(PATH.COMPANY_INFORMATION).subscribe((res: any) => {
        this.companyInfo = res;
        this.spinnerService.hide();
      },(error) => {
        this.spinnerService.hide();
      });
    }

    getFinancial(){
      this.spinnerService.show();
      this.httpService.getData(PATH.DASHBOARD+'FINANCIAL/'+this.vendorEntityId).subscribe((res: any) => {
      
        this.financialData = res['content'];
        this.isFinancialRes=true;
        this.spinnerService.hide();
      },(error) => {
        this.spinnerService.hide();
        this.isFinancialRes=true;
      });
    }

}
