<div class="esg mt-4">
    <div class="heading">
        <h1 id="esgHeading">ESG</h1>
    </div>

    <div class="banner mt-3">
        <div class="left">
            <p id="textLeft">Environmental, Social, & Governance (ESG) Score</p>
        </div>
        <div class="right">
            <div class="images" (click)="addLockPopup()">
                <img class="lock" src="../../../assets/images/lockscreen.png" alt="">
            </div>
        </div>
    </div>

    <div class="esg-table">
        <div class="row">
            <div class="page-content mt-3 mb-3">
                <div class="showList mt-2">
    
    
                    <p-table class="table catalogue-table" [columns]="locationheaders" [value]="companyInfo?.esg"
                        class="p-table mt-5" responsiveLayout="scroll">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <ng-container *ngFor="let col of columns; let i = index">
                                    <th class="cursor-pointer " id="{{ 'header' + i }}" pSortableColumn={{col.name}}>{{col.header}}<img
                                            *ngIf="col.sort" class="topbottom"
                                            src="../../assets/images/arrow_drop_up-24px@2x.png" alt="top-bottom" >
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-Esg>
                            <tr class="tr">
                                <td>{{Esg.question}}</td>
                                <td>
                                    <em *ngIf="Esg.answer == 'yes'" class="icon icon-tick-circle"></em>
                                    <em *ngIf="Esg.answer != 'yes'" class="icon icon-cross-circle"></em>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8" class="text-center">No Data Available.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>