import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PATH } from 'src/app/app.constant';
import { invokeUpdateBookAPI } from 'src/app/redux/action';
import { Selector } from 'src/app/redux/selector';
import { AppCookieService } from 'src/app/services/cookieService';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'company-info-esg',
  templateUrl: './company-info-esg.component.html',
  styleUrls: ['./company-info-esg.component.scss'],
})
export class CompanyInfoEsgComponent implements OnInit {
  @Output("getCompanyInfo") callParent: EventEmitter<any> = new EventEmitter();

  esg: Array<any> = [];
  subsriptions: Subscription;
  user: any;
  companyInfo: any = {};
  isDisabled:boolean=false;
  disableBtn: boolean = false;
  questionForm: UntypedFormGroup;
  constructor(
    private httpService: HttpService,
    private router: Router,
    private appCookieService: AppCookieService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private fb: UntypedFormBuilder,
    public store: Store
  ) { }

  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.getEsg();
  }

  getEsg() {
    this.httpService.getData(PATH.VENDOR_ESG).subscribe((res: Array<any>) => {
      this.esg = [];
      res.forEach((question) => {
        if (question.question) {
          this.esg.push({ question: question.question, answer: '' });
        }
      });
      this.getCompanyInfo();
      this.spinnerService.hide();
    });
  }

  getCompanyInfo() {
    this.store.select(Selector).subscribe((res: any) => {
      if(res?.companyInfo?.id || res?.companyInfo?.length > 0){
        let resData:any;
        if(res?.companyInfo?.id){
          resData = JSON.parse(JSON.stringify(res.companyInfo));
        }
        if(res?.companyInfo?.length > 0){
          resData = JSON.parse(JSON.stringify(res.companyInfo[0]));
        }
        this.companyInfo = resData;
        if (this.companyInfo.status == 'Submitted') {
          this.disableBtn = true;
        }
        if (this.companyInfo['esg']?.length) {
          this.esg = this.companyInfo['esg'];
        }
      }

    },
      (error) => {
        this.spinnerService.hide();
      }
    );
  }

  onSubmit(form: UntypedFormGroup) {
    if (form.invalid) {
      this.toastrService.error('Please fill all the fields', 'Error');
      return;
    }
    else {
      let esg = [];
      this.esg.forEach((q) => {
        let answer = {
          "answer": q.answer,
          "question": q.question
        }
        esg.push(answer)
      });
      form.markAllAsTouched();
      this.companyInfo['esg'] = esg;
      this.updateCompanyInfo();
    }

  }


  updateCompanyInfo() {
    // this.spinnerService.show();
    let branches = [];
    if (this.companyInfo.branchInOtherCountry) {
      this.companyInfo.branchInOtherCountry.map((o) => {
        o.branch ? branches.push(o.branch) : branches.push(o);
      });
      this.companyInfo.branchInOtherCountry = branches;
    }
    if (this.companyInfo.step <= 5) {
      this.companyInfo.step = 5;
    }
    this.spinnerService.show();
    this.httpService.updateData(PATH.COMPANY_INFORMATION, this.companyInfo).subscribe(
      (res) => {
        this.store.dispatch(invokeUpdateBookAPI({ payload: { ...res }, user:this.user.entityId }));
        this.companyInfo = res;
        this.spinnerService.hide();
        this.router.navigate(['/vendor/vendor-info/5']);
        this.callParent.emit({ step: 5, url: '/vendor/vendor-info/5', progressStep: this.companyInfo.step })
      },
      (error) => {
        this.spinnerService.hide();

        this.toastrService.error(error.message?.error);
      }
    );
  }


  goNext() {
    this.router.navigate(['vendor/vendor-info/5']);
    this.callParent.emit({
      step: 5,
      url: '/vendor/vendor-info/5',
      progressStep: this.companyInfo.step
    });
  }

  back() {
    this.isDisabled=false;
    this.router.navigate(['/vendor/vendor-info/3'])
    this.callParent.emit({ step: 3, url: '/vendor/vendor-info/3', progressStep: this.companyInfo.step })
  }
  keypress(ev){
   
    
    if(ev){
      this.isDisabled=true;
    }else{
      this.isDisabled=false;
    }
  }
  
}
