<div class="search-box">
    <form  [formGroup]="infoSecRiskForm">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <label>Type</label>
                <div class="type">
                    <div class="checkbox">
                        <p-radioButton name="vendorType" value="CUSTOMER" inputId="customer" (onClick)="checkVendorType($event)" formControlName="vendorType"></p-radioButton>
                        <label for="customer">Customer</label>
                    </div>
                    <div class="checkbox">
                        <p-radioButton name="vendorType" value="VENDOR" inputId="vendor" (onClick)="checkVendorType($event)" formControlName="vendorType"></p-radioButton>
                        <label for="vendor">Vendor</label>
                    </div>
                </div>
            </div>
        
        <div class="input-wrapper col-lg-3 col-md-6">
            <label for="companyName">Select Customer / Vendor</label>
            <p-dropdown [options]="vendorType=='VENDOR'?vendorList:customerList" filter="true" name="companyName" 
                optionLabel="vendorName" id="companyName" placeholder="Search Customer / Vendor"
                formControlName="vendor">
            </p-dropdown>
        </div>
        <div class="col-lg-3 col-md-8 display-flex" >
            <div class="buttons ">
                <button class="btn cancel white-box" id="clearBtn">Clear</button>
                <button  class="btn save" id="submitBtn" (click)="submit()">Submit</button>
            </div>
        </div>
    </div>
    </form>
</div>