import { Component } from '@angular/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html'
})
export class IndexComponent{
  isCollapsed:boolean=true;
  collapsed(itm){
    itm=='collapsed'?this.isCollapsed=true:this.isCollapsed=false;
}
}
