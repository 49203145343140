import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  HostListener,
} from '@angular/core';
import { AppCookieService } from 'src/app/services/cookieService';
import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsServiceService } from 'src/app/services/validators-service.service';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { email } from 'src/app/services/custom.validations';

@Component({
  selector: 'app-profile-info-upload-document',
  templateUrl: './profile-info-upload-document.component.html',
  styleUrls: ['./profile-info-upload-document.component.scss']
})
export class ProfileInfoUploadDocumentComponent implements OnInit {
  user: any;
  disableBtn:boolean = false;
  uploaded: boolean;
  @Output('getCompanyInfo') callParent: EventEmitter<any> = new EventEmitter();
  submitted: boolean = false;
  documents = [
    {
      title: 'Incorporation Documents',
      name: 'certificateOfIncorporation',
    },
    {
      title: 'Memorandum & Articles of Association',
      name: 'memorandumArticlesOfAssociation',
    },
    {
      title: 'Financial Statements for last three years',
      name: 'financialStatementsLast3Years',
    },
    {
      title: 'Trading License',
      name: 'tradingLicense',
    },
    {
      title: 'Address Proof',
      name: 'addressApproval',
    },
    {
      title: 'Insurance Certificate',
      name: 'insuranceCertificate',
    },
  ];

  shareholders = [
    {
      title: 'Passport',
      name: 'passport',
    },
    {
      title: 'National ID Card',
      name: 'personalIdentification',
    },
  ];

  companyShareholders = [
    {
      title: 'Trading License',
      name: 'tradingLicense',
    },
  ];
  

  directors = [
    {
      title: 'Passport',
      name: 'passport',
    },
    {
      title: 'National ID Card',
      name: 'personalIdentification',
    },

  ];
  contracts = [
    {
      title: 'Upload Contract Docs',
      name: 'contractDocument',
      contractName: '',
      endDate: '',
      renewalDate: '',
      startDate: '',
    },
  ];

  
  companyInfo: any = {};
  type:any='';
  @ViewChild('file') fileInput: ElementRef;
  fileDetails: any = {};
  reqType:string = 'save';
  date =new Date();

 
  
  constructor(
    private router: Router,
    private appCookieService: AppCookieService,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public validators:ValidatorsServiceService,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2
  ) {}

  

  infoForm = this.formBuilder.group({
    contractInformation:this.formBuilder.array([this.addContractGroup(null)]),
  })

  
  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.getCompanyInfo();
    window.scroll({
      top: 50,
      left: 0,
      behavior: 'smooth',
    });
  }

  getCompanyInfo() {
    this.spinnerService.show();
    this.httpService
      .getData(PATH.COMPANY_INFORMATION+'/profile')
      .subscribe(
        (res: any) => {
          if (!res) {
            this.spinnerService.hide();
            return;
          }       
          let branches = [];
          if (res.branchInOtherCountry) {
            res.branchInOtherCountry?.map((o) => {
              branches.push(o);
            });
            res.branchInOtherCountry = branches;
          } 
          this.spinnerService.hide();
          this.companyInfo = res;
          const contractInformation = new UntypedFormArray([]);
          res.contractInformation?.forEach((data) => {
            if(data.endDate){
              data.endDate =new Date( moment(data.endDate).format('yyyy-MM-DD'));
            }
            if(data.renewalDate){
              data.renewalDate =new Date( moment(data.renewalDate).format('yyyy-MM-DD'));
            }
            if(data.startDate){
              data.startDate = new Date(moment(data.startDate).format('yyyy-MM-DD'));
            }
            
            
            contractInformation.push(this.addContractGroup(data));
          });
          this.infoForm.setControl('contractInformation', contractInformation);
        
          if(this.companyInfo.status == 'Submitted'){
            this.disableBtn = true;
          }
        },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }
  upload(type, name, index) {
    this.fileDetails = { type: type, name: name, index: index };
    let i = this.documents.findIndex((d) => {
      return d.name == name;
    });
    this.fileInput.nativeElement.click();
  }
  addContract() {
    (<UntypedFormArray>this.infoForm.get('contractInformation')).push(
      this.addContractGroup(null)
    );
  }
  deleteContract(index) {
    (<UntypedFormArray>this.infoForm.get('contractInformation')).removeAt(index);
  }

  emailChange(){
    
  }

  addContractGroup(data): UntypedFormGroup {
    return this.formBuilder.group({
      annualValue: [data ? data.annualValue : ''],
      contractDocument: [data ? data.contractDocument : ''],
      contractName: [data ? data.contractName : ''],
      contractOwner: [data ? data.contractOwner : ''],
      currency: [data ? data.currency : ''],
      email: [data ? data.email : '',[email,Validators.maxLength(80)]],
      endDate: [data ? data.endDate : ''],
      renewalDate: [data ? data.renewalDate : ''],
      startDate: [data ? data.startDate : ''],
    });
  }

  uploadFile() {
    const fileBrowser = this.fileInput.nativeElement;

    if (fileBrowser.files && fileBrowser.files[0]) {
      if (fileBrowser.files[0].size > 10485760) {
        this.toastrService.error('File size should be max 10MB', 'Error');
        return;
      }
      if (fileBrowser.files[0].type !='application/pdf') {
        this.toastrService.error('Only Pdf Allowed', 'Error');
        return;
      }
     
      let data = new FormData();
      data.append('file', fileBrowser.files[0]);
      this.spinnerService.show();

      this.httpService.postData(PATH.FILE_UPLOAD,data).subscribe(
        (res) => {
          if (this.fileDetails.type == 'doc') {
            this.companyInfo[this.fileDetails.name] = res.fileName;
          } else if (this.fileDetails.type == 'shareholder') {
            this.companyInfo.shareHolders[this.fileDetails.index][
              this.fileDetails.name
            ] = res.fileName;
          } else if (this.fileDetails.type == 'director') {
            this.companyInfo.boardOfDirectors[this.fileDetails.index][
              this.fileDetails.name
            ] = res.fileName;
          } else if (this.fileDetails.type == 'contract') {
            this.infoForm.get('contractInformation')['controls'][this.fileDetails.index].value[this.fileDetails.name]=res.fileName
          }
          this.reqType='Submit';
          this.updateCompanyInfo();
          this.fileInput.nativeElement.value = '';
          this.toastrService.success("File uploaded Successfully")
          this.spinnerService.hide();
        },
        (err) => {
          this.spinnerService.hide();

          this.toastrService.error(err.message);
          this.fileInput.nativeElement.value = '';
        }
      );
    }
  }
  convertDate(dateString) {
    let dateParts: Array<any> = this.ngbDateParserFormatter
      .format(dateString)
      .split('/');
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }
  updateCompanyInfo() {
   
    this.infoForm.markAllAsTouched();
    if(!this.infoForm.valid){
      return;
    }

    this.companyInfo.contractInformation=this.infoForm.get('contractInformation').value;
    this.companyInfo.contractInformation.map((o) => {
      o.endDate =='Invalid date'?'': moment(o.endDate).format('yyyy-MM-DD');
      o.renewalDate =='Invalid date'?'': moment(o.renewalDate).format('yyyy-MM-DD');
      o.startDate == 'Invalid date'?'': moment(o.startDate).format('yyyy-MM-DD');
      o.email=o.email.toLowerCase();
    });
    this.spinnerService.show();
    if(this.reqType=='submit'){
      this.companyInfo.status = 'Submitted';
    }
    this.httpService.updateData(PATH.COMPANY_INFORMATION+'/profile',this.companyInfo).subscribe(
        (res) => {
          if(res){
            this.companyInfo = res;
            this.spinnerService.hide();
            this.getCompanyInfo();
            if(this.reqType=='save'){
              this.router.navigate(['vendor/vendor-info']);
              this.callParent.emit({
                step: 1,
                url: '/vendor/profile-info/1',
                progressStep:this.companyInfo.step
              });
              this.toastrService.success('Profile updated successfully');
            }
              
          }
        },
        (error) => {
          this.spinnerService.hide();
          
        }
      );
  }
  deleteFile(type, name, index, filename) {
    this.httpService
      .deleteData(PATH.DELETE_FILE + filename)
      .subscribe((res) => {
        this.toastrService.success('File Deleted Successfully!');
        if (type == 'doc') {
          this.companyInfo[name] = null;
        } else if (type == 'shareholder') {
          this.companyInfo.shareHolders[index][name] = null;
        } else if (type == 'director') {
          this.companyInfo.boardOfDirectors[index][name] = null;
        }
        this.updateCompanyInfo();
      });
  }

  next(type) {
    this.submitted = true;
    this.reqType = type;
    this.updateCompanyInfo();
  }


  download(filename) {
    if(filename){
      this.spinnerService.show();
      let type = this.checkDocumentType(filename);
      this.httpService.download(PATH.FILE_UPLOAD+'?fileName='+filename).subscribe((res)=>{
        this.spinnerService.hide();
        let file = new Blob([res], {type: type});
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
    }else{
      this.toastrService.error('Please Upload Document', 'Error');
    }
  }
  


  checkDocumentType(filename){
    let fileType = filename.split('.').pop();
    if(fileType == 'jpeg'){
      return 'image/jpeg';
    }
    if(fileType == 'pdf'){
      return 'application/pdf';
    }
    if(fileType == 'png'){
      return 'image/png';
    }
    if(fileType == 'gif'){
      return 'image/gif';
    }
  }

  back() {
    this.router.navigate(['/vendor/profile-info/1']);
    this.callParent.emit({
      step: 1,
      url: '/vendor/profile-info/1',
      progressStep:this.companyInfo.step
    });
  }
}