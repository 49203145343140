import { ActionReducerMap, createReducer, on } from "@ngrx/store";
import { CompanyAPISuccess, DeleteRedux, HeaderAPISuccess, invokeCustomerCompanyInfo, invokeUpdateBookAPI } from "./action";
export const initialState: ReadonlyArray<string> = [];
var user = JSON.parse(sessionStorage.getItem("digiUser"));

export const CompanyInfo = createReducer(

    initialState,
    on(HeaderAPISuccess, (state, { allData }) => { return [...state, allData]; }
    ),

    on(CompanyAPISuccess, (state, { allCompanyInfo }) => {
        // if (allCompanyInfo?.organizationId == user?.entityId) {
            return [...state, allCompanyInfo];
        // }
    }),

    on(invokeUpdateBookAPI, (state, { payload, user }) => {
        if (payload?.organizationId == user) {
            let newState:any;
            if(state.length > 0){
                let resData: any = JSON.parse(JSON.stringify(state[0]));
                newState = state.filter(_ => resData?.id !== payload?.id);
                newState.unshift(payload);
            }else{
                newState = payload
            }
            return newState;
        }
    }),

    on(DeleteRedux, (state, { payload }) => {
        let newState = [];
        return newState;
    }),

);

export const Header = createReducer(
    initialState,
    on(HeaderAPISuccess, (state,
        { allData }) => { return [...state, allData] }
    ),

)

export const CustomerCompanyInfo = createReducer(
    initialState,
    on(invokeCustomerCompanyInfo, (state, { customerPayload }) => {
        return [...state, customerPayload];
    }),

    on(DeleteRedux, (state, { payload }) => {
        let newState = [];
        return newState;
    }),

)

export const rootReducer: ActionReducerMap<any> = {
    companyInfo: CompanyInfo,
    Header: Header,
    customerCompanyInfo: CustomerCompanyInfo,
};



