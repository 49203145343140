import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';


@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html'
})
export class FormControlComponent implements OnInit {

  @Input() control:FormControl;
  @Input() ctrl:any;
  @Input() disable:any;

  @ViewChild('file') fileInput: ElementRef;

  constructor(private toastrService:ToastrService, private spinnerService: NgxSpinnerService,
    private httpService:HttpService) { }

  ngOnInit() {
    if(this.disable){
      this.control.disable();
    }
  }

  upload(){
    // this.control.patchValue("12345");
    // this.control.setValue("12345");

    this.fileInput.nativeElement.click();
  }

  uploadFile() {
    const fileBrowser = this.fileInput.nativeElement;

    if (fileBrowser.files && fileBrowser.files[0]) {
      if (fileBrowser.files[0].size > 10485760) {
        this.toastrService.error('File size shold be max 10MB', 'Error');
        return;
      }

      let data = new FormData();
      data.append('file', fileBrowser.files[0]);
      this.spinnerService.show();
      this.toastrService.success('File Uploaded');

      this.httpService.postData(PATH.FILE_UPLOAD, data).subscribe(
        (res) => {
          this.spinnerService.hide();
          
          this.control.setValue(res.fileName);
        },
        (err) => {
          this.spinnerService.hide();
          this.fileInput.nativeElement.value = '';
        }
      );
    }
  }

  download(val) {
    this.spinnerService.show();
    let type = this.checkDocumentType(val.value);
    this.httpService
      .download(PATH.FILE_UPLOAD + '?fileName=' + val.value)
      .subscribe((res) => {
        let file = new Blob([res], { type: type });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.spinnerService.hide();
      });
  }

  checkDocumentType(filename) {
    let fileType = filename.split('.').pop();
    if (fileType == 'jpeg' || fileType == 'jpg') {
      return 'image/jpeg';
    }
    if (fileType == 'pdf') {
      return 'application/pdf';
    }
    if (fileType == 'png') {
      return 'image/png';
    }
    if (fileType == 'gif') {
      return 'image/gif';
    }
  }

  numberOnly(event:any){  
    const regexpNumber = /\d/;
    let inputCharacter = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
      event.preventDefault();
    }
  }

}
