import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { AppCookieService } from 'src/app/services/cookieService';
import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsServiceService } from 'src/app/services/validators-service.service';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { email, noSpecialCharAllow, phoneNumberOnly } from 'src/app/services/custom.validations';
import { ConfirmationService } from 'primeng/api';
import { Store } from '@ngrx/store';
import { Selector } from 'src/app/redux/selector';
import { invokeUpdateBookAPI } from 'src/app/redux/action';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
})
export class UploadDocumentsComponent implements OnInit {
  user: any;
  disableBtn: boolean = false;
  uploaded: boolean;
  step: number;
  @Output('getCompanyInfo') callParent: EventEmitter<any> = new EventEmitter();
  submitted: boolean = false;
  documents = [
    {
      title: 'Incorporation Documents',
      name: 'certificateOfIncorporation',
    },
    {
      title: 'Memorandum & Articles of Association',
      name: 'memorandumArticlesOfAssociation',
    },
    {
      title: 'Financial Statements for last three years',
      name: 'financialStatementsLast3Years',
    },
    {
      title: 'Trading License',
      name: 'tradingLicense',
    },
    {
      title: 'Address Proof',
      name: 'addressApproval',
    }
    // {
    //   title: 'Insurance Certificate',
    //   name: 'insuranceCertificate',
    // },
  ];

  shareholders = [
    {
      title: 'Passport',
      name: 'passport',
    },
    {
      title: 'National ID Card',
      name: 'personalIdentification',
    },
  ];

  companyShareholders = [
    {
      title: 'Trading License',
      name: 'tradingLicense',
    },
  ];


  directors = [
    {
      title: 'Passport',
      name: 'passport',
    },
    {
      title: 'National ID Card',
      name: 'personalIdentification',
    },
  ];
  contracts = [
    {
      title: 'Upload Contract Docs',
      name: 'contractDocument',
      contractName: '',
      endDate: '',
      renewalDate: '',
      startDate: '',
    },
  ];


  companyInfo: any = {};
  type: any = '';
  @ViewChild('file') fileInput: ElementRef;
  fileDetails: any = {};
  reqType: string = 'save';
  date = new Date();
  isProfileRes: boolean = false;
  hasError: boolean = true;
  isDocumentUploaded: boolean = false;
  countryCode = 'in';
  
  constructor(
    private router: Router,
    private appCookieService: AppCookieService,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    public validators: ValidatorsServiceService,
    private formBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    public store: Store
  ) { }

  infoForm = this.formBuilder.group({
    keyContacts: this.formBuilder.array([this.addContractGroup(null)]),
  })

  ngOnInit(): void {
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.getCompanyInfo();
    this.checkProfile();
    
  }

  getCompanyInfo() {
    this.store.select(Selector).subscribe((res: any) => {
      let companyInfo = res?.companyInfo;

      if(res?.companyInfo?.id || res?.companyInfo?.length > 0){
        let resData:any;
        if(res?.companyInfo?.id){
          resData = JSON.parse(JSON.stringify(res.companyInfo));
        }
        if(res?.companyInfo?.length > 0){
          resData = JSON.parse(JSON.stringify(res.companyInfo[0]));
        }
        let branches = [];
        if (resData.branchInOtherCountry) {
          resData.branchInOtherCountry?.map((o) => {
            branches.push(o);
          });
          resData.branchInOtherCountry = branches;
        }
        this.companyInfo = resData;
        this.step = this.companyInfo.step;
        if (!this.companyInfo.entityType) {
          if (this.user.entityType) {
            this.companyInfo.entityType = this.user.entityType
          }
        }
        const keyContacts = new UntypedFormArray([]);
        if (resData.keyContacts?.length) {
          this.addContract();
        }
        resData.keyContacts?.forEach((data) => {
          if (data.endDate) {
            data.endDate = new Date(moment(data.endDate).format('yyyy-MM-DD'));
          }
          if (data.renewalDate) {
            data.renewalDate = new Date(moment(data.renewalDate).format('yyyy-MM-DD'));
          }
          if (data.startDate) {
            data.startDate = new Date(moment(data.startDate).format('yyyy-MM-DD'));
          }
          keyContacts.push(this.addContractGroup(data));
        });
        this.infoForm.setControl('keyContacts', keyContacts);
        if (keyContacts.length == 0) {
          this.addContract();
        }

        if (this.companyInfo.status == 'Submitted') {
          this.disableBtn = true;
        }
        this.infoForm.updateValueAndValidity();
      }
    },
      (error) => {
        this.spinnerService.hide();
      }
    );
  }
  upload(type, name, index) {
    this.isDocumentUploaded = true;
    this.fileDetails = { type: type, name: name, index: index };
    let i = this.documents.findIndex((d) => {
      return d.name == name;
    });
    this.fileInput.nativeElement.click();
  }
  addContract() {
    (<UntypedFormArray>this.infoForm.get('keyContacts')).push(
      this.addContractGroup(null)
    );
  }
  deleteContract(index) {
    (<UntypedFormArray>this.infoForm.get('keyContacts')).removeAt(index);
  }

  emailChange() {
  }

  addContractGroup(data): UntypedFormGroup {
    return this.formBuilder.group({
      emailId: [data ? data.emailId : '', [email]],
      name: [data ? data.name : ''],
      phoneNumber: [data ? data.phoneNumber : '', [noSpecialCharAllow, phoneNumberOnly]]
    });
  }

  uploadFile() {
    const fileBrowser = this.fileInput.nativeElement;

    if (fileBrowser.files && fileBrowser.files[0]) {
      if (fileBrowser.files[0].size > 10485760) {
        this.toastrService.error('File size should be max 10MB', 'Error');
        return;
      }
      if (fileBrowser.files[0].type != 'application/pdf') {
        this.toastrService.error('Only Pdf Allowed', 'Error');
        return;
      }

      let data = new FormData();
      data.append('file', fileBrowser.files[0]);
      this.spinnerService.show();

      this.httpService.postData(PATH.FILE_UPLOAD, data).subscribe(
        (res) => {
          if (this.fileDetails.type == 'doc') {
            this.companyInfo[this.fileDetails.name] = res.fileName;
          } else if (this.fileDetails.type == 'shareholder') {
            this.companyInfo.shareHolders[this.fileDetails.index][
              this.fileDetails.name
            ] = res.fileName;
          } else if (this.fileDetails.type == 'director') {
            this.companyInfo.boardOfDirectors[this.fileDetails.index][
              this.fileDetails.name
            ] = res.fileName;
          } else if (this.fileDetails.type == 'contract') {
            this.infoForm.get('keyContacts')['controls'][this.fileDetails.index].value[this.fileDetails.name] = res.fileName
          }
          this.reqType = 'Submit';
          this.updateCompanyInfo('company');
          this.fileInput.nativeElement.value = '';
          this.toastrService.success("File uploaded Successfully")
          this.spinnerService.hide();
        },
        (err) => {
          this.spinnerService.hide();

          this.toastrService.error(err.message);
          this.fileInput.nativeElement.value = '';
        }
      );
    }
  }
  convertDate(dateString) {
    let dateParts: Array<any> = this.ngbDateParserFormatter
      .format(dateString)
      .split('/');
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }
  updateCompanyInfo(val) {
    this.infoForm.markAllAsTouched();
    if (!this.infoForm.valid) {
      return;
    }
    this.companyInfo.step = this.step;
    if (this.companyInfo.step <= 3) {
      this.companyInfo.step = 3;
    }

    this.companyInfo.keyContacts = this.infoForm.get('keyContacts').value;
    this.companyInfo.keyContacts.map((o) => {
      o.emailId = o.emailId.toLowerCase();
    });
    this.spinnerService.show();
    if (this.reqType == 'submit') {
      this.companyInfo.status = 'Submitted';
    }
    if (val == 'company') {
      this.httpService.updateData(PATH.COMPANY_INFORMATION, this.companyInfo).subscribe(
        (res) => {
          if (res) {
            this.store.dispatch(invokeUpdateBookAPI({ payload: { ...res }, user:this.user.entityId }));
            this.validateDocs();
            this.companyInfo = res;
            this.spinnerService.hide();
            this.getCompanyInfo();
            // if (this.reqType == 'save') {
            //   this.toastrService.success('Company information updated successfully');
            // }
          }

        },
        (error) => {
          this.spinnerService.hide();

        }
      );
    }

    if (val == 'profile') {
      this.companyInfo.keyContacts = this.infoForm.get('keyContacts').value;
      this.companyInfo.keyContacts.map((o) => {
        o.emailId = o.emailId.toLowerCase();
      });
      this.spinnerService.show();
      if (this.reqType == 'submit') {
        this.companyInfo.status = 'Submitted';
      }
      this.httpService.updateData(PATH.COMPANY_INFORMATION + '/profile', this.companyInfo).subscribe(
        (res) => {
          if (res) {
            this.validateDocs();
            this.companyInfo = res;
            this.spinnerService.hide();
            this.getCompanyInfo();
            if (this.reqType == 'save') {
              this.toastrService.success('Profile updated successfully');
            }
          }

        },
        (error) => {
          this.spinnerService.hide();

        }
      );
    }
  }
  deleteFile(type, name, index, filename) {
    this.httpService
      .deleteData(PATH.DELETE_FILE + filename)
      .subscribe((res) => {
        this.toastrService.success('File Deleted Successfully!');
        if (type == 'doc') {
          this.companyInfo[name] = null;
        } else if (type == 'shareholder') {
          this.companyInfo.shareHolders[index][name] = null;
        } else if (type == 'director') {
          this.companyInfo.boardOfDirectors[index][name] = null;
        }
        this.updateCompanyInfo('company');
      });
  }

  next(type) {
    this.submitted = true;
    this.reqType = type;
    if (!this.hasError) {
      this.toastrService.error('Phone number is not valid');
      return
    }
    if (this.validateDocs()) {
      if (this.companyInfo.step <= 3) {
        this.companyInfo.step = 3;
      }
      this.confirmationService.confirm({
        message: "Do you want to update your profile",
        accept: () => {
          this.updateCompanyInfo('profile');
          this.updateCompanyInfo('company');
          if (type == 'save') {
            this.router.navigate(['/vendor/vendor-info/3']);
            this.callParent.emit({
              step: 3,
              url: '/vendor/vendor-info/3',
              progressStep: this.companyInfo.step
            });
          }
        }, reject: () => {
          this.updateCompanyInfo('company');
          if (type == 'save') {
            this.router.navigate(['/vendor/vendor-info/3']);
            this.callParent.emit({
              step: 3,
              url: '/vendor/vendor-info/3',
              progressStep: this.companyInfo.step
            });
          }
        }
      })


    } else {
      if (!this.infoForm.valid) {
        this.toastrService.error('Please validate contracts form', 'Error');
      } else {
        this.toastrService.error('Please Upload All Required Documents', 'Error');
      }
    }
  }

  skip() {
    this.router.navigate(['/vendor/vendor-info/3']);
  }

  validateDocs() {
    if (this.submitted) {
      let invalidElements = [];
      let validElements = [];
      // this.documents.map((o) => {
      //   if (o.name == 'certificateOfIncorporation' || o.name == 'tradingLicense' || o.name == 'addressApproval') {
      //     if (!this.companyInfo[o.name]) {
      //       invalidElements.push(document.getElementById(o.name));
      //     } else {
      //       validElements.push(document.getElementById(o.name));
      //     }
      //   }
      // });
      this.infoForm.get('keyContacts')['controls'].map((o, index) => {
        if (o.status != 'VALID') {
          invalidElements.push(document.getElementById(index + 'key'));
        } else {
          validElements.push(document.getElementById(index + 'key'));
        }
      })

      validElements.map((o) => {
        o.classList.remove('b-red');
      });
      if (invalidElements.length) {
        invalidElements.map((o) => {
          o.classList.add('b-red');
        });
        invalidElements[0].scrollIntoView();
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  download(filename) {
    if (filename) {
      this.spinnerService.show();
      let type = this.checkDocumentType(filename);
      this.httpService.download(PATH.FILE_UPLOAD + '?fileName=' + filename).subscribe((res) => {
        this.spinnerService.hide();
        let file = new Blob([res], { type: type });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
    } else {
      this.toastrService.error('Please Upload Document', 'Error');
    }
  }

  goNext() {
    this.router.navigate(['vendor/vendor-info/3']);
    this.callParent.emit({
      step: this.companyInfo.step,
      url: '/vendor/vendor-info/3',
      progressStep: this.companyInfo.step
    });
  }

  checkDocumentType(filename) {
    let fileType = filename.split('.').pop();
    if (fileType == 'jpeg') {
      return 'image/jpeg';
    }
    if (fileType == 'pdf') {
      return 'application/pdf';
    }
    if (fileType == 'png') {
      return 'image/png';
    }
    if (fileType == 'gif') {
      return 'image/gif';
    }
  }

  back() {
    this.isDocumentUploaded = false;
    this.router.navigate(['/vendor/vendor-info/1']);
    this.callParent.emit({
      step: 1,
      url: '/vendor/vendor-info/1',
      progressStep: this.companyInfo.step
    });
  }

  importProfile() {
    this.spinnerService.show();
    this.httpService
      .getData(PATH.COMPANY_INFORMATION + '/profile')
      .subscribe(
        (res: any) => {
          if (!res) {
            this.spinnerService.hide();
            return;
          }
          delete res.step
          let branches = [];
          if (res.branchInOtherCountry) {
            res.branchInOtherCountry?.map((o) => {
              branches.push(o);
            });
            res.branchInOtherCountry = branches;
          }
          this.spinnerService.hide();
          this.companyInfo = res;
          delete res.step
          const keyContacts = new UntypedFormArray([]);
          res.keyContacts?.forEach((data) => {
            keyContacts.push(this.addContractGroup(data));
          });
          this.infoForm.setControl('keyContacts', keyContacts);

          if (this.companyInfo.status == 'Submitted') {
            this.disableBtn = true;
          }
          if (!this.companyInfo.entityType) {
            if (this.user.entityType) {
              this.companyInfo.entityType = this.user.entityType
            }
          }
          this.toastrService.success(
            'Data imported successfully for Step 1 & 2'
          );
        },
        (error) => {
          this.spinnerService.hide();
        }
      );
  }

  onError(obj, itm) {
    this.hasError = obj;
    itm.hasError = obj
  }

  checkProfile() {
    this.httpService.getData(PATH.COMPANY_INFORMATION + '/profile').subscribe(
      (res: any) => {
        if (res) {
          this.isProfileRes = true;
        }
      })
  }

  numberOnly(event: any) {
    const regexpNumber = /\d/;
    let inputCharacter = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
      event.preventDefault();
    }
  }

  keypress(ev){
    
    
    if(ev.target.value){
      this.isDocumentUploaded=true;
    }else{
      this.isDocumentUploaded=false;
    }
   
    
  }

}
