import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { forkJoin } from 'rxjs';
import { PATH } from 'src/app/app.constant';
import { noExtraWhiteSpace, numericOnly } from 'src/app/services/custom.validations';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-vendor-info-sec-risk-form',
  templateUrl: './vendor-risk-assessment-form.component.html',
  styleUrls: ['./vendor-risk-assessment-form.component.scss']
})
export class VendorRiskAssessmentFormComponent implements OnInit {

  private stepper: Stepper;
  step: number = 1;
  page = 1;
  size = 10;
  meta: any;
  data: any = [];
  isMetaData: boolean = false;
  form: any[];
  parentForm: FormGroup;
  infoSecRiskAnswer: any;
  infosecId;
  infoSecDetails;
  answerList: any;

  constructor(private router: Router,
    private httpService: HttpService,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private activateRoute: ActivatedRoute,
    public dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.activateRoute.params.subscribe((res) => {
      this.infosecId = res.id;
    })
    this.getInfoSecById();
    this.getInfoSecRisk();
    let stepper = document.querySelector('.bs-stepper');
    this.stepper = new Stepper(stepper, {
      linear: true,
      animation: true,
    });
    this.disbleBtns();

  }

  backbtn() {
    this.router.navigate(['vendor/info-sec-risk']);
  }

  getInfoSecRisk() {
    this.spinnerService.show();
    const riskAnswer = this.httpService.getData(`${PATH.INFO_SEC_RISK}/settings/vendor-ques?page=${1}&size=${1000}`);
    const riskForm = this.httpService.getData(`${PATH.INFO_SEC_RISK}/settings?page=${1}&size=${1000}`);

    forkJoin([riskAnswer, riskForm]).subscribe((res: any) => {
      this.answerList = res[0]['content'];
      let resData = res[0]['content'];
      let allAnsList: any = [];
      resData.forEach(elm => {
        elm.question.forEach(el => {
          allAnsList.push(el)
        })
      })

      let map = new Map();
      allAnsList.forEach(elm => {
        map.set(elm.questionId, elm.answer)
      })

      const mapToObj = m => {
        return Array.from(m).reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
      };

      this.infoSecRiskAnswer = JSON.stringify(mapToObj(map));
      this.infoSecRiskAnswer = JSON.parse(this.infoSecRiskAnswer);

      this.meta = res[1]['content'];
      this.form = this.processForm();
      this.isMetaData = true;

    })
  }

  pages(step) {
    if(this.infoSecDetails?.status=='COMPLETED' || this.infoSecDetails?.status=='SUBMITTED'){
      if (step) {
        if (step == 1) {
          this.stepper.to(1);
        } else if (step == 2) {
          this.stepper.to(2);
        } else {
          this.stepper.to(3);
        }
      }
      this.disbleBtns();
    }
  }

  backBtn() {
    let currentStep: any = this.stepper;
    if (currentStep._currentIndex == 0) {
      this.router.navigate(['/vendor', 'info-sec-risk']);
    } else {
      this.stepper.previous();
    }
  }

  next(section: any, step) {
    if (step == 'step1') {
      let isFormValidSection1: boolean = false;
      let isAnswer: boolean = false;
      section.forEach((elm) => {
        if (elm.label == 'GENERAL COMPANY INFORMATION') {
          isAnswer = this.getAnswerSubsection(elm);
          isFormValidSection1 = this.checkFormGeneralComp(elm);
        }
      });
      if (isFormValidSection1 && isAnswer) {
        this.stepper.next();
      } else {
        this.toastrService.error('Please Save all Sections!')
      }
    }
    if (step == 'step2') {
      let isFormValidSection2: boolean = false;
      let isAnswer: boolean = false;
      section.forEach((elm) => {
        if (elm.label == 'INFORMATION SECURITY') {
          isAnswer = this.getAnswerSubsection(elm);
          isFormValidSection2 = this.checkFormSecurity(elm);
        }
      });
      if (isFormValidSection2 && isAnswer) {
        this.stepper.next();
      } else {
        this.toastrService.error('Please Save all Sections!')
      }
    }
  }

  back() {
    this.stepper.previous();
  }

  onSubmit(section: any=[], step) {
    let isFormValidSection3: boolean = false;
    let isAnswer: boolean = false;
    section.forEach((elm) => {
      if (elm.label == 'PRIVACY COMPLIANCE') {
        isAnswer = this.getAnswerSubsection(elm);
        isFormValidSection3 = this.checkFormPrivacyComp(elm);
      }
    })
    if (isFormValidSection3 && isAnswer) {
      this.spinnerService.show();
      this.httpService.patchData(`${PATH.INFO_SEC_RISK}/${this.infosecId}`, {}).subscribe((res: any) => {
        this.toastrService.success('Information Security Risk Submitted Successfully');
        this.spinnerService.hide();
        this.router.navigate(['/vendor', 'info-sec-risk']);
      })
    } else {
      this.toastrService.error('Please Save all Sections!')
    }
  }

  getInfoSecById() {
    this.httpService.getData(`${PATH.INFO_SEC_RISK}/${this.infosecId}`).subscribe((res: any) => {
      this.infoSecDetails = res;

    })
  }

  disbleBtns() {
    document.getElementById("generalCompanyInformation-part-trigger").removeAttribute("disabled");
    document.getElementById("security-part-trigger").removeAttribute("disabled");
    document.getElementById("privacyCompliance-part-trigger").removeAttribute("disabled");
  }

  processForm() {
    const topLevelGroup = this.groupBy(this.meta, 'section');
    const form = [];
    this.parentForm = new FormGroup({});

    Object.keys(topLevelGroup).forEach((key) => {
      const topLevelEntries = topLevelGroup[key];
      const subLevelGroup = this.groupBy(topLevelEntries, 'subSection');
      const topObj = { label: key, children: [] };

      Object.keys(subLevelGroup).forEach((subKey) => {
        const subEntries = subLevelGroup[subKey];

        const subObj = {
          label: subKey,
          form: new FormGroup({}),
          children: subEntries.filter((e) => !e.parentQuestionId),
          parentGroup: this.groupBy(
            subEntries.filter((e) => e.parentQuestionId),
            'parentQuestionId'
          ),
        };

        subEntries.forEach((cur) => {
          let isNumber = cur.type == 'TEXT' ? true : false;
          subObj.form.addControl(
            cur.id,
            new FormControl(this.infoSecRiskAnswer[cur.id] || '', this.setValidator(cur))
          );
        });

        // for ParentForm
        this.parentForm.addControl(subKey, subObj.form);

        topObj.children.push(subObj);
      });
      form.push(topObj);
    });

    return form;
  }

  private groupBy(data: any[], key: string) {
    if (!data || !data.length) {
      return {};
    }
    const result = data.reduce((acc, cur) => {
      if (acc[cur[key]]) {
        acc[cur[key]].push(cur);
      } else {
        acc[cur[key]] = [cur];
      }
      return acc;
    }, {});
    return result;
  }

  setValidator(cur) {
    let validator = [];
    if (!cur.parentQuestionId && cur.mandatory) {
      validator.push(Validators.required)
    }
    if (cur.type == 'NUMBER') {
      validator.push(numericOnly)
    }
    if (cur.type == 'TEXT') {
      validator.push(noExtraWhiteSpace, Validators.maxLength(150))
    }
    return validator
  }

  getAnswerSubsection(section) {
    let answers: any = [];
    let questions: any = [];
    if (this.answerList) {
      this.answerList.forEach((elm) => {
        answers.push(elm.subSection)
      })
    }
    if (section.children) {
      section.children.forEach((elm) => {
        questions.push(elm.label)
      })
    }
    const found = questions.every(r => answers.includes(r))
    return found
  }

  checkFormGeneralComp(section) {
    let isLegalForm: boolean = true;
    let isInsuranceForm: boolean = true;
    let isBusinessForm: boolean = true;
    section.children.forEach(el => {
      let isLegal = el.label == 'Legal Entity Information' ? true : false;
      let isInsurance = el.label == 'Insurance Information' ? true : false;
      let isBusiness = el.label == 'Business Contact Information' ? true : false;
      if (isLegal) {
        isLegalForm = !el.form.invalid;
      }
      if (isInsurance) {
        isInsuranceForm = !el.form.invalid;
      }
      if (isBusiness) {
        isBusinessForm = !el.form.invalid;
      }
    });
    if (isLegalForm && isInsuranceForm && isBusinessForm) {
      return true
    } else {
      return false
    }
  }

  checkFormSecurity(section) {
    let isSubsec1Form: boolean = true;
    let isSubsec2Form: boolean = true;
    let isSubsec3Form: boolean = true;
    let isSubsec4Form: boolean = true;
    let isSubsec5Form: boolean = true;
    let isSubsec6Form: boolean = true;
    let isSubsec7Form: boolean = true;
    let isSubsec8Form: boolean = true;
    let isSubsec9Form: boolean = true;
    let isSubsec10Form: boolean = true;
    let isSubsec11Form: boolean = true;
    section.children.forEach(el => {
      let isSubsec1 = el.label == 'Environment Understanding' ? true : false;
      let isSubsec2 = el.label == 'Risk Assessment and Treatment' ? true : false;
      let isSubsec3 = el.label == 'Information Security' ? true : false;
      let isSubsec4 = el.label == 'Organizational Security' ? true : false;
      let isSubsec5 = el.label == 'Asset Management or Vulnerability Management' ? true : false;
      let isSubsec6 = el.label == 'Human Resource Security' ? true : false;
      let isSubsec7 = el.label == 'Physical and Environmental Security' ? true : false;
      let isSubsec8 = el.label == 'Communications and Operations Management' ? true : false;
      let isSubsec9 = el.label == 'Access Control' ? true : false;
      let isSubsec10 = el.label == 'Incident Event and Communications Management' ? true : false;
      let isSubsec11 = el.label == 'Business Continuity and Disaster Recovery' ? true : false;
      if (isSubsec1) {
        isSubsec1Form = !el.form.invalid;
      }
      if (isSubsec2) {
        isSubsec2Form = !el.form.invalid;
      }
      if (isSubsec3) {
        isSubsec3Form = !el.form.invalid;
      }
      if (isSubsec4) {
        isSubsec4Form = !el.form.invalid;
      }
      if (isSubsec5) {
        isSubsec5Form = !el.form.invalid;
      }
      if (isSubsec6) {
        isSubsec6Form = !el.form.invalid;
      }
      if (isSubsec7) {
        isSubsec7Form = !el.form.invalid;
      }
      if (isSubsec8) {
        isSubsec8Form = !el.form.invalid;
      }
      if (isSubsec9) {
        isSubsec9Form = !el.form.invalid;
      }
      if (isSubsec10) {
        isSubsec10Form = !el.form.invalid;
      }
      if (isSubsec11) {
        isSubsec11Form = !el.form.invalid;
      }
    });
    if (isSubsec1Form && isSubsec2Form && isSubsec3Form && isSubsec4Form && isSubsec5Form && isSubsec6Form && isSubsec7Form && isSubsec8Form && isSubsec9Form && isSubsec10Form && isSubsec11Form) {
      return true
    } else {
      return false
    }
  }

  checkFormPrivacyComp(section) {
    let isSubsec1Form: boolean = true;
    let isSubsec2Form: boolean = true;
    let isSubsec3Form: boolean = true;
    let isSubsec4Form: boolean = true;
    let isSubsec5Form: boolean = true;
    section.children.forEach(el => {
      let isSubsec1 = el.label == 'Your Privacy Program' ? true : false;
      let isSubsec2 = el.label == 'The personal data processed' ? true : false;
      let isSubsec3 = el.label == 'Your company' ? true : false;
      let isSubsec4 = el.label == 'Subcontractors' ? true : false;
      let isSubsec5 = el.label == 'Security' ? true : false;
      if (isSubsec1) {
        isSubsec1Form = !el.form.invalid;
      }
      if (isSubsec2) {
        isSubsec2Form = !el.form.invalid;
      }
      if (isSubsec3) {
        isSubsec3Form = !el.form.invalid;
      }
      if (isSubsec4) {
        isSubsec4Form = !el.form.invalid;
      }
      if (isSubsec5) {
        isSubsec5Form = !el.form.invalid;
      }
    });
    if (isSubsec1Form && isSubsec2Form && isSubsec3Form && isSubsec4Form && isSubsec5Form) {
      return true
    } else {
      return false
    }
  }

  dynamicFormRes(event) {
    if (event) {
      this.getInfoSecRisk();
    }
  }

}
