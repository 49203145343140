import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { ActivitiesComponent } from '../activities/activities.component';
import { CompanyInformationFormComponent } from '../company-information-form/company-information-form.component';
import { CompanyInfoQuestionnaireComponent } from '../company-info-questionnaire/company-info-questionnaire.component';
import { CompanyInfoRegulationsComponent } from '../company-info-regulations/company-info-regulations.component';
import { UploadDocumentsComponent } from '../upload-documents/upload-documents.component';
import { HttpService } from 'src/app/services/http.service';
import { PATH } from 'src/app/app.constant';
import { ToastrService } from 'ngx-toastr';
import { AppCookieService } from 'src/app/services/cookieService';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyInfoEsgComponent } from '../company-info-esg/company-info-esg.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessDialogComponent } from 'src/app/components';
import { invokeCompanyAPI } from 'src/app/redux/action';
import { Selector } from 'src/app/redux/selector';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.scss'],
})
export class VendorRegistrationComponent implements OnInit {
  private stepper: Stepper;
  step = 0;
  progressStep=0;
  percent = this.progressStep * 16.66;
  notes;
  responseRFI: Array<any> = [];
  notesRes: Array<any> = [];
  RfiShow:boolean= false;
  count:number=0;
  resId:any;
  paramStep:any=0;
  @ViewChild(UploadDocumentsComponent) upload: UploadDocumentsComponent;
  @ViewChild(CompanyInformationFormComponent) companyInfo: CompanyInformationFormComponent;
  @ViewChild(ActivitiesComponent) activities: ActivitiesComponent;
  @ViewChild(CompanyInfoQuestionnaireComponent) questions: CompanyInfoQuestionnaireComponent;
  @ViewChild(CompanyInfoEsgComponent) esg: CompanyInfoEsgComponent;
  @ViewChild(CompanyInfoRegulationsComponent) regulations: CompanyInfoRegulationsComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private appCookieService: AppCookieService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    public store: Store
  ) {}

  ngOnInit(): void {
    let stepper = document.querySelector('.stepper');
    this.stepper = new Stepper(stepper, {
      linear: true,
      animation: true,
    });
    this.route.params.subscribe((params) => {
      if (params.step) {
        this.paramStep=params.step;
        if (params.step >= 6) {
          this.router.navigate(['/vendor/dashboard']);
        } else {
          this.stepper.to(params.step);
        }
      }
    });
    this.getDefaultCompanyInfo();
    this.getNotes();
  }

  stepperMove(data) {
    if (data.step == 1) {
      this.stepper.to(1);
      this.companyInfo.getCompanyInfo();
      this.activities.getActivities();
    } else if (data.step == 2) {
      this.stepper.to(2);
      this.upload.getCompanyInfo();
      this.activities.getActivities();
      if(this.count<1){
        let modelRef = this.modalService.open(SuccessDialogComponent, {
          ariaLabelledBy: "modal-basic-title",
          windowClass: "center",
        })
        modelRef.componentInstance.type = 'upload-document';
      }
      this.count=this.count+1;
    } else if (data.step == 3) {
      this.stepper.to(3);
      this.questions.getCompanyInfo();
      this.activities.getActivities();
    } else if (data.step == 4) {
      this.stepper.to(4);
      this.esg.getCompanyInfo();
      this.activities.getActivities();
    }else {
      this.stepper.to(5);
      this.regulations.getCompanyInfo();
      this.activities.getActivities();
    }
  }

  getCompanyInfo(data) {
    this.step = data.step;
    this.getRFI();
    this.stepperMove(data);
    this.activities.getActivities();
    this.progressStep=data.progressStep;
    this.percent = (this.progressStep ? (this.progressStep-1)*20 : 0);
  }

  getDefaultCompanyInfo() {
    // this.store.dispatch(invokeCompanyAPI());
    this.store.select(Selector).subscribe((res: any) => {
      if(res?.companyInfo?.length > 0){
        let resData = res.companyInfo[0];
        if(res.status == 'REJECTED'){
        }
        this.resId=resData.id;
        this.getRFI();
        this.step = resData.step;
        this.progressStep=resData.step;
        this.percent = (this.progressStep ? (this.progressStep-1)*20 : 0)
      }
    },(error) => {
      this.spinnerService.hide();
    });

    // let user = JSON.parse(this.appCookieService.get('digiUser'));
    // this.spinnerService.show();
    // this.httpService.getData(PATH.COMPANY_INFORMATION).subscribe((res: any) => {
    //       this.spinnerService.hide();
    //       if(res.status == 'REJECTED'){
    //       }
    //       this.resId=res.id;
    //       this.getRFI();
    //       this.step = res.step;
    //       this.progressStep=res.step;
    //       this.percent = (this.progressStep ? (this.progressStep-1)*20 : 0)
    //     },
    //     (error) => {
    //       this.spinnerService.hide();
    //     }
    //   );
  }

  getRFI() {
    this.RfiShow=false;
    this.spinnerService.show();
    let step=this.step
    if(step==0){
      step=1;
    }
    this.httpService.getData(PATH.RFI+'/findByVendorInformationId/'+this.resId+'/'+step).subscribe((res: any) => {
      this.responseRFI = res;
      if(res.length>0){
        this.RfiShow=true;
      }
      this.spinnerService.hide();
    },
      (error) => {
        this.RfiShow=false;
        this.spinnerService.hide();
        this.toastrService.error(error.message?.error);
      }
    )
  }

  addNote() {
    this.spinnerService.show();
    let data = { notesText: this.notes };
    this.httpService.postData(PATH.NOTES_SUBMIT,data).subscribe((res) => {
        this.toastrService.success('Notes Added Successfully!');
        this.getNotes();
        this.notes = '';
        this.spinnerService.hide();

      },(error) => {
        this.spinnerService.hide();
        this.toastrService.error(error.message?.error);
      }
    );
  }

  getNotes() {
    this.httpService.getData(PATH.GET_SUBMIT).subscribe(
      (res: any) => {
        this.notesRes = res;
      },
      (error) => {}
    );
  }

}
