import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PATH } from 'src/app/app.constant';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SuccessDialogComponent } from 'src/app/components/success-dialog/success-dialog.component';
import { email, noExtraWhiteSpace, noSpecialCharAllow, onlyCharacters, phoneNumberOnly } from 'src/app/services/custom.validations';
import { ValidatorsServiceService } from 'src/app/services/validators-service.service';
import { AppCookieService } from 'src/app/services/cookieService';

@Component({
  selector: 'app-add-vendor-user-managment',
  templateUrl: './add-vendor-user-managment.component.html',
  styleUrls: ['./add-vendor-user-managment.component.scss']
})
export class AddVendorUserManagmentComponent implements OnInit {

  checked: boolean = false;
  userForm: FormGroup;
  entityId: any;
  userName: any;
  user: any;
  countryCode = 'in';
  countries: any;
  hasError: boolean = false;
  inputOptions = { initialCountry: this.countryCode, separateDialCode: true };
  formSubmitAttempt: boolean = false;
  type: string;
  editMode: boolean = false;
  userId;
  roles: Array<any> = [];
  departments: Array<any> = [];
  isCheckboxChange: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    public validators: ValidatorsServiceService,
    private appCookieService: AppCookieService) { }



  ngOnInit(): void {
    this.grtCountriesList();
    this.user = JSON.parse(this.appCookieService.get('digiUser'));
    this.entityId = this.user.entityId;
    this.prepareUserForm();
    this.route.params.subscribe((res) => {
      if (res.id) {
        this.editMode = true;
        this.getData(res);
      }
      if (res.type) {
        this.type = res.type;
      }
    })
  }

  prepareUserForm() {
    this.userForm = this.formBuilder.group({
      firstName: ["", [Validators.required, noSpecialCharAllow, Validators.minLength(2), Validators.maxLength(50), onlyCharacters, noExtraWhiteSpace]],
      email: ["", [Validators.required, email, noExtraWhiteSpace, Validators.maxLength(80)]],
      mobileNumber: ['', [Validators.required, phoneNumberOnly]],
      lastName: ['', [Validators.required, noSpecialCharAllow, Validators.minLength(2), Validators.maxLength(50), onlyCharacters, noExtraWhiteSpace]],
      eroleSet: this.formBuilder.array([], [Validators.required]),
      departments: this.formBuilder.array([], [Validators.required]),
      locations: [[], [Validators.required]],
      entityId: [''],
      entityName: [''],
      entityType: ['']
    })

    this.roles = [
      { label: 'Admin', value: 'ROLE_VENDOR_ADMIN', check: '' },
      { label: 'Non Admin', value: 'ROLE_VENDOR_NONADMIN', check: '' }
    ];

    this.departments = [
      { label: 'All', value: 'All', check: '' },
      { label: 'Administration', value: 'Administration', check: '' },
      { label: 'Finance', value: 'Finance', check: '' },
      { label: 'Management', value: 'Management', check: '' },
      { label: 'Human Resource', value: 'Human Resource', check: '' },
      { label: 'Marketing', value: 'Marketing', check: '' },
      { label: 'Sales', value: 'Sales', check: '' }
    ];
  }

  get f() {
    return this.userForm.controls;
  }

  getData(res) {
    this.onError(true);
    this.spinnerService.show();
    this.httpService.getData(PATH.GET_USER + '/' + res.id + '/' + this.entityId).subscribe((res: any) => {
      this.userName = res.username
      if (res.eroleSet) {
        res.eroleSet.forEach(element => {
          this.editCheckBox(element, 'roles');
        });
      }
      if (res.departments) {
        res.departments.forEach(element => {
          this.editCheckBox(element, 'departments');
        });
        if (res.departments.length >= 5) {
          for (let i = 0; i < this.departments.length; i++) {
            if (this.departments[i].value == 'All') {
              this.departments[i].check = true;
            }
          }
        }
      }
      this.userId = res.email;
      this.userForm.controls['email'].disable();
      this.userForm.patchValue(res);
      this.countryCode = res.countryCode;
      this.spinnerService.hide();
    })
  }

  editCheckBox(e, type) {
    if (type == 'roles') {
      this.roles.forEach(element => {
        if (element.value == e) {
          element.check = true;
        }
      });
      const checkArray: UntypedFormArray = this.userForm.get('eroleSet') as UntypedFormArray;
      checkArray.push(new UntypedFormControl(e));
    }

    if (type == 'departments') {
      this.departments.forEach(element => {
        if (element.value == e) {
          element.check = true;
        }
      });
      const checkArray: UntypedFormArray = this.userForm.get('departments') as UntypedFormArray;
      checkArray.push(new UntypedFormControl(e));
    }
  }

  onAccessChange(e) {
    this.isCheckboxChange = true;
    const checkArray: UntypedFormArray = this.userForm.get('eroleSet') as UntypedFormArray;
    if (e.target.checked) {
      checkArray.push(new UntypedFormControl(e.target.value));
      for (let i = 0; i < checkArray.length; i++) {
        if (e.target.value != checkArray.value[i]) {
          checkArray.removeAt(i);
        }
      }
      this.roles = [
        { label: 'Admin', value: 'ROLE_VENDOR_ADMIN', check: e.target.value == 'ROLE_VENDOR_ADMIN' ? true : false },
        { label: 'Non Admin', value: 'ROLE_VENDOR_NONADMIN', check: e.target.value == 'ROLE_VENDOR_NONADMIN' ? true : false }
      ]
    }
    else {
      if (e.target.value == 'All') {
        this.uncheckedAll('access');
      }
      else {
        let i: number = 0;
        checkArray.controls.forEach((item: UntypedFormControl) => {
          if (item.value == e.target.value) {
            checkArray.removeAt(i);
            for (let j = 0; j < this.roles.length; j++) {
              if (this.roles[j].value == 'All') {
                this.roles[j].check = false;
              }
            }
            return;

          }
          i++;
        });
      }
    }
  }
  
  onDepartmentsChange(e) {
    this.isCheckboxChange = true;
    const checkArray: UntypedFormArray = this.userForm.get('departments') as UntypedFormArray;
    if (e.target.checked) {
      if (e.target.value == 'All') {
        this.checkedAll('departments');
      }
      else {
        checkArray.push(new UntypedFormControl(e.target.value));
      }
    }
    else {
      if (e.target.value == 'All') {
        this.uncheckedAll('departments');
      }
      else {
        let i: number = 0;
        checkArray.controls.forEach((item: UntypedFormControl) => {
          if (item.value == e.target.value) {
            checkArray.removeAt(i);
            for (let j = 0; j < this.departments.length; j++) {
              if (this.departments[j].value == 'All') {
                this.departments[j].check = false;
              }
            }
            return;

          }
          i++;
        });
      }
    }
  }


  checkedAll(type) {
    if (type == 'access') {
      const checkArray: UntypedFormArray = this.userForm.get('eroleSet') as UntypedFormArray;
      checkArray.controls = [];
      checkArray.setValue([]);
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].value != 'All') {
          checkArray.push(new UntypedFormControl(this.roles[i].value));
        }
        this.roles[i].check = true;
      }
    }
    if (type == 'departments') {
      const checkArray: UntypedFormArray = this.userForm.get('departments') as UntypedFormArray;
      checkArray.controls = [];
      checkArray.setValue([]);
      for (let i = 0; i < this.departments.length; i++) {
        if (this.departments[i].value != 'All') {
          checkArray.push(new UntypedFormControl(this.departments[i].value));
        }
        this.departments[i].check = true;
      }
    }
  }

  uncheckedAll(type) {
    if (type == 'access') {
      const checkArray: UntypedFormArray = this.userForm.get('eroleSet') as UntypedFormArray;
      checkArray.controls = [];
      checkArray.setValue([]);
      for (let i = 0; i < this.roles.length; i++) {
        this.roles[i].check = false;
      }
    }
    if (type == 'departments') {
      const checkArray: UntypedFormArray = this.userForm.get('departments') as UntypedFormArray;
      checkArray.controls = [];
      checkArray.setValue([]);
      for (let i = 0; i < this.departments.length; i++) {
        this.departments[i].check = false;
      }
    }
  }

  deleteLocation(itm, index) {
    this.userForm.get('locations').value.splice(index, 1);
    this.userForm.get('locations').patchValue(this.userForm.get('locations').value);
  }


  submit() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid) {
      this.toastrService.error('Please fill all the required fields');
      return
    }
    if (this.userForm.value.departments.length == 0) {
      this.toastrService.error('Please select at least one department');
      return
    }
    if (!this.hasError) {
      return
    }
    this.spinnerService.show();
    let payload = this.userForm.getRawValue();
    payload.countryCode = this.countryCode
    payload.email = payload.email.toLowerCase();
    payload.entityId = this.user.entityId;
    payload.entityName = this.user.entityName;
    payload.entityType = this.user.entityType;
    if (this.type == 'edit') {
      delete payload.email;
    }
    const req = this.editMode ? this.httpService.patchData(PATH.USER_MANAGEMENT + '/' + this.userName + '?organizationId=' + this.entityId, payload) :
      this.httpService.postData(PATH.USER_MANAGEMENT, payload);
    req.subscribe((res) => {
      this.spinnerService.hide();
      this.openSuccessModel();
    }, (err) => {
      this.spinnerService.hide();
      this.toastrService.error(err.message.message);
    })
  }

  openSuccessModel() {
    let modelRef = this.modalService.open(SuccessDialogComponent, {
      ariaLabelledBy: "modal-basic-title",
      windowClass: "center",
    })
    modelRef.componentInstance.type = 'addVendorUser';
    modelRef.componentInstance.message = 'User Created Successfully!';
    modelRef.componentInstance.message = 'User Updated Successfully!';
  }

  onError(obj) {
    this.hasError = obj;
  }

  onCountryChange(obj) {
    this.countryCode = obj.iso2
  }

  numberOnly(event: any) {
    const regexpNumber = /\d/;
    let inputCharacter = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
      event.preventDefault();
    }
  }

  characterOnly(event: any) {
    const regexpNumber = /^([a-zA-Z]*)$/;
    let inputCharacter = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !regexpNumber.test(inputCharacter)) {
      event.preventDefault();
    }
  }

  cancelClick() {
    if (this.editMode) {
      this.ngOnInit();
    }
    else {
      this.userForm.reset();
      this.prepareUserForm();
    }
  }

  changeCountry(ev) {
    this.inputOptions = null;
    this.inputOptions = { initialCountry: this.countryCode, separateDialCode: true };
  }

  grtCountriesList() {
    this.spinnerService.show();
    this.httpService.getData(PATH.GET_ALL_COUNTRIES + '?page=1&size=1000').subscribe((res) => {
      let countryList = res['content'];
      this.countries = countryList.map((elm) => { return { name: elm.countryName, code: elm.countryName } })
      this.spinnerService.hide();
    })
  }

}

