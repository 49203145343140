<div class="vendor-dashboard-screen">
    <div class="row vendor-screen ">
        <div class="col-md-9 vendor-col-width">
            <div class="settingForm">
                <div class="contactheading">
                    <h2 class="font-bold text-2xl" id="dashboardHeading">Dashboard</h2>
                </div>
                <p-tabView>
                    <p-tabPanel header="Overview">
                        <app-overview *ngIf="companyInfo" [companyInfo]="companyInfo" ></app-overview>
                    </p-tabPanel>
                    <p-tabPanel header="Directors & Shareholders" class="mt-4">
                        <app-directors *ngIf="companyInfo" [companyInfo]="companyInfo"></app-directors>
                    </p-tabPanel>
                    <p-tabPanel header="Financial" *ngIf="isFinancialRes" class="mt-4">
                        <app-financial *ngIf="financialData" [companyInfo]="financialData"></app-financial>
                        <app-financial *ngIf="!financialData" [companyInfo]="financialData"></app-financial>
                    </p-tabPanel>
                    <p-tabPanel header="ESG" class="mt-4">
                        <app-esg *ngIf="companyInfo" [companyInfo]="companyInfo"></app-esg>
                    </p-tabPanel>
                    <p-tabPanel header="Questionnaire" class="mt-4">
                        <app-questionnaire-screen *ngIf="companyInfo" [companyInfo]="companyInfo"> </app-questionnaire-screen>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
        <div class="col-md-3 vendor-col-width">
            <div class="right-container  pb-5">
                <div class="invited-images" *ngIf="invitedCompDetails">
                    <h2 id="inviteByHeading">Invited BY</h2>
                    <img *ngIf="companyLogoUrl" [src]="companyLogoUrl" alt="" [alt]="companyLogoUrl?.legalName">
                    <p id="legalNameText">{{invitedCompDetails?.legalName}}</p>
                </div>
                <div class="dashboard-heading mt-3 pt-3 pb-0">
                    <p class="text-2xl fontbold" id="legalNameMessage">
                        {{invitedCompDetails?.legalName}} Message</p>
                    <!-- <p class="font-bold text-base text-rose-700 mt-1 font-base-size p-red " id="viewAll">View All</p> -->
                </div>

                <div class="image-containers">
                    <ng-container *ngFor="let col of adverseMedia; let i = index">
                        <div class="row message-box bg-white">
                            <!-- <div class="col-3">
                                <img src="{{col.img}}">
                            </div> -->
                            <div class="col-12" id="{{ 'title' + i }}">
                                <!-- {{col.title}} <br> -->
                                <p class="para" >DigiComp+ platform enables a seamless way to link past, present and future data for due diligence checks in an economical and efficient way. This real-time data analytics process can also generate actionable insights for the future.</p>
                                <p class="para" >It is a platform that is designed for transparency, resilience and sustainability, maximizing the return on data investment.</p>
                                <p class="para" >Risk mitigation, fast and effective decision-making and creating trust with your customers is now a click away.</p>
                                <p class="para" >Subscribe to DigiComp+ platform and manage your business efficiently whilst avoiding costly penalties and sanctions</p>
                                <p class="email" >Contact: <span class="support" >support@gobeyonddigital.net</span></p>
                                <!-- <p class="text-gray-400" id="{{ 'date' + i }}">12-Dec-2021</p> -->
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>