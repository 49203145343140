<div class="header-comp">
    <div class="header">
        <header [ngClass]="collapsed?'expand-header':'collapsed-header'" class="header">
            <nav class="navbar navbar-expand-lg navbar-light bg-white">
                <div class="container-fluid">
                    <div class="search-wrapper">
                        <div class="search-cont search-cont-none">
                            <img *ngIf="companyLogo" src="../../../assets/images/logo.png" alt="logo"/>
                        </div>
                    </div>
                    <a class="navbar-brand ">
                        <img *ngIf="!companyLogo" src="../../../assets/images/logo.png" alt="logo" width="185" class="navbar-img">
                        <img class="companyLogo" *ngIf="companyLogo" [src]="imgUrl" alt="logo" />
                    </a>
                    <div class="inline-flex flex-header" [ngClass]="  !companyLogo ? 'responsive-small-logo': 'noResponsive-small-logo' "   >
                        <div class="notification-icon">
                            <div *ngIf="user?.roles == 'ROLE_VENDOR'">
                                <div *ngIf="companyInfo?.status =='Rfi Generated'" (click)="goToCompanyInfo()">
                                    <em class="icon icon-alert" pTooltip="Notifications" tooltipPosition="left"></em> 
                                    <span *ngIf="companyInfo?.status =='Rfi Generated'" id="notificationLength"
                                        class="alert-notification">{{notificationLength}}</span>
                                </div>
                                <em *ngIf="companyInfo?.status =='Submitted'" id="notifications" pTooltip="Notifications" tooltipPosition="left" class="icon icon-alert"></em> 
                            </div>
                            <div *ngIf="user?.roles != 'ROLE_VENDOR'" >
                                <em class="icon icon-alert" pTooltip="Notifications" id="notifications" tooltipPosition="left" [ngClass]="  !companyLogo ? 'admin': 'nonAdmin' " ></em> 
                            </div>
                        </div>
                        <div ngbDropdown class="dropdown border-list">
                            <a class="nav-link dropdown-toggle" ngbDropdownToggle type="button" id="dropdownMenu" aria-expanded="false">
                                <em class="icons icon-user"  ></em>  {{loggedInUser?.firstName}} {{ loggedInUser?.lastName}}
                            </a>
                            <ul ngbDropdownMenu aria-labelledby="dropdownMenu">
                                <li *ngIf="(isVendor && isOwner) || isMultipleAssociation">
                                <a class="dropdown-item" id="chooseProfile" ngbDropdownItem (click)="chooseProfile()"><em class="icon icon-change-profile"></em>  <span> Choose Profile</span> </a>
                                </li>
                                <li *ngIf="isVendorLog">
                                    <a class="dropdown-item" id="profile" ngbDropdownItem (click)="linkProfile()"><em class="icon icon-user"></em>  <span> Profile</span> </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" id="logout" ngbDropdownItem (click)="logout()"><em class="icon icon-logout"></em>  <span> Logout</span> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    </div>
</div>
