<div class="vendor-risk-assessment">
    <div class="page-heading mt-4">
        <div class="left">
            <h1>Assessment Form</h1>
        </div>
        <div class="right">
            <button type="button" class="step-trigger btn btn-back" role="tab" aria-controls="companyInformation-part"
                id="companyInformation-part-trigger" (click)="backBtn()">
                <em class="icon-back icon" aria-hidden="true"></em><span>Back</span>
            </button>
        </div>
    </div>
  
    <div class="stepper-container">
        <div class="bs-stepper">
            <div class="bs-stepper-header" role="tablist">
                <div class="step" data-target="#generalCompanyInformation">
                    <button type="button" class="step-trigger" role="tab" aria-controls="companyInformation-part" id="generalCompanyInformation-part-trigger" (click)="pages(1)">
                        <span class="stepper-status" [ngClass]="[infoSecDetails?.status=='PENDING' ? 'red-active-circle' : 'green-inactive-circle', infoSecDetails?.step>'1' ? 'green-inactive-circle' : 'red-active-circle']">1</span>
                        <div class="text active">
                            <span class="bs-stepper-label general-company" id="CompanyInfo">General Company <span class="info first">Information</span> </span>
                        </div>
                    </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#security">
                    <button type="button" class="step-trigger" role="tab" aria-controls="companyInformation-part" id="security-part-trigger" (click)="pages(2)">
                        <span class="stepper-status" [ngClass]="[infoSecDetails?.status=='PENDING' ? 'red-active-circle' : 'green-inactive-circle', infoSecDetails?.step>'2' ? 'green-inactive-circle' : 'red-active-circle']">2</span>
                        <div class="text active">
                            <span class="bs-stepper-label security" id="CompanyInfo">Information Security </span>
                        </div>
                    </button>
                </div>
                <div class="line"></div>
                <div class="step" data-target="#privacyCompliance">
                    <button type="button" class="step-trigger" role="tab" aria-controls="companyInformation-part" id="privacyCompliance-part-trigger" (click)="pages(3)">
                        <span class="stepper-status" [ngClass]="[infoSecDetails?.status=='PENDING' ? 'red-active-circle' : 'green-inactive-circle', infoSecDetails?.step>'3' ? 'green-inactive-circle' : 'red-active-circle']">3</span>
                        <div class="text active">
                            <span class="bs-stepper-label privacy" id="CompanyInfo">Privacy <span class="info third">Compliance</span> </span>
                        </div>
                    </button>
                </div>
            </div>
            <div class="bs-stepper-content p-0">
                    <div id="generalCompanyInformation" class="content" role="tabpanel" aria-labelledby="companyInformation-part-trigger">
                        <div class="component">
                            <ng-container *ngIf="isMetaData">
                                <div *ngFor="let section of form">
                                    <ng-container *ngIf="section.label=='GENERAL COMPANY INFORMATION'">
                                        <div class="accordion" id="accordionPanelsStayOpenExample">
                                            <app-dynamic-form *ngFor="let subSection of section.children"
                                            [subSection]="subSection" [section]="section.label" [info]="infoSecDetails" [answerList]="answerList" (formResData)="dynamicFormRes($event)"></app-dynamic-form>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="buttons mb-3">
                                <div class="btn next" id="nextBtn" (click)="next(form,'step1')">Next</div>
                            </div>
                        </div>
                    </div>
                    <div id="security" class="content" role="tabpanel" aria-labelledby="companyInformation-part-trigger">
                        <div class="component">
                            <ng-container *ngIf="isMetaData">
                                <div *ngFor="let section of form">
                                    <ng-container *ngIf="section.label=='INFORMATION SECURITY'">
                                        <div class="accordion" id="accordionPanelsStayOpenExample">
                                            <app-dynamic-form *ngFor="let subSection of section.children" 
                                            [subSection]="subSection" [section]="section.label" [info]="infoSecDetails" (formResData)="dynamicFormRes($event)"></app-dynamic-form>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="buttons mb-3">
                                <div class="btn cancel" id="backBtn" (click)="back()">Back</div>
                                <div class="btn next" id="nextBtn" (click)="next(form,'step2')">Next</div>
                            </div>
                        </div>
                    </div>
                    <div id="privacyCompliance" class="content" role="tabpanel" aria-labelledby="companyInformation-part-trigger">
                        <div class="component">
                            <ng-container *ngIf="isMetaData">
                                <div *ngFor="let section of form">
                                    <ng-container *ngIf="section.label=='PRIVACY COMPLIANCE'">
                                        <div class="accordion" id="accordionPanelsStayOpenExample">
                                            <app-dynamic-form *ngFor="let subSection of section.children"
                                            [subSection]="subSection" [section]="section.label" [info]="infoSecDetails" (formResData)="dynamicFormRes($event)"></app-dynamic-form>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div class="buttons mb-3">
                                <div class="btn cancel" id="backBtn" (click)="back()">Back</div>
                                <div class="btn next" id="nextBtn" (click)="onSubmit(form,'step3'); reloadPage()" *ngIf="infoSecDetails?.status=='PENDING'">Submit</div>
                            </div>
                        </div>
                    </div>
                
            </div>
            
        </div>
    </div>

    






    