<app-header-login></app-header-login>
<div class="register-cont-wrapper">
    <div class="register-container">
        <div class="login">
            <p class="subhead-login" id="welcomeText">Welcome TO</p>
            <div class="login-img">
                <img src="../../../assets/images/logo.png" alt="login image" width="185"> 
            </div>
            <div class="login-heading">
                <h1 id="createPasswordHeading">Create Password & Register</h1>
            </div>
        </div>

        <form [formGroup]="registerForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" class="form-control" id="emailId" placeholder="Email ID" formControlName="emailId" name="emailId">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group pass-form">
                        <input [type]="password" class="form-control  fc-icon" placeholder="Password" id="password" (mouseout)="passwordPopUP=false" (mouseover)="passwordPopUP=true"  autocomplete="off" (keyup)="getValue($event)"  formControlName="password" name="password" [ngClass]="{'is-invalid':formSubmitAttempt && f.password.errors }"  (keydown)="validators.space($event)" appBlockcopypaste>
                        <em *ngIf="iconShow" (click)="viewPassword()" class="password-icon" [ngClass]="show ? 'icon-hide' : 'icon-view'" aria-hidden="true"></em>
                        <div app-errors class="error-msg" [control]="f.password" [submit]="formSubmitAttempt" label="Password"></div>
                        <div class="password-checker" *ngIf="passwordPopUP">
                            <ul> 
                                <li [ngClass]="notMatch ? 'error-Msg' : 'non-validate'" >Password should meet the following requirements:</li>
                                <li [ngClass]="length ? 'validate' : 'non-validate'" ><span >✔</span> Length greater than or equal to 8 characters</li>
                                <li [ngClass]="isValidLength  ? 'validate' : 'non-validate'" ><span >✔</span> Length less than or equal to 15 characters</li>
                                <li [ngClass]="UpperChar ? 'validate' : 'non-validate'" ><span>✔</span> Upper case character</li>
                                <li [ngClass]="LowerChar ? 'validate' : 'non-validate'" ><span>✔</span> Lower case character</li>
                                <li [ngClass]="Numeric ? 'validate' : 'non-validate'" ><span>✔</span> Numeric character</li>
                                <li [ngClass]="SpecialChar ? 'validate' : 'non-validate'" ><span>✔</span> Special character</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div class="col-md-6">
                    <div class="form-group">
                        <input [type]="reTypepassword" (keyup)="rePassword($event)" class="form-control fc-icon" id="rePassword"
                         placeholder="Re-type Password" formControlName="retypePassword" 
                         name="retypePassword" [ngClass]="{'is-invalid':formSubmitAttempt && f.retypePassword.errors}"  
                         (keydown)="validators.space($event)" appBlockcopypaste>
                        <em *ngIf="iconShow" (click)="viewRePassword()" class="password-icon" 
                        [ngClass]="showReType ? 'icon-hide' : 'icon-view'" aria-hidden="true"></em>
                        <div app-errors class="error-msg" [control]="f.retypePassword" [submit]="formSubmitAttempt" label="Confirm Password"></div>
                    </div>
                </div> 

            </div>
            <div class="text-center">
                <button (click)="activateUser()" id="activateUser" class="btn login-btn">Register</button>
            </div>
        </form>
    </div>
</div>