import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit  {

  constructor() { }
  
  @Input() totalPages:number;
  @Input() size:number;

  @Output() onPageChange: EventEmitter<any> = new EventEmitter();

  pages: any;
  activePage: number;

  ngOnInit(): void {
    
  }

  ngOnChanges(): any {
    this.pages = Array(this.totalPages);
    this.activePage = 1;
  }

  setPage(pageNumber): void {
    if (pageNumber >= 1 && pageNumber <= this.pages.length) {
      this.activePage = pageNumber;
      let dt={ page: this.activePage, size: this.size }
      this.onPageChange.emit(dt);
    }
  }


}