import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-questionnaire-screen',
  templateUrl: './dashboard-questionnaire.component.html',
  styleUrls: ['./dashboard-questionnaire.component.scss']
})
export class QuestionnaireScreenComponent  {

  @Input() companyInfo:any;



}
