<div class="vendor-vservey">
    <div class="vendor-survey mt-4" *ngIf="!isCreateSurvey">
        <div class="heading">
            <h1 id="surveyHeading">Survey</h1>
        </div>
        <div class="row">
            <div class="page-content mt-3">
                <div class="showList mt-2">
                    <p-table [columns]="surveyHeaders" [value]="surveyInfo" class="p-table mt-5"
                        responsiveLayout="scroll">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <ng-container *ngFor="let col of columns; let i = index">
                                    <th pSortableColumn={{col.name}} id="{{ 'header' + i }}">{{col.header}}</th>
                                </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-survey>
                            <tr>
                                <td nowrap>{{survey?.createdOn}}</td>
                                <td nowrap class="vendor-survey-title">{{survey?.title}}</td>
                                <td>
                                    <div *ngIf="survey.status=='Started Now'" class="bg-yellow" (click)="addSurvey(survey)" >
                                        <span>{{survey?.status}}</span>
                                    </div>
                                    <div *ngIf="survey.status=='Completed'"  class='bg-green'>
                                        <span>{{survey?.status}}</span>
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                </div>
            </div>

        </div>
    </div>
    <div class="add-screen" *ngIf="isCreateSurvey">
        <div class="screen-container">
            <div class="heading">
                <h1 id="surveyHeading2">Survey</h1>
            </div>
            <div class="buttons">
                <button class="btn cancel" id="backBtn" (click)="backSurvey()">Back to Listing</button>
            </div>
        </div>


        <form [formGroup]="surveyForm">

            <div formArrayName="questionsAnswer" class="question-section">
                <ng-container *ngFor="let survey of surveyForm.get('questionsAnswer')['controls'] let i=index; ">
                    <div [formGroupName]="i">
                        <div class="mt-4">
                            <h1 id="questionsAnswerHeading">Q 0{{i+1}}<span
                                    class="ml-3">{{survey.get('question').value}}</span></h1>
                        </div>
                        <div class="ans" *ngIf="survey.get('vendorRatingType').value == 'Excellent/Good/Average/Poor/Worst'">
                            <div class="survey-answer">
                                <input class="form-check-input ratiobtn mx-2" id="excellent" type="radio" formControlName="rating" value="Excellent">
                                <label class="form-check-label radioValue mr-3" for="excellent">
                                    Excellent
                                </label>
                                <input class="form-check-input ratiobtn mx-2" type="radio" id="good" formControlName="rating"
                                    value="Good">
                                <label class="form-check-label radioValue mr-3" for="good">
                                    Good
                                </label>
                                <input class="form-check-input ratiobtn mx-2" type="radio" id="average" formControlName="rating"
                                    value="Average">
                                <label class="form-check-label radioValue mr-3" for="average">
                                    Average
                                </label>
                                <input class="form-check-input ratiobtn mx-2" type="radio" id="poor" formControlName="rating"
                                    value="Poor">
                                <label class="form-check-label radioValue mr-3" for="poor">
                                    Poor
                                </label>

                                <input class="form-check-input ratiobtn mx-2" type="radio" id="worst" formControlName="rating"
                                    value="Worst">
                                <label class="form-check-label radioValue mr-3" for="worst">
                                    Worst
                                </label>
                            </div>
                        </div>
                        <div class="ans" *ngIf="survey.get('vendorRatingType').value == 'Good/Average/Worse'">
                            <div class="survey-answer">

                                <input class="form-check-input ratiobtn mx-2" type="radio" id="goodRating" formControlName="rating" value="Good" >
                                <label class="form-check-label radioValue mr-3" for="goodRating"> Good </label>

                                <input class="form-check-input ratiobtn mx-2" type="radio" id="averageRating" formControlName="rating" value="Average">
                                <label class="form-check-label radioValue mr-3" for="averageRating"> Average </label>

                                <input class="form-check-input ratiobtn mx-2" type="radio" id="worstRating" formControlName="rating" value="Worst">
                                <label class="form-check-label radioValue mr-3" for="worstRating"> Worst </label>
                            </div>
                        </div>
                     
                        <div class="ans" *ngIf="survey.get('vendorRatingType').value == '1 to 3'">
                            <div class="survey-answer">
                                    <p-radioButton name="rating" id="ratingRadioBtn" value="1" class="mx-2" formControlName="rating" >
                                    </p-radioButton>
                                <label class="form-check-label radioValue mr-3" for="ratingRadioBtn">
                                    1
                                </label>
                                    <p-radioButton name="rating" value="2" id="ratingRadioBtn" class="mx-2" formControlName="rating">
                                    </p-radioButton>
                                <label class="form-check-label radioValue mr-3" for="ratingRadioBtn">
                                    2
                                </label>
                                    <p-radioButton name="rating" value="3" id="ratingRadioBtn" class="mx-2" formControlName="rating">
                                    </p-radioButton>
                                <label class="form-check-label radioValue mr-3" for="ratingRadioBtn">
                                    3
                                </label>
                            </div>
                        </div>

                        <div class="ans" *ngIf="survey.get('vendorRatingType').value == '1 to 5'">
                            <div class="survey-answer">
                                <input class="mx-2" type="radio" id="vendorRatingType1" name="rating" formControlName="rating"  value="1">
                                <label class="mr-3" for="vendorRatingType1"> 1 </label>

                                <input class="mx-2" type="radio"  id="vendorRatingType2" name="rating" formControlName="rating"  value="2">
                                <label class="mr-3" for="vendorRatingType2"> 2 </label>
                                
                                <input class="mx-2" type="radio" id="vendorRatingType3" name="rating" formControlName="rating"  value="3">
                                <label class="mr-3" for="vendorRatingType3"> 3 </label>

                                <input class="mx-2" type="radio"  id="vendorRatingType4" name="rating" formControlName="rating"  value="4">
                                <label class="mr-3" for="vendorRatingType4"> 4 </label> 
                                
                                <input class="mx-2" type="radio" id="vendorRatingType5"  name="rating" formControlName="rating"  value="5">
                                <label class="mr-3" for="vendorRatingType5"> 5 </label>
                            </div>
                        </div>

                        <div class="ans" *ngIf="survey.get('vendorRatingType').value == '1 to 10' || survey.get('vendorRatingType').value == 'start rating' ">
                            <div class="survey-answer">
                                <input class="form-check-input ratiobtn mx-2" id="ratingType1" type="radio" formControlName="rating"
                                    value="1">
                                <label class="form-check-label radioValue mr-3" for="ratingType1">
                                    1
                                </label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType2" type="radio" formControlName="rating"
                                    value="2">
                                <label class="form-check-label radioValue mr-3" for="ratingType2">
                                    2
                                </label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType3" type="radio" formControlName="rating"
                                    value="3">
                                <label class="form-check-label radioValue mr-3" for="ratingType3">
                                    3
                                </label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType4" type="radio" formControlName="rating"
                                    value="4">
                                <label class="form-check-label radioValue mr-3" for="ratingType4">
                                    4
                                </label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType5" type="radio" formControlName="rating"
                                    value="5">
                                <label class="form-check-label radioValue mr-3"  for="ratingType5"> 5 </label>

                                <input class="form-check-input ratiobtn mx-2" id="ratingType6" type="radio" formControlName="rating"
                                    value="6">
                                <label class="form-check-label radioValue mr-3" for="ratingType6">6</label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType7" type="radio" formControlName="rating"
                                    value="7">
                                <label class="form-check-label radioValue mr-3" for="ratingType7">7</label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType8" type="radio" formControlName="rating"
                                    value="8">
                                <label class="form-check-label radioValue mr-3" for="ratingType8">8</label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType9" type="radio" formControlName="rating"
                                    value="9">
                                <label class="form-check-label radioValue mr-3" for="ratingType9">9</label>
                                <input class="form-check-input ratiobtn mx-2" id="ratingType10" type="radio" formControlName="rating"
                                    value="10" >
                                <label class="form-check-label radioValue mr-3" for="ratingType10">10</label>
                            </div>
                        </div>

                    </div>
                </ng-container>

            </div>
            <div class="invite-comment mt-5">
                <h3 class="mb-2" id="commentHeading">Any Comment / Message </h3>
                <textarea formControlName="message" id="textArea" class="comment" [rows]="5" [cols]="80" autoResize="autoResize"
                    pInputTextarea></textarea>
            </div>

            <div class="buttons mt-5">
                <button class="btn next" id="inviteBtn" (click)="inviteSend()">Invite</button>
            </div>
        </form>
    </div>