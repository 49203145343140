import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SERVER_PATHS } from '../app.constant';
@Injectable({
  providedIn: 'root'
})

export class ReduxService {
  constructor(private http: HttpClient) { }
  // updatedData:any;  
  
  getUrl(url) {
    return location.protocol + url;
  }
  
  get(url) {
    return this.http.get(`${SERVER_PATHS.DEV}${url}`);
  }

//   updated(url, data){
//     let API_URL = this.getUrl(`${SERVER_PATHS.DEV}${url}`);
//     return this.http.put(API_URL, data);
// }

// update(data){
// return this.updatedData = data;
// }

} 